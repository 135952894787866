import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Modal } from 'components/Modal';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { UsersService } from 'services';
import { userStateRecoil } from 'contexts/atoms';
import { useRecoilState } from 'recoil';

interface ModalProps {
  visible: boolean;
  onClose: () => void;
}

export const ChangePasswordModal = ({
  visible,
  onClose,
}: ModalProps): JSX.Element => {
  const [userState] = useRecoilState(userStateRecoil);
  const [error, setError] = useState({ inputError: false, isInValid: false });
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    repeatPassword: '',
  });

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.currentTarget.name]: e.currentTarget.value });
  };

  const checkPassword = () => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[a-zA-Z\d\w\W]{8,}$/;
    if (formData.newPassword !== formData.repeatPassword) {
      setError({
        inputError: true,
        isInValid: false,
      });
    } else if (
      !formData.newPassword.match(regex) ||
      formData.newPassword == formData.oldPassword
    ) {
      setError({
        inputError: false,
        isInValid: true,
      });
    } else {
      handleChangePassword();
    }
  };

  const handleChangePassword = () => {
    toast
      .promise(
        UsersService.changePassword(
          userState.userId,
          formData.oldPassword,
          formData.newPassword,
        ),
        {
          pending: 'Checking ...',
          success: 'Password successfully changed!',
          error: {
            render({ data }: any) {
              return data?.data.error || 'Something went wrong!';
            },
          },
        },
      )
      .then((res) => {
        if (res) {
          onClose();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    setError({ inputError: false, isInValid: false });
  }, [visible]);

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      title="Change Password"
      width="auto"
    >
      <div className="w-full space-y-4">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            checkPassword();
          }}
        >
          <div className="relative flex flex-col space-y-6 py- px-2 items-center">
            <Input
              name="oldPassword"
              type="password"
              required
              placeholder="Current Password"
              onChange={handleChange}
            />
            <Input
              name="newPassword"
              type="password"
              required
              placeholder="New Password"
              onChange={handleChange}
            />
            <Input
              name="repeatPassword"
              type="password"
              required
              placeholder="Repeat New Password"
              helperText={error.inputError ? 'Passwords do not match' : ''}
              onChange={handleChange}
              error={error.inputError}
            />
            <p className="max-w-sm mt-2 text-primary bottom-0 leftfull w-full">
              Your Password must have:
              <span className="block">- At least 8 characters</span>
              <span className="block">
                - A number, a capital letter and a lowercase letter
              </span>
              <span className="block">- A special character (!@#$%)</span>
            </p>
          </div>
          <div className="mt-4 flex justify-center">
            {error.isInValid && (
              <p className="text-red-600">
                Invalid Password. Please try again.
              </p>
            )}
          </div>
          <div className="mt-4 flex justify-center">
            <Button type="submit" variant="fullRounded">
              Change Password
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
