import React from 'react';
import styled from 'styled-components';
const Icon = styled.svg`
  cursor: grab;
  margin: 0 20px 0 0;
`;
export const DragIcon = (): JSX.Element => {
  return (
    <Icon
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2V0ZM25 2C25.5523 2 26 1.55228 26 1C26 0.447715 25.5523 0 25 0V2ZM5 5C4.44772 5 4 5.44772 4 6C4 6.55228 4.44772 7 5 7V5ZM25 7C25.5523 7 26 6.55228 26 6C26 5.44772 25.5523 5 25 5V7ZM1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM1 10C0.447715 10 0 10.4477 0 11C0 11.5523 0.447715 12 1 12V10ZM25 12C25.5523 12 26 11.5523 26 11C26 10.4477 25.5523 10 25 10V12ZM1 2H25V0H1V2ZM5 7H25V5H5V7ZM8 12H18V10H8V12ZM1 7H25V5H1V7ZM1 12H18V10H1V12ZM8 12H25V10H8V12ZM1 12H25V10H1V12Z"
        fill="currentColor"
      />
    </Icon>
  );
};
