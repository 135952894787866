import { DashboardLayout } from 'layouts/Dashboard';
import React, { useEffect } from 'react';
import { getSchoolsData } from 'services/SchoolsService';

import { ListItem } from './components/ListItem';

export const SchoolsList = (): JSX.Element => {
  const [schoolsData, setSchoolsData] = React.useState([]);
  useEffect(() => {
    const getData = async () => {
      const response = await getSchoolsData();
      setSchoolsData(response?.data);
    };
    getData();
  }, []);

  return (
    <DashboardLayout
      title={
        <div className="mt-8">
          <h1>Enrolled Schools</h1>
          <h6 className="text-primary text-2xl">View and Manage</h6>
        </div>
      }
    >
      <ul className="col-span-3 space-y-3 overflow-scroll no-scrollbar">
        {schoolsData.map((school, index) => (
          <ListItem key={index} {...school} />
        ))}
      </ul>
    </DashboardLayout>
  );
};
