import React, { useEffect, useState } from 'react';

import { Table } from './components/Table';
import { LinkButton } from 'components/LinkButton';
import { getModulesProgressByUser } from 'services/UsersService';
import { userStateRecoil } from 'contexts/atoms';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ManageLayout } from 'layouts/Manage';
import { getCourse } from 'services/CoursesService';
import { AccountTypeEnum } from 'contexts/types';

export const ViewUsersAdmin = (): JSX.Element => {
  const [progresses, setProgresses] = useState([]);
  const { idSchool, idCourse } = useParams();
  const [schoolAdmins, setSchoolAdmins] = useState(
    [] as Array<Record<string, string>>,
  );
  const [modules, setModules] = useState([]);
  const [course, setCourse] = useState({ name: '' });
  const [userState] = useRecoilState(userStateRecoil);

  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);
  const schoolName = params.get('schoolName');

  const getData = async () => {
    if (idSchool && idCourse) {
      const response = await getModulesProgressByUser(idSchool, idCourse);
      setProgresses(response?.data?.modulesProgressesByUser);
      setModules(response?.data?.modules);
      setSchoolAdmins(response?.data?.schoolAdmins);
      const course = await getCourse(idCourse);
      setCourse(course?.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <ManageLayout
      title={
        <div>
          <h1>Enrolled Students</h1>
          <p className="text-primary text-2xl">{schoolName}</p>
        </div>
      }
    >
      <div>
        <div className="flex justify-between mt-6">
          <div>
            <h1 className="text-primary text-3xl">{course?.name}</h1>
            <h2 className="text-secondary text-2xl">Students</h2>
          </div>
          {userState.role === AccountTypeEnum.XYLO_ADMIN &&
            schoolAdmins.length > 0 && (
              <div className="flex justify-between w-1/2 rounded-full bg-primary h-12 px-8 py-4 items-center text-white overflow-x-auto no-scrollbar">
                <h3 className="text-orange">Admin</h3>
                <p>{schoolAdmins[0]?.name}</p>
                <p className="w-1/2">{schoolAdmins[0]?.email}</p>
              </div>
            )}
        </div>
        <div className="overflow-auto no-scrollbar h-vh45">
          <Table data={progresses} columns={[{}, {}, {}, ...modules]} />
        </div>
        <div className="flex justify-center mt-4">
          <LinkButton to={`detailed-scores?schoolName=${schoolName}`}>
            View Detailed Scores
          </LinkButton>
        </div>
      </div>
    </ManageLayout>
  );
};
