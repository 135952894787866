import React, { useState } from 'react';
import { Input } from 'components/Input';
import { Title } from 'components/Title/styles';
import { Button } from 'components/Button';
import Link from 'components/Link';
import { useRecoilState } from 'recoil';
import { authStateRecoil, userStateRecoil } from '../../contexts/atoms';
import { AccountTypeEnum } from '../../contexts/types';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import Basic from 'layouts/Basic';
import { AuthService } from 'services';

export const Login = (): JSX.Element => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: '', password: '' });

  const [userState, setUserState] = useRecoilState(userStateRecoil);
  const [authState, setAuthState] = useRecoilState(authStateRecoil);

  const handleLogin = () => {
    toast
      .promise(AuthService.login(formData.email, formData.password), {
        pending: 'Checking...',
        success: {
          render({ data }: any) {
            const message = data.data?.token
              ? 'Access granted.'
              : 'Access code sent to your email';
            return message;
          },
        },
        error: {
          render({ data }: any) {
            return data.data?.message || 'Something went wrong!';
          },
        },
      })
      .then((res) => {
        if (res) {
          setAuthState(true);
          const data = res.data;
          const loggedUser = {
            role: data.roles[0],
            name: data.name,
            userId: data.id,
            surname: data.surname,
            email: data.email,
            token: data.token,
            refreshToken: data.refreshToken,
            profilePhotoUrl: data.profilePhotoUrl,
          };
          setUserState(loggedUser);

          window.localStorage.setItem('loggedUser', JSON.stringify(loggedUser));
          window.localStorage.setItem('SESSION_TOKEN', loggedUser.token);
          window.localStorage.setItem('REFRESH_TOKEN', loggedUser.refreshToken);

          switch (data.roles[0]) {
            case AccountTypeEnum.XYLO_ADMIN:
              return navigate('/xylo-admin/dashboard');
            case AccountTypeEnum.SCHOOL_ADMIN:
              return navigate('/school-admin/dashboard');
            case AccountTypeEnum.STUDENT:
              return navigate('/code');
            default:
              return navigate('/login');
          }
        }
      });
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.currentTarget.name]: e.currentTarget.value });
  };

  return (
    <Basic>
      <div>
        <div className="flex flex-col max-w-lg mx-auto">
          <Title className="mb-8 text-center text-secondary">
            Login to XYLO Academy
          </Title>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleLogin();
            }}
          >
            <div className="flex flex-col space-y-3 py- px-2 items-center">
              <Input
                name="email"
                type="email"
                required
                placeholder="Email"
                onChange={handleChange}
              />
              <Input
                name="password"
                type="password"
                required
                placeholder="Password"
                onChange={handleChange}
              />
            </div>
            <div className="mt-8 flex justify-center">
              <Button type="submit" variant="fullRounded">
                Log In
              </Button>
            </div>
          </form>
          <div className="flex flex-col items-center mt-8">
            <Link href="/forgot-password" text="Forgot password?" />
          </div>
        </div>
        <div className="flex justify-center absolute inset-x-0 bottom-10">
          <p className="relative inset-x-0 top-4 text-primary left-50">
            For more information on how to register your school or invidually,
            email&nbsp;
            <Link href="#" text="info@xyloacademy.com" />
          </p>
        </div>
      </div>
    </Basic>
  );
};
