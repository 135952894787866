import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { UserCard } from 'components/UserCard';
import { LinkButton } from 'components/LinkButton';
import { DashboardLayout } from 'layouts/Dashboard';
import { userStateRecoil } from 'contexts/atoms';
import { Role, UserType } from 'contexts/types';
import { useRecoilState } from 'recoil';
import { UsersService, SchoolsService } from 'services';
import { formatDate } from 'utils/date-utils';
import { Loader } from 'components/Loader';
import { reviewLicenseExpiration } from 'utils/utils';

export const DashboardAdmin = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [userState] = useRecoilState(userStateRecoil);
  const [user, setUser] = useState<UserType>();
  const [school, setSchool] = useState<any>();

  const handleGetUserInfo = async () => {
    setIsLoading(true);
    const userInfo = await UsersService.getUser(userState.userId);
    const newSchool = await SchoolsService.getSchool(userInfo?.data.school.id);
    setUser({
      role: userInfo?.data.roles[0],
      ...userInfo?.data,
    });

    setSchool(newSchool?.data);
    if (userInfo?.data && newSchool?.data) {
      setIsLoading(false);
    }

    reviewLicenseExpiration(newSchool?.data.license);
  };

  useEffect(() => {
    handleGetUserInfo();
  }, []);
  return (
    <DashboardLayout title="Your Dashboard">
      {isLoading ? (
        <div className="w-full col-span-3h-full">
          <Loader />
        </div>
      ) : (
        <>
          <div className="col-span-2">
            {/* Line 10 just for testing */}
            <div className="mb-12">
              <h2 className="text-primary text-3xl mb-4">Licensed Courses</h2>
              <S.Row className="mb-4 bg-blue-100">
                <div className="mr-6">
                  <S.RowTitle className="text-2xl mb-4">
                    {school?.courses[0].name}
                  </S.RowTitle>
                  <LinkButton
                    to={`/school-admin/course-outline/${school?.courses?.[0].id}?idSchool=${school?.id}&courseName=${school?.courses?.[0].name}`}
                  >
                    Go To Course Outline
                  </LinkButton>
                </div>
                <S.RowDateCotnainer>
                  <S.RowDateLabel>License Expires</S.RowDateLabel>
                  <S.RowDate>
                    {formatDate(new Date(school?.license?.expirationDate))}
                  </S.RowDate>
                </S.RowDateCotnainer>
              </S.Row>
            </div>
            <h2 className="text-primary text-3xl mb-4">Enrolled Students</h2>
            <LinkButton
              to={`/admin/view-students/schools/${school?.id}/courses/${school?.courses?.[0].id}?courseName=${school?.courses?.[0].name}&schoolName=${user?.school.name}`}
              className="mr-2"
            >
              View
            </LinkButton>

            <LinkButton
              to={`/school-admin/school/${school?.id}/forums`}
            >{`View Forums`}</LinkButton>
          </div>

          <div className="col-span-1 flex justify-end">
            <UserCard
              role={Role[user?.role as keyof typeof Role]}
              name={`${user?.name} ${user?.surname}`}
              school={user?.school.name}
              photoUrl={user?.profilePhotoUrl}
            />
          </div>
        </>
      )}
    </DashboardLayout>
  );
};
