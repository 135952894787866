import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { DashboardLayout } from 'layouts/Dashboard';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { CheckBox } from 'components/CheckBox';
import { getSchool } from 'services/SchoolsService';
import { LicensesService } from '../../services';
import { School } from 'types';
import { formatDate } from 'utils/date-utils';
import { toast } from 'react-toastify';

export const ExtendLicense = (): JSX.Element => {
  const [schoolData, setSchoolsData] = useState<School>();
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    date: new Date(),
    newCode: '',
  });
  const { idSchool } = useParams();

  const navigate = useNavigate();

  const handleChangeInput = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    const auxValue = name === 'date' ? new Date(value) : value;
    setFormData({
      ...formData,
      [name]: auxValue,
    });
  };

  useEffect(() => {
    const getData = async () => {
      const response = await getSchool(idSchool as string);
      setSchoolsData(response?.data);
    };
    getData();
  }, []);

  const getCoveredModules = () => {
    const code = schoolData?.license?.code.substring(1).split('') || [];
    let text = '';
    code.forEach((el, index) => {
      text += `${
        index + 1 == code.length
          ? `and ${el}`
          : `${el}${index + 1 !== code.length - 1 ? ',' : ''} `
      }`;
    });
    return text;
  };

  return (
    <DashboardLayout
      title={
        <div className="mt-8">
          <h1>Enrolled Students</h1>
          <h6 className="text-primary text-2xl">View and Manage</h6>
        </div>
      }
    >
      <div className="col-span-3">
        {!submitted ? (
          <div className="text-primary-dark">
            <h1 className="text-secondary text-2xl">
              {schoolData?.name} - Extend License
            </h1>
            <p className="text-primary-dark">
              Current license end date is{'  '}
              <span className="text-orange">
                {formatDate(
                  new Date(schoolData?.license?.expirationDate || ''),
                )}
              </span>
            </p>
            <p>
              License Code is{' '}
              <span className="text-orange">{schoolData?.license.code}</span>;
              and therefore
              {(schoolData?.license?.code || '').length > 1
                ? ` it only
            covers module ${getCoveredModules()}`
                : `it covers all modules`}
              .
            </p>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                LicensesService.editLicense(`${schoolData?.license?.id}`, {
                  code: formData?.newCode,
                  expirationDate: formData?.date,
                  id: `${schoolData?.license?.id}`,
                })
                  .then(() => {
                    setSubmitted(true);
                  })
                  .catch((err) => {
                    toast.error(
                      `Unable to change license: ${err.data.message}`,
                    );
                  });
              }}
            >
              <div className="flex items-center mt-4 mb-4 space-x-4">
                <div className="col-span-2 space-y-3">
                  <p>
                    Enter the date you want to extend until in MM/DD/YYYY
                    format:
                  </p>
                  <p>
                    Enter the License Code you want to switch this license to:{' '}
                  </p>
                </div>
                <div className="space-y-3 w-56">
                  <Input
                    type="text"
                    name="date"
                    className="h-8"
                    placeholder="Enter new date here"
                    required
                    onChange={handleChangeInput}
                  />
                  <Input
                    type="text"
                    name="newCode"
                    className="h-8"
                    placeholder="Enter new code here"
                    required
                    onChange={handleChangeInput}
                  />
                </div>
              </div>
              <div className="mt-20">
                <p className="text-orange">
                  Please check documents and confirm below:
                </p>

                <div className="mb-6 flex items-center">
                  <CheckBox
                    id="payment"
                    type="checkbox"
                    color="orange"
                    required
                  />
                  <label htmlFor="payment" className="ml-3">
                    I confirm that the school admin has provided the necessary
                    payment for the specified change of this license
                  </label>
                </div>
                <Button variant="green" type="submit">
                  Submit Extension
                </Button>
              </div>
            </form>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center text-blue-200 space-y-3">
            <p>
              License with code {formData.newCode} for {schoolData?.name} has
              been extended until {formatDate(formData.date)}
            </p>
            <Button
              variant="green"
              onClick={() => navigate('/root/view-manage')}
            >
              Back To Schools
            </Button>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};
