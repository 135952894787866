import { AccountTypeEnum } from 'contexts/types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { authStateRecoil, userStateRecoil } from '../contexts/atoms';

export default function AuthRedirect() {
  const navigate = useNavigate();

  const [userState] = useRecoilState(userStateRecoil);
  const [authState, setAuthState] = useRecoilState(authStateRecoil);

  useEffect(() => {
    if (!authState) {
      navigate('/login');
    }
    setAuthState(true);
    switch (userState.role) {
      case AccountTypeEnum.XYLO_ADMIN:
        return navigate('/xylo-admin/dashboard');
      case AccountTypeEnum.SCHOOL_ADMIN:
        return navigate('/school-admin/dashboard');
      case AccountTypeEnum.STUDENT:
        return navigate('/student/dashboard');
      default:
        return navigate('/login');
    }
  }, [authState]);

  return null;
}
