import React from 'react';
import * as S from './styles';
export default function FileUpload({ onChange, ...props }) {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (hiddenFileInput.current !== null) {
      hiddenFileInput.current.click();
    }
  };
  return (
    <>
      <S.FileInput onClick={handleClick}>
        <S.Text>
          {hiddenFileInput.current?.value.replace(/C:\\fakepath\\/i, '') ||
            'Select Document for Upload'}
        </S.Text>
      </S.FileInput>
      <input
        id="file"
        type="file"
        accept=".csv"
        ref={hiddenFileInput}
        onChange={(event) => onChange(event.target.files?.[0])}
        style={{ display: 'none' }}
      />
    </>
  );
}
