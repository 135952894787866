import { Content } from 'contexts/types';
import parse, { domToReact } from 'html-react-parser';
import { getStyleObjectFromString } from 'utils/object-utils';

interface ContentContainerProps {
  content: Content;
}

const getNodeStyles = (node, defaultStyles?): React.CSSProperties => {
  let styles;
  if (!!node?.attribs?.style) {
    const params = !!defaultStyles
      ? node?.attribs?.style.concat(defaultStyles)
      : node?.attribs?.style;
    styles = getStyleObjectFromString(params);
  } else if (!!defaultStyles) {
    styles = getStyleObjectFromString(defaultStyles);
  } else {
    styles = {};
  }
  return styles;
};

export const ContentContainer = ({ content }: ContentContainerProps) => {
  return (
    <div className="ml-20 mr-6">
      {content.data && parse(content.data, nodeOptions)}
    </div>
  );
};

//this selects the options to parse to html depending on each node
const nodeOptions: any = {
  replace: (node) => {
    if (!node) {
      return;
    }

    if (node.name === 'h1') {
      return (
        <h1 style={getNodeStyles(node, 'font-size: 2em;font-weight: bold;')}>
          {domToReact(node.children)}
        </h1>
      );
    }

    if (node.name === 'h2') {
      return (
        <h2 style={getNodeStyles(node, 'font-size: 1.5em;font-weight: bold;')}>
          {domToReact(node.children)}
        </h2>
      );
    }

    if (node.name === 'h3') {
      return (
        <h3 style={getNodeStyles(node, 'font-size: 1.17em;font-weight: bold;')}>
          {domToReact(node.children)}
        </h3>
      );
    }
    if (node.name === 'h4') {
      return (
        <h4 style={getNodeStyles(node, 'font-weight: bold;')}>
          {domToReact(node.children)}
        </h4>
      );
    }
    if (node.name === 'h5') {
      return (
        <h5 style={getNodeStyles(node, 'font-size: 0.83em;')}>
          {domToReact(node.children)}
        </h5>
      );
    }

    if (node.name === 'h6') {
      return (
        <h6 style={getNodeStyles(node, 'font-size: 0.67em;')}>
          {domToReact(node.children)}
        </h6>
      );
    }

    if (node.name === 'strong') {
      return (
        <strong style={getNodeStyles(node, undefined)}>
          {domToReact(node.children)}
        </strong>
      );
    }

    if (node.name === 'li') {
      return (
        <li style={getNodeStyles(node, undefined)}>
          {domToReact(node.children)}
        </li>
      );
    }
    if (node.name === 'ol') {
      return (
        <ol style={getNodeStyles(node, undefined)}>
          {domToReact(node.children)}
        </ol>
      );
    }
    if (node.name === 'ul') {
      if (!!node.attribs.style) {
        return (
          <ul style={getNodeStyles(node, undefined)}>
            {domToReact(node.children)}
          </ul>
        );
      } else {
        return (
          <ul style={getNodeStyles(node, 'list-style-type: disc;')}>
            {domToReact(node.children)}
          </ul>
        );
      }
    }
  },
};
