import React, { useEffect, useState } from 'react';
import { Table } from '../ViewUsersAdmin/components/Table';
import { LinkButton } from 'components/LinkButton';
import { getModulesProgressByUser } from 'services/UsersService';
import { useParams } from 'react-router-dom';
import { ManageLayout } from 'layouts/Manage';

export const EnrolledStudents = (): JSX.Element => {
  const [progresses, setProgresses] = useState([]);
  const { idSchool, idCourse } = useParams();
  const [modules, setModules] = useState([]);

  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);
  const schoolName = params.get('schoolName');
  const courseName = params.get('courseName');

  const getData = async () => {
    if (idSchool && idCourse) {
      const response = await getModulesProgressByUser(idSchool, idCourse);
      setProgresses(response?.data?.modulesProgressesByUser);
      setModules(response?.data?.modules);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <ManageLayout
      title={
        <div>
          <h1>Enrolled Students</h1>
          <p className="text-primary text-2xl">{schoolName}</p>
        </div>
      }
      subtitle=""
    >
      <div className="col-span-3">
        <div>
          <h1 className="text-primary text-3xl">{courseName}</h1>
        </div>
        <div className="overflow-auto no-scrollbar h-vh45">
          <Table data={progresses} columns={[{}, {}, {}, ...modules]} />
        </div>

        <div className="flex justify-center mt-4 w-ful mb-2">
          <LinkButton
            to={`detailed-scores?schoolName=${schoolName}&courseName=${courseName}`}
          >
            View Detailed Scores
          </LinkButton>
        </div>
      </div>
    </ManageLayout>
  );
};
