import { authAxiosCall } from '../axiosCall';
import { AxiosResponse } from 'axios';
import { ICourse } from '../../types';

const BASE_PATH = 'courses';

export const getCourses = async (): Promise<AxiosResponse<any> | undefined> => {
  return authAxiosCall(BASE_PATH, {
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  });
};

export const getCourse = async (
  courseId: string,
): Promise<AxiosResponse<any> | undefined> => {
  return authAxiosCall(`${BASE_PATH}/${courseId}`, {
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  });
};

export const createCourse = async (
  course: ICourse,
): Promise<AxiosResponse<any> | undefined> => {
  const params = JSON.stringify(course);
  return authAxiosCall(BASE_PATH, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
  });
};

export const editCourse = async (
  _id: string,
  course: ICourse,
): Promise<AxiosResponse<any> | undefined> => {
  const params = JSON.stringify(course);
  return authAxiosCall(`${BASE_PATH}/${_id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
  });
};

export const getCourseModules = async (
  courseId: string,
): Promise<AxiosResponse<any> | undefined> => {
  return authAxiosCall(`${BASE_PATH}/${courseId}/modules`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
