import { userAnswersState, userStateRecoil } from 'contexts/atoms';
import { Content, ContentProgress } from 'contexts/types';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { ProgressService } from 'services';
import { ModalQuiz } from './ModalQuiz';

interface QuizProps {
  content: Content;
  userId: string;
  onSetModuleProgress: (progress: number) => void;
  onSetContentCompleted: (contentId: string) => void;
}

export const Quiz = ({
  content,
  userId,
  onSetModuleProgress,
  onSetContentCompleted,
}: QuizProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [contentProgress, setContentProgress] = useState<ContentProgress>();
  const [userState] = useRecoilState(userStateRecoil);
  const [answersState, setAnswersState] = useRecoilState(userAnswersState);

  const getProgressByContent = async () => {
    if (userState.role == 'STUDENT') {
      const response = await ProgressService.getProgressByContent(
        content.id,
        userId,
        content?.moduleId,
      );
      const cProgress = response?.data as ContentProgress;
      setContentProgress(cProgress);

      !cProgress.seen &&
        setAnswersState(
          (prev) =>
            [
              ...prev,
              {
                contentId: cProgress.contentId,
                answers: cProgress?.userAnswers,
              },
            ] || [],
        );
      if (response?.data?.seen) {
        onSetContentCompleted(response.data?.contentId);
      }
    }
  };

  useEffect(() => {
    getProgressByContent();
    return () => {
      setAnswersState([]);
    };
  }, []);

  let messageBtnQuiz = '';
  if (contentProgress?.seen) {
    messageBtnQuiz = 'to review';
  } else if (contentProgress && contentProgress?.userAnswers.length > 0) {
    messageBtnQuiz = 'to continue';
  } else {
    messageBtnQuiz = 'to start quiz';
  }

  return (
    <div
      className="relative -top-10 h-56 flex justify-left items-center text-white w-11/12 xl:w-9/12 mx-auto"
      style={{
        minWidth: '480px',
      }}
    >
      <div className="relative w-full">
        <div className="bg-primary px-8 py-4 h-24 w-10/12 rounded-3xl text-xl">
          Time to review what you&apos;ve learned so far!
        </div>
        <div className="absolute text-xl flex space-x-7 top inset-y-1/2 left-20 w-full">
          <div className="bg-orange w-1/4 py-2 px-6 h-fit rounded-3xl text-right">
            <span className="block leading-7">Questions:</span>
            <span className="text-3xl leading-7">
              {content.questions?.length}
            </span>
          </div>
          <div className="bg-orange w-1/4 py-2 px-6 h-fit rounded-3xl text-right">
            <span className="block leading-7">
              {!contentProgress
                ? 'Time Limit:'
                : contentProgress?.seen
                ? 'Your Score'
                : 'Time Limit:'}
            </span>
            <span className="text-3xl block leading-7">
              {!!contentProgress && contentProgress.seen
                ? `${contentProgress?.score}/${content.questions?.length}`
                : content.timeLimit}
            </span>
            <span className="block leading-7">
              {!contentProgress ? '' : contentProgress?.seen ? '' : 'Minutes'}
            </span>
          </div>
          <button
            className="bg-green w-1/3 py-6 h-28 rounded-3xl hover:bg-green-dark"
            onClick={() => setModalVisible(true)}
          >
            <p className="text-3xl">Click Here</p> {messageBtnQuiz}
          </button>
        </div>
      </div>
      {(contentProgress || userState.role !== 'STUDENT') && modalVisible && (
        <ModalQuiz
          visible={modalVisible}
          content={content}
          contentProgress={contentProgress}
          onClose={() => {
            setModalVisible(false);
          }}
          onUpdateProgress={(newContentProgress, newModuleProgress) => {
            setContentProgress(newContentProgress);
            if (newModuleProgress) {
              onSetModuleProgress(newModuleProgress);
              onSetContentCompleted(newContentProgress.contentId);
            }
          }}
        />
      )}
    </div>
  );
};
