import { atom } from 'recoil';
import { UserType, Content, AnswerByContent } from './types';

const user = window.localStorage.getItem('loggedUser');
export const userStateRecoil = atom<UserType>({
  key: 'user',
  default: user ? JSON.parse(user) : {},
});

export const authStateRecoil = atom<boolean>({
  key: 'auth',
  default: user ? true : false,
});

export const contentListState = atom<Content[]>({
  key: 'contentListState',
  default: [],
});

export const userAnswersState = atom<AnswerByContent[]>({
  key: 'userAnswers',
  default: [],
});
