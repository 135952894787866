import React, { useState, useEffect } from 'react';
import { InputContent } from './InputContent';
import { Modal } from 'components/Modal';
import { Dropdown } from './DropDown';
import { Button } from 'components/Button';
import { Question } from 'contexts/types';
import TimeField from 'react-simple-timefield';

interface IProps {
  question: Question;
  visible: boolean;
  onClose: () => void;
  onSave: (question: Question) => void;
  choices: Array<string>;
  setChoices: React.Dispatch<React.SetStateAction<string[]>>;
}

export const TimestampedQuestionForm = ({
  question,
  visible,
  onClose,
  onSave,
  choices,
  setChoices,
}: IProps) => {
  const [currentQuestion, setCurrentQuestion] = useState(question);

  useEffect(() => {
    setCurrentQuestion(question);
  }, [visible]);

  const onChangeQuestion = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentQuestion((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onDeleteOption = (index: number) => {
    const auxChoices = [...choices];
    auxChoices.splice(index, 1);
    setChoices(auxChoices);

    setCurrentQuestion((prevState) => {
      return {
        ...prevState,
        options: auxChoices,
      };
    });
  };

  return (
    <>
      <Modal
        visible={visible}
        onClose={onClose}
        hiddeCancelButton
        width="9/12"
        style="border-4 border-primary p-2"
      >
        <div className="flex items-center space-x-2 grid grid-cols-12 space-y-1">
          <span className="text-right col-span-2">Question:</span>
          <div className="col-span-9">
            <InputContent
              type="text"
              name="question"
              value={currentQuestion.question}
              onChange={onChangeQuestion}
            />
          </div>
          <span className="text-right col-span-2">Timestamp:</span>
          <div className="flex col-span-9">
            <TimeField
              value={currentQuestion.timestamps}
              onChange={onChangeQuestion}
              input={
                <InputContent
                  type="text"
                  resize="36"
                  name="timestamps"
                  className="form-control"
                />
              }
              showSeconds
            />
          </div>

          {choices.map((choice, index) => (
            <>
              {index == 0 ? (
                <span className="text-right col-span-2">Choices:</span>
              ) : (
                <span className="col-span-2" />
              )}
              <div className="flex col-span-9">
                <InputContent
                  type="text"
                  defaultValue={choice}
                  onChange={(event) =>
                    setCurrentQuestion((prevState) => {
                      const newOptions = [...prevState.options];
                      newOptions[index] = event.target.value;
                      return {
                        ...prevState,
                        options: [...newOptions],
                      };
                    })
                  }
                />
                {index > 1 && (
                  <button
                    onClick={() => onDeleteOption(index)}
                    className="underline text-red-500 hover:text-red-800 col-span-9 text-left  ml-2"
                  >
                    X
                  </button>
                )}
              </div>
            </>
          ))}
          <span className="col-span-2" />
          <button
            onClick={() => setChoices([...choices, ''])}
            className="underline text-green hover:text-green-dark col-span-9 text-left w-36"
          >
            Add Choice
          </button>
          <span className="text-right col-span-2">Correct Choice:</span>
          <div className="col-span-9">
            <Dropdown
              value={currentQuestion.correctAnswer}
              options={currentQuestion.options}
              onChange={(value) =>
                setCurrentQuestion((prevState: any) => {
                  return { ...prevState, correctAnswer: value };
                })
              }
            />
          </div>
          <span className="text-right col-span-2">Explanation:</span>
          <div className="col-span-9">
            <InputContent
              type="text"
              name="explanation"
              value={currentQuestion.explanation}
              onChange={onChangeQuestion}
            />
          </div>
        </div>
        <div className="mt-4 flex space-x-2 justify-end">
          <Button type="submit" variant="white" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            onClick={() => onSave(currentQuestion)}
          >
            Save
          </Button>
        </div>
      </Modal>
    </>
  );
};
