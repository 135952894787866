import { useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { default as toWebVTT } from 'srt-webvtt';
import {
  PlayIcon,
  PauseIcon,
  VolumeUpIcon,
  VolumeOffIcon,
} from '@heroicons/react/solid';

import { Text } from 'components/Text';
import { Content, ContentProgress, Question } from 'contexts/types';
import { Questions } from './Questions';
import { convertStringTimetoSeconds, formatTime } from 'utils/date-utils';
import { ProgressService } from 'services';
import { EndMessage } from './EndMessage';
import useFullscreenStatus from 'hooks/useFullScreen';
import {
  CaptionsIcon,
  ExitFullScreenIcon,
  FullScreenIcon,
} from 'components/Icons';
import * as S from './styles';
import { userStateRecoil } from 'contexts/atoms';
import { useRecoilState } from 'recoil';

interface VideoProps {
  content: Content;
  userId: string;
  onSetModuleProgress: (progress: number) => void;
  onSetContentCompleted: (contentId: string) => void;
}

export const Video = ({
  content,
  userId,
  onSetModuleProgress,
  onSetContentCompleted,
}: VideoProps) => {
  const [videoState, setVideoState] = useState({
    url: null,
    playing: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    playedSeconds: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    seeking: false,
    captions: false,
  });
  const [userState] = useRecoilState(userStateRecoil);

  const {
    playing,
    light,
    muted,
    loop,
    playbackRate,

    volume,
  } = videoState;

  const playerContainerRef = useRef<HTMLDivElement>();
  const playerRef = useRef<ReactPlayer>();
  const video = document.getElementById(`video-${content.id}`);

  const [showQuestion, setShowQuestion] = useState(false);
  const [questionsAux, setQuestionsAux] = useState<number[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState<Question>();
  const [score, setScore] = useState<number>(0);
  const [attempts, setAttempts] = useState<number>(0);
  const [isReady, setIsReady] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [openRateSelector, setOpenRateSelector] = useState(false);
  const [showVolSlider, setShowVolSlider] = useState(false);

  const [isFullscreen, setIsFullscreen] =
    useFullscreenStatus(playerContainerRef);

  const [contentProgress, setContentProgress] = useState<ContentProgress>();

  const totalQuestions = content.questions?.length;

  const questionsTime = content?.questions?.map((item) => {
    return convertStringTimetoSeconds(item.timestamps);
  });

  const onReady = useCallback(async () => {
    if (!isReady) {
      if (userState.role == 'STUDENT') {
        const contentProgress = await getProgressByContent();
        setContentProgress(contentProgress);
        const auxScore = !!totalQuestions && 0;
        setScore(contentProgress.score || auxScore);
        setAttempts(contentProgress.attempts);

        if (contentProgress?.seen) {
          onSetContentCompleted(contentProgress?.contentId);
        }

        playerRef?.current?.seekTo(contentProgress.timestamps, 'seconds');

        setVideoState((prevState) => {
          return {
            ...prevState,
            playedSeconds: contentProgress.timestamps,
          };
        });
      }

      setIsReady(true);
    }
  }, [isReady]);

  const handleSeekMouseDown = (e) => {
    setVideoState((prevState) => {
      return { ...prevState, seeking: true };
    });
  };

  const handleSeekMouseUp = (e) => {
    setVideoState((prevState) => {
      return { ...prevState, seeking: false };
    });
    playerRef?.current?.seekTo(parseFloat(e.target.value));
  };

  const handleSeekChange = (e) => {
    setVideoState((prevState) => {
      return {
        ...prevState,
        played: parseFloat(e.target.value),
        playing: true,
      };
    });
  };

  const handlePlay = () => {
    setVideoState((prevState) => {
      return { ...prevState, playing: true };
    });
    setShowQuestion(false);
  };

  const handlePause = () => {
    setVideoState((prevState) => {
      return { ...prevState, playing: false };
    });
  };

  const handleVolumeChange = (newValue: number) => {
    setVideoState((prevState) => {
      return {
        ...prevState,
        volume: newValue / 100,
        muted: +newValue === 0 ? true : false,
      };
    });
  };

  const handleProgress = ({ played, playedSeconds, loaded }) => {
    if (!videoState.seeking) {
      const newPlayedSeconds = Math.trunc(playedSeconds);

      let playing = videoState.playing;
      if (
        questionsTime?.includes(newPlayedSeconds) &&
        !questionsAux.includes(newPlayedSeconds)
      ) {
        playing = false;
        const newState = [...questionsAux, newPlayedSeconds];
        setQuestionsAux(newState);

        const question = content.questions
          ?.map((item, index) => {
            if (
              convertStringTimetoSeconds(item.timestamps) == newPlayedSeconds
            ) {
              return { ...item, index }; // add index necessary to identify what is the current question's index
            }
          })
          .filter((item) => !!item)[0];
        setCurrentQuestion(question);
        setShowQuestion(true);
      }

      if (showQuestion && questionsTime?.includes(newPlayedSeconds))
        playing = false;

      if (playedSeconds === videoState.duration) {
        playing = false;
        setQuestionsAux([]);
      }

      setVideoState((prevState) => {
        return {
          ...prevState,
          playedSeconds: newPlayedSeconds,
          played,
          loaded,
          playing,
        };
      });
    }
  };

  const handleDuration = (duration: number) => {
    setVideoState((prevState) => {
      return {
        ...prevState,
        duration,
      };
    });
  };

  const toggleFullScreen = () => {
    setIsFullscreen();
    video?.classList.remove('max-h-500');
  };

  const handleExitFullscreen = () => {
    document.exitFullscreen();
    video?.classList.add('max-h-500');
  };

  const getProgressByContent = async () => {
    const response = await ProgressService.getProgressByContent(
      content.id,
      userId,
      content?.moduleId,
    );
    return response?.data;
  };

  const toggleCaptions = () => {
    const track = video?.getElementsByTagName('track')[0];
    if (track && track.track.mode === 'hidden') {
      track.track.mode = 'showing';
    } else if (track && track.track.mode === 'showing') {
      track.track.mode = 'hidden';
    }
  };

  const handlePlaybackRate = (rate: number) => {
    setVideoState((prev) => {
      return { ...prev, playbackRate: rate };
    });
  };

  let timeout: NodeJS.Timeout;
  const elem = document.getElementById(`video-${content.id}`);
  elem?.addEventListener('mousemove', function () {
    if (elem) {
      setShowControls(true);
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        setShowControls(false);
      }, 3000);
    }
  });

  const saveProgressVideo = async () => {
    let auxIsPlaying = false;
    let auxTimestamps = 0;
    setVideoState((prevState) => {
      auxIsPlaying = prevState.playing;
      auxTimestamps = prevState.playedSeconds;
      return {
        ...prevState,
      };
    });
    try {
      if (auxIsPlaying) {
        await ProgressService.createOrUpdateContentProgress({
          contentId: content.id,
          userId, 
          timestamps: auxTimestamps,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      saveProgressVideo();
    }, 15000);

    return () => clearInterval(interval);
  }, []);

  const createVtt = async () => {
    try {
      const data =
        content?.url?.urlScript && (await fetch(content?.url?.urlScript));
      const blob = data && (await data.blob());

      const textTrackUrl = blob && (await toWebVTT(blob)); // this function accepts a parameer of SRT subtitle blob/file object
      video?.classList.add('max-h-500');

      const trackElement = video?.getElementsByTagName('track')[0];
      if (trackElement && textTrackUrl) {
        trackElement.src = textTrackUrl;
        trackElement.track.mode = 'hidden';
      } // Set the converted URL to track's source
    } catch (e: any) {
      console.error(e?.message);
    }
  };

  useEffect(() => {
    createVtt();
  }, [video]);

  return (
    <div className="relative flex flex-col justify-center itemscenter">
      <div
        className={`relative transition duration-1000 ease-in-out border-2 ${
          !playing ? 'border-orange' : 'border-pink-200'
        } mx-auto w-10/12`}
      >
        <div
          id={`controls-${content.id}`}
          ref={playerContainerRef as React.LegacyRef<HTMLDivElement>}
          className="relative transition-all delay-700 duration-75 ease-in-out"
        >
          <ReactPlayer
            ref={playerRef as React.LegacyRef<ReactPlayer>}
            width="100%"
            height="100%"
            url={content.url?.urlMedia}
            playing={playing}
            controls={false}
            light={light}
            loop={loop}
            playbackRate={playbackRate}
            volume={volume}
            muted={muted}
            progressInterval={100}
            onReady={onReady}
            onDuration={handleDuration}
            onPlay={handlePlay}
            onPause={handlePause}
            onProgress={handleProgress}
            onEnded={async () => {
              if (!contentProgress?.seen || attempts <= 2) {
                const auxScore = totalQuestions == 0 ? 100 : score;
                const response = await ProgressService.updateContentProgress(
                  contentProgress?.id || '',
                  {
                    seen: true,
                    moduleId: content.moduleId,
                    attempts,
                    timestamps: videoState.duration,
                    progressContent: 100,
                    score: auxScore,
                  },
                );
                if (response?.data) {
                  onSetModuleProgress(response?.data.moduleProgress);
                  onSetContentCompleted(contentProgress?.contentId || '');
                }
              } else {
                await ProgressService.updateContentProgress(
                  contentProgress?.id || '',
                  {
                    timestamps: videoState.duration,
                  },
                );
              }
            }}
            config={{
              file: {
                attributes: {
                  id: `video-${content.id}`,
                  crossOrigin: 'anonymous',
                },
                tracks: [
                  {
                    kind: 'subtitles',
                    src: '', // set on useEffect where we convert SRT to WebVTT
                    srcLang: 'en',
                    label: 'English',
                  },
                ],
              },
            }}
          />
          {showQuestion && (
            <Questions
              contentId={content.id}
              itemQuestion={currentQuestion}
              isFullscreen={isFullscreen}
              total={totalQuestions || 0}
              handleNext={async (wasCorrect, userAnswer) => {
                setShowQuestion(false);
                setVideoState((prevState) => {
                  return {
                    ...prevState,
                    playing: true,
                  };
                });

                const questionWeight = 100 / (totalQuestions || 1);
                setScore((prev) => {
                  if (totalQuestions && prev / 100 < 100) {
                    return wasCorrect ? prev + questionWeight : prev;
                  }
                  return prev;
                });

                questionsAux.length == questionsTime?.length &&
                  setAttempts((prev) => prev + 1);

                // save every answer of the user
                const newUserAnswers = contentProgress?.userAnswers || [];
                const answer = {
                  questionId: `${currentQuestion?._id}` || '',
                  answer: userAnswer,
                };
                const index = newUserAnswers.indexOf(
                  newUserAnswers.filter(
                    (item) => item.questionId == currentQuestion?._id,
                  )[0],
                );
                if (index < 0) {
                  newUserAnswers.push(answer);
                } else {
                  newUserAnswers[index] = {
                    questionId: `${currentQuestion?._id}` || '',
                    answer: userAnswer,
                  };
                }

                if (score !== 100 && contentProgress && attempts < 2) {
                  await ProgressService.updateContentProgress(
                    contentProgress?.id || '',
                    {
                      attempts: attempts + 1,
                      score:
                        totalQuestions && score < 100 && wasCorrect
                          ? score + questionWeight
                          : score,
                      userAnswers: newUserAnswers,
                    },
                  );
                }
              }}
            />
          )}
          {videoState.played == 1 && (
            <EndMessage
              score={`${
                content.questions?.length
                  ? (score * (content.questions?.length || 1)) / 100 +
                    '/' +
                    content.questions?.length
                  : ''
              }`}
            />
          )}
          {/* CONTROLS */}
          <div
            className={`absolute bottom-1 px-3 w-full ${
              !videoState.playing
                ? 'block'
                : showControls
                ? 'opacity-100'
                : 'hidden'
            }`}
          >
            <div className="relative flex justify-between w-full h-9">
              <div
                className="flex space-x-2 items-center"
                onMouseLeave={(ev) => setShowVolSlider(false)}
              >
                <button
                  className="h-6 w-6 text-orange"
                  onClick={() => {
                    setVideoState((prev) => {
                      return {
                        ...prev,
                        playing: !prev.playing,
                        playedSeconds:
                          videoState.duration == contentProgress?.timestamps
                            ? 0
                            : contentProgress?.timestamps || 0,
                      };
                    });
                    if (videoState.played == 1) {
                      setScore(0);
                      setTimeout(() => {
                        setVideoState((prev) => {
                          return {
                            ...prev,
                            playing: true,
                          };
                        });
                      }, 20);
                    }
                  }}
                >
                  {!videoState.playing ? <PlayIcon /> : <PauseIcon />}
                </button>

                <button
                  className="h-6 w-6 text-orange"
                  onClick={() => {
                    setVideoState((prev) => {
                      return {
                        ...prev,
                        muted: !prev.muted,
                      };
                    });
                  }}
                  onMouseEnter={(ev) => setShowVolSlider(true)}
                >
                  {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                </button>
                {showVolSlider && (
                  <input
                    className="h-2 w-24 cursor-pointer z-20 appearance-none overflow-hidden bg-gray-300 rounded-full outline-none slider-thumb-played"
                    type="range"
                    min={0}
                    max={100}
                    step="any"
                    value={muted ? 0 : volume * 100}
                    onChange={(e) => {
                      handleVolumeChange(+e?.target?.value);
                    }}
                  />
                )}
                <div className="flex  text-orange">
                  <Text variant="paragraph">
                    {formatTime(videoState.playedSeconds)}
                  </Text>
                  /
                  <Text variant="paragraph">
                    {formatTime(videoState.duration)}
                  </Text>
                </div>
              </div>
              <div className="flex space-x-2 items-center">
                <div>
                  <button
                    className="text-center w-10 text-orange"
                    onClick={() => {
                      setOpenRateSelector((prev) => !prev);
                    }}
                  >
                    {playbackRate}x
                  </button>
                  {openRateSelector && (
                    <S.ListContainer className="absolute bottom-full bg-white text-orange">
                      {[0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2].map(
                        (rate, index) => (
                          <S.ListItem
                            className={`cursor-pointer flex justify-center hover:bg-gray-100 ${
                              playbackRate == rate && 'bg-gray-300'
                            }`}
                            key={index}
                            onClick={() => {
                              handlePlaybackRate(rate);
                              setOpenRateSelector(false);
                            }}
                          >
                            {rate}x
                          </S.ListItem>
                        ),
                      )}
                    </S.ListContainer>
                  )}
                </div>

                <button
                  className="h-6 w-6 text-orange"
                  onClick={() => {
                    toggleCaptions();
                  }}
                >
                  <CaptionsIcon />
                </button>
                <button
                  className="h-5 w-5 text-orange"
                  onClick={() => {
                    isFullscreen ? handleExitFullscreen() : toggleFullScreen();
                  }}
                >
                  {isFullscreen ? <ExitFullScreenIcon /> : <FullScreenIcon />}
                </button>
              </div>
            </div>
            <div className="relative w-full h-2.5">
              <input
                className="absolute h-2.5 cursor-pointer z-20 left-0 appearance-none overflow-hidden w-full bg-transparent rounded-full outline-none slider-thumb-played"
                type="range"
                min={0}
                max={1}
                step="any"
                value={videoState.played}
                onMouseDown={handleSeekMouseDown}
                onChange={handleSeekChange}
                onMouseUp={handleSeekMouseUp}
              />

              <input
                className="absolute h-2.5 z-10 left-0 appearance-none overflow-hidden w-full bg-gray-300 rounded-full outline-none slider-thumb-loaded"
                type="range"
                min={0}
                max={0.999999}
                step="any"
                defaultValue={videoState.loaded}
              />
              {questionsTime?.map((item, index) => {
                // block questions with time greater than video duration,TODO: fix on AddContent view
                if (item <= videoState.duration)
                  return (
                    <span
                      key={index}
                      data-index="1"
                      style={{
                        left: `${(item * 100) / videoState.duration}%`,
                        width: '5px',
                        height: '14px',
                        top: '-2px',
                      }}
                      className="absolute bg-green z-30 rounded-full"
                    ></span>
                  );
              })}
            </div>
          </div>
          {/* end of controls */}
        </div>
      </div>
    </div>
  );
};
