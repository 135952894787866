import React from 'react';
import { formatNumber } from 'utils/utils';

interface RowProps {
  item: Record<string, any>;
  columns: Array<any>;
}

const showModuleProgress = (item, col) => {
  const progresses = item?.modulesProgresses;
  let findModuleOrder;
  if (progresses?.length) {
    findModuleOrder = progresses.find(
      (mProgress) =>
        mProgress?.moduleId?._id?.toString() === col?._id?.toString(),
    );
    if (findModuleOrder) {
      return Math.round(findModuleOrder?.progress);
    }
    return 0;
  }
  return 0;
};

export const Row = ({ item, columns }: RowProps): JSX.Element => {
  return (
    <tr className="bg-primary">
      <td
        key={'col0'}
        className={`border-none first:rounded-l-2xl first:text-secondary px-4 py-2  }`}
      >
        {item.name} {item.surname}
      </td>
      <td key={'col1'} className={`border-none px-4 py-2 text-white`}>
        {item.email}
      </td>
      {columns.map((col, index) => {
        if (index === 2) {
          return <td key={index} className="border-none"></td>;
        }
        if (Object.values(col).length) {
          return (
            <td
              key={index}
              className={`border-none last:rounded-r-2xl px-4 py-2 text-white ${
                index >= 3 && 'text-center'
              }`}
            >
              {showModuleProgress(item, col)}
            </td>
          );
        }
      })}
    </tr>
  );
};
