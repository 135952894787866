import React from 'react';

import * as S from '../Basic/styles';
import { Menu } from 'components/Menu';

interface IProps {
  children: React.ReactNode;
}

export const ModulesOutline = ({ children }: IProps): JSX.Element => {
  return (
    <S.Container className="bg-pink-200">
      <div className="absolute right-8 top-8 z-50">
        <Menu
          list={[
            { text: 'Dashboard', link: '/' },
            {
              text: 'XYLO Home',
              link: 'https://xyloacademy.com/',
              externalPage: true,
            },
            { text: 'Logout', link: '/logout' },
          ]}
        />
      </div>
      <div className="w-full h-full px-10 pt-28 pb-2">
        <div className="relative flex flex-col items-end lg:pr-32 z-40">
          <h2 className="text-secondary text-3xl mb-2">
            Disruptive Innovation
          </h2>
          <h3 className="text-primary text-2xl">Course Outline</h3>
        </div>
        <div className="mt-12 overflow-auto md:overflow-visible">
          {children}
        </div>
      </div>
    </S.Container>
  );
};
