import { useEffect, useState } from 'react';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { Question } from 'contexts/types';
import { ContentService } from 'services';
import { shuffleArray } from 'utils/utils';

interface QuestionsProps {
  contentId: string;
  itemQuestion?: Question;
  total: number;
  isFullscreen: boolean;
  handleNext: (wasCorrect: boolean, userAnswer: string) => void;
}

export const Questions = ({
  contentId,
  total,
  itemQuestion,
  isFullscreen,
  handleNext,
}: QuestionsProps) => {
  const [currentOptions, setCurrentOptions] = useState<Array<string>>([]);
  const [value, setValue] = useState('');
  const [checked, setChecked] = useState(-1);
  const [change, setChange] = useState(false);
  const [validation, setValidation] = useState<{
    correctAnswer: string;
    explanation: string;
    isCorrect: boolean;
  }>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const index = itemQuestion?.index || 0;

  const validateAnswer = async (userAnswer: string) => {
    try {
      setLoading(true);
      const response = await ContentService.validateAnswersContent(contentId, {
        answer: userAnswer,
        questionId: itemQuestion?._id || '',
      });
      setLoading(false);
      setValidation(response?.data);
      return response;
    } catch (error) {
      setLoading(false);
      setError('Something went wrong. Try again');
    }
  };

  useEffect(() => {
    if (itemQuestion) {
      setCurrentOptions(shuffleArray(itemQuestion.options || []));
    }
  }, []);

  return (
    <div className="px-4 py-2  absolute inset-0 bg-pink-200">
      <div
        className={`${
          isFullscreen
            ? 'absolute w-full left-0 px-20 inset-y-1/3 space-y-4'
            : 'space-y-2 h-full'
        }`}
      >
        <div className="px-3 h-16 bg-primary rounded-full flex items-center">
          <Text variant="paragraph" color="white">
            Video intermittent question
            {` ${index + 1}/${total}`}
          </Text>
        </div>

        <div className="px-2">
          <Text variant="paragraph" color="secondary">
            {itemQuestion?.question}
          </Text>
          <div className="flex">
            <div className="w-1/2">
              {currentOptions.map((option, index) => {
                return (
                  <div key={index} className="flex items-center space-x-1 pt-2">
                    <input
                      id={`option-${index}`}
                      type="radio"
                      disabled={checked !== -1}
                      onChange={async (event) => {
                        setValue(option);
                        setChecked(index);
                        await validateAnswer(option);
                        setChange(true);
                      }}
                      checked={index === checked}
                      className={`form-radio h-5 w-5 border-primary cursor-pointer focus:ring-transparent ${
                        !change
                          ? 'text-primary'
                          : option === validation?.correctAnswer
                          ? 'text-green'
                          : 'text-red-500'
                      }`}
                    />
                    <label htmlFor={`option-${index}`}>
                      <Text variant="paragraph" color="secondary">
                        {option}
                      </Text>
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="w-1/2">
              {loading ? 'Loading...' : error && error}
              {!!validation ? (
                value == validation?.correctAnswer ? (
                  <Text variant="paragraph" color="green">
                    Correct
                  </Text>
                ) : (
                  <>
                    <Text variant="paragraph" color="red-500">
                      Incorrect
                    </Text>
                    <Text variant="paragraph" color="primary">
                      The correct answer is:
                    </Text>
                    <Text variant="paragraph" color="primary" resize="18px">
                      {validation?.correctAnswer}
                    </Text>
                    <Text variant="paragraph" color="primary">
                      {validation?.explanation}
                    </Text>
                  </>
                )
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-6">
          <Button
            variant="primary"
            onClick={() => {
              handleNext(
                value === validation?.correctAnswer ? true : false,
                value,
              );
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
