import React, { ReactNode } from 'react';
import './styles.css';

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'white' | 'red' | 'green';
  icon: ReactNode;
  bg: string;
  border?: string;
}

export const IconButton = ({ icon, bg, border, ...props }: IButton) => {
  return (
    <button
      {...props}
      className={`min-w-ibtn w-12 h-12 rounded-full bg-${bg} ${border} opacity-90 flex justify-center items-center hover:opacity-100`}
    >
      {icon}
    </button>
  );
};
