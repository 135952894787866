import React, { useState } from 'react';
import { Button } from 'components/Button';

import { Title } from 'components/Title/styles';
import { AddUsersLayout } from 'layouts/AddUsers';
import { toast } from 'react-toastify';
import { UsersService } from '../../services';
import { Input } from 'components/Input';
import { Modal } from 'components/Modal';

export default function DeleteUsers() {
  const [email, setEmail] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const handleDelete = () => {
    if (email) {
      toast
        .promise(UsersService.deleteUserByEmail(email), {
          pending: 'Checking...',
          success: 'User deleted successfully!',
          error: {
            render({ data }: any) {
              return data.data?.error || 'Something went wrong!';
            },
          },
        })
        .catch((err) => {
          toast.error(err.error);
        });
      setEmail('');
    }
  };

  return (
    <AddUsersLayout>
      <div className="flex flex-col justify-center itemscenter w-full mxauto">
        <Title className="mb-4 text-center text-secondary">Delete User</Title>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setIsDeleteModalOpen(true);
          }}
        >
          <div>
            <p className="text-primary mb-8 text-center">
              Add the user email
              <br />
            </p>
          </div>
          <div className="flex items-center w-full justify-center">
            <Input
              type="email"
              className="w-full"
              required
              value={email}
              onChange={(e) => {
                setEmail(e.currentTarget.value);
              }}
            />
          </div>
          <div className="mt-8 flex justify-center">
            <Button type="submit" variant="fullRounded">
              Continue
            </Button>
          </div>
        </form>
      </div>
      <Modal
        visible={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        style="bg-yellow text-white text-center"
        width="1/2"
      >
        <h1 className="text-xl">Are you sure you want to delete this User?</h1>
        <div className="flex justify-around w-full mt-3">
          <Button
            variant="white"
            onClick={() => {
              setIsDeleteModalOpen(false);
            }}
          >
            No, go back.
          </Button>
          <Button
            variant="red"
            onClick={() => {
              email && handleDelete();
              setIsDeleteModalOpen(false);
            }}
          >
            Yes, delete.
          </Button>
        </div>
      </Modal>
    </AddUsersLayout>
  );
}
