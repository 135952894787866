import { authAxiosCall } from '../axiosCall';
import { AxiosResponse } from 'axios';
import { OrderListContent, UpdateContent, UserAnswer } from 'types';
import { Content } from 'contexts/types';

const BASE_PATH = 'contents';

export const getContentList = async (
  moduleId: string,
): Promise<AxiosResponse<any> | undefined> => {
  return authAxiosCall(`${BASE_PATH}/modules/${moduleId}`, {
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  });
};

export const getContent = async (
  id: string,
): Promise<AxiosResponse<any> | undefined> => {
  return authAxiosCall(`${BASE_PATH}/${id}`, {
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  });
};

// Check if it is necessary
export const validateAnswersContent = async (
  id: string,
  useranswer: UserAnswer,
): Promise<AxiosResponse<any> | undefined> => {
  const data = useranswer;
  return authAxiosCall(`${BASE_PATH}/${id}/validations`, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    data,
  });
};

export const updateOrderAndStatusContent = async (
  list: OrderListContent[],
): Promise<AxiosResponse<any> | undefined> => {
  const data = { docs: list };
  return authAxiosCall(`${BASE_PATH}/order`, {
    method: 'PATCH',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    data,
  });
};

export const updateContent = async (
  contentId: string,
  content: UpdateContent,
): Promise<AxiosResponse<any> | undefined> => {
  return authAxiosCall(`${BASE_PATH}/${contentId}`, {
    method: 'PATCH',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    data: content,
  });
};

export const createContent = async (
  content: Omit<Content, 'id'>,
): Promise<AxiosResponse<any, any> | undefined> => {
  return authAxiosCall(BASE_PATH, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: content,
  });
};

export const getPresignedUrl = async (
  key: string,
  contentType: string,
): Promise<AxiosResponse<any, any> | undefined> => {
  return authAxiosCall(
    `${BASE_PATH}/presigned-url/params?key=${key}&contentType=${contentType}`,
    {
      method: 'GET',
    },
  );
};

export const deleteContent = async (
  contentId: string,
): Promise<AxiosResponse<any> | undefined> => {
  return authAxiosCall(`${BASE_PATH}/${contentId}`, {
    method: 'DELETE',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  });
};
