import React from 'react';
import * as S from './styles';
import { Menu } from 'components/Menu';

interface IProps {
  children: React.ReactNode;
}

export default function Basic({ children }: IProps) {
  return (
    <S.Container className="bg-pink-200">
      <div className="absolute right-8 top-8 z-40">
        <Menu list={[{ text: 'XYLO Home', link: '/' }]} />
      </div>
      <S.Content>{children}</S.Content>
    </S.Container>
  );
}
