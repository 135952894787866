import React from 'react';

interface InputCodeProps extends React.InputHTMLAttributes<HTMLInputElement> {
  numberDigits?: number;
  getCode: (code: string) => void;
}

export const InputCode = ({ numberDigits = 5, getCode }: InputCodeProps) => {
  const [value, setValue] = React.useState('');

  const elemRefs: Array<React.RefObject<HTMLInputElement>> = [];

  const autoTab = (event: React.KeyboardEvent<HTMLInputElement>) => {
    let tabindex = event.currentTarget.getAttribute('data-index') || 0;
    tabindex = Number(tabindex);
    let elem;
    if (event.key === 'Backspace' && tabindex > 0) {
      elem = elemRefs[tabindex - 1];
      elem.current.focus();
    } else if (
      event.key !== 'Delete' &&
      elemRefs[tabindex].current?.value &&
      tabindex < elemRefs.length - 1
    ) {
      elem = elemRefs[tabindex + 1];
      elem.current.focus();
    }
    getValues();
  };

  const getValues = () => {
    let code = '';
    elemRefs.forEach((elem, index) => {
      if (elem.current) {
        code += elem.current.value;
        setValue(code);
      }
    });
  };

  // function from props
  getCode(value);

  return (
    <div className="space-x-2">
      {Array.from({ length: numberDigits }, (element, index) => {
        const ref = React.useRef<HTMLInputElement>(null);
        elemRefs.push(ref);

        return (
          <input
            key={index}
            name={`inputCode${index}`}
            id={`index`}
            className="w-8 h-11 bg-blue-100 rounded-lg rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-100 text-center"
            data-index={index}
            ref={ref}
            maxLength={1}
            onKeyUp={autoTab}
          />
        );
      })}
    </div>
  );
};
