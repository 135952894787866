import { authAxiosCall } from '../axiosCall';
import { AxiosResponse } from 'axios';

const BASE_PATH = 'schools';

export const getSchoolsData = async (): Promise<
  AxiosResponse<any> | undefined
> => {
  return authAxiosCall(`${BASE_PATH}`, {
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  });
};

export const getSchool = async (
  schoolId: string,
): Promise<AxiosResponse<any> | undefined> => {
  return authAxiosCall(`${BASE_PATH}/${schoolId}`, {
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  });
};
