/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { useTable, UseTableOptions, Column, ColumnGroup } from 'react-table';

import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Row } from '../Row';
import { Styles } from './styles';

export const Table = <T extends object>({
  columns,
  data,
}: UseTableOptions<T>): JSX.Element => {
  const hiddenColumns: string[] = [];
  (columns as ReadonlyArray<Column<T>>)
    .filter((element) => !element.accessor)
    .forEach((element) => {
      const newElement = element as ColumnGroup<T>;
      newElement.columns.forEach((column, index) => {
        index != 0 && hiddenColumns.push(column.id as string);
      });
    });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
  } = useTable({
    columns,
    data,
    initialState: {
      hiddenColumns: hiddenColumns,
    },
  });

  return (
    <Styles>
      <table
        {...getTableProps()}
        className="text-left border-separate border-spacing w-full border-none"
      >
        <thead className="text-white w-max sticky top-0 bg-pink-200 z-10">
          {headerGroups.map((headerGroup, indexGroup) => {
            const { key, ...getHeaderGroupProps } =
              headerGroup.getHeaderGroupProps();
            return indexGroup === 0 ? null : (
              <tr key={key} {...getHeaderGroupProps}>
                {headerGroup.headers.map((column, index) => {
                  const { key, ...getHeaderProps } = column.getHeaderProps();
                  return (
                    <th
                      key={key}
                      {...getHeaderProps}
                      className={`${
                        column.parent?.Header === column.Header
                          ? 'text-secondary min-w-head1'
                          : column.parent
                          ? 'text-blue-200 text-xs min-w-head2'
                          : 'text-blue-200'
                      } border-none`}
                    >
                      <div
                        className="flex cursor-pointer w-full"
                        onClick={() => {
                          allColumns?.forEach((col) => {
                            if (
                              col.parent?.Header === column.Header &&
                              col.id !== column.id
                            ) {
                              col.toggleHidden(col?.isVisible);
                            }
                          });
                        }}
                      >
                        <div
                          className={`${
                            column.parent?.Header === column.Header
                              ? ''
                              : column.parent
                              ? 'sm-col'
                              : ''
                          }`}
                        >
                          {column?.render('Header')}
                        </div>
                        {column?.parent?.Header === column.Header &&
                          (column?.parent?.columns?.[1]?.isVisible ? (
                            <ChevronRightIcon className="h-5 w-5" />
                          ) : (
                            <ChevronDownIcon className="h-5 w-5" />
                          ))}
                      </div>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className="border-separate overflow-auto no-scrollbar w-full"
        >
          {rows.map((row, index) => {
            prepareRow(row);
            return <Row key={index} {...row} />;
          })}
        </tbody>
      </table>
    </Styles>
  );
};
