import React from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';

interface DropdownProperties {
  options?: Array<string>;
  onChange: (value: string) => void;
  value?: string;
  primary?: string;
  secondary?: string;
  disabled?: boolean;
}

export const Dropdown: React.FC<DropdownProperties> = ({
  options,
  onChange,
  value,
  disabled,
}: DropdownProperties) => {
  const [open, setOpen] = React.useState(false);

  const CONTAINER = 'flex flex-col relative justify-center w-fit';

  const OPTION_CONTAINER = `w-fit min-w-dropdownOptions pt-2 text-primary max-h40 border border-primary ${
    open
      ? 'block transition-all ease-in-out duration-150 h-fit max-h-24'
      : 'hidden max-h-0'
  } items-start justify-start absolute z-10 top-4 left-2 border rounded-lg border-gray-5 bg-pink-100 overflow-scroll no-scrollbar`;

  const OPTION =
    'flex flex-start text-left text-secondary w-full hover:bg-gray-200 cursor-pointer px-5 justify-start items-center';

  return (
    <div className={CONTAINER}>
      <div className="w-full">
        <button
          disabled={disabled}
          className={`relative flex pl-1.5 pr-5 rounded-full bg-primary text-white z-20 ${
            value && 'w-full'
          } min-w-36 text-ellipsis overflow-hidden`}
          onClick={() => {
            setOpen(true);
            if (!onclick) {
              setOpen(!open);
            }
          }}
        >
          <p className="truncate">{value || 'Select'}</p>
          <ChevronDownIcon className="w-6 absolute top-0 right-0" />
        </button>
      </div>
      <div className={OPTION_CONTAINER}>
        {options?.map((item, index) => (
          <button
            className={`${OPTION} outline-none focus:outline-none ${
              item === value ? 'bg-gray-300' : ''
            }`}
            key={index}
            onClick={() => {
              onChange(item);
              setOpen(false);
            }}
          >
            {item}
          </button>
        ))}
      </div>
    </div>
  );
};
