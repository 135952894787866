import { useEffect, useState } from 'react';
import * as S from './styles';
import { UserCard } from 'components/UserCard';
import { LinkButton } from 'components/LinkButton';
import CircularProgressBar from 'components/CircularProgressBar';
import { DashboardLayout } from 'layouts/Dashboard';
import { ProgressService, SchoolsService, UsersService } from 'services';
import { userStateRecoil } from 'contexts/atoms';
import { Role, UserType } from 'contexts/types';
import { useRecoilState } from 'recoil';
import { CourseProgress, School } from 'types/index';
import { Loader } from 'components/Loader';
import { reviewLicenseExpiration } from 'utils/utils';

export const DashboardStudent = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [userState] = useRecoilState(userStateRecoil);
  const [user, setUser] = useState<UserType>();
  const [school, setSchool] = useState<School>();
  const [progress, setProgress] = useState<CourseProgress>();

  const handleGetUserInfo = async () => {
    setIsLoading(true);
    const userInfo = await UsersService.getUser(userState.userId);
    const newSchool = await SchoolsService.getSchool(userInfo?.data.school.id);
    const courseProgress = await ProgressService.getCourseProgress(
      newSchool?.data.courses[0].id,
      userInfo?.data.id,
    );

    setUser({
      role: userInfo?.data.roles[0],
      ...userInfo?.data,
    });
    setSchool(newSchool?.data);
    setProgress(courseProgress?.data);
    if (userInfo?.data && newSchool?.data && courseProgress?.data) {
      setIsLoading(false);
    }
    reviewLicenseExpiration(newSchool?.data.license);
  };

  useEffect(() => {
    handleGetUserInfo();
  }, []);

  return (
    <DashboardLayout title="Your Dashboard">
      {isLoading ? (
        <div className="w-full col-span-3 h-full">
          <Loader />
        </div>
      ) : (
        <>
          <div className="col-span-2">
            <div className="mb-12">
              <h2 className="text-primary text-3xl mb-4">Enrolled Courses</h2>
              <S.Row className="mb-4">
                <div className="mr-6">
                  <S.RowTitle className="text-3xl mb-4">
                    {school?.courses[0]?.name}
                  </S.RowTitle>
                  <LinkButton
                    to={`/student/course-outline/${school?.courses?.[0].id}?idSchool=${school?.id}&courseName=${school?.courses?.[0].name}`}
                  >
                    Go To Course Outline
                  </LinkButton>
                </div>
                <CircularProgressBar
                  progress={Math.round(progress?.courseProgress || 0)}
                />
              </S.Row>
              <LinkButton
                to={`/student/school/${school?.id}/forums`}
              >{`Go To Q&A Forums`}</LinkButton>
            </div>
          </div>

          <div className="col-span-1 flex justify-end">
            <UserCard
              role={Role[user?.role as keyof typeof Role]}
              name={`${user?.name} ${user?.surname}`}
              school={user?.school?.name}
              photoUrl={user?.profilePhotoUrl}
            />
          </div>
        </>
      )}
    </DashboardLayout>
  );
};
