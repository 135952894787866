import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Input } from 'components/Input';
import { Title } from 'components/Title/styles';
import { Button } from 'components/Button';
import { toast } from 'react-toastify';
import Basic from 'layouts/Basic';
import { AuthService } from 'services';

export const ResetPassword = (): JSX.Element => {
  const [error, setError] = useState({ inputError: false, isInValid: false });
  const [formData, setFormData] = useState({
    newPassword: '',
    repeatPassword: '',
  });

  const navigate = useNavigate();

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.currentTarget.name]: e.currentTarget.value });
  };

  const checkPassword = () => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[a-zA-Z\d\w\W]{8,}$/;
    if (formData.newPassword !== formData.repeatPassword) {
      setError({
        inputError: true,
        isInValid: false,
      });
    } else if (!formData.newPassword.match(regex)) {
      setError({
        inputError: false,
        isInValid: true,
      });
    } else {
      handleReset();
    }
  };

  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);
  const token = params.get('token');
  const email = params.get('email');

  const handleReset = () => {
    toast
      .promise(
        AuthService.resetPassword(
          email || '',
          formData.newPassword,
          token || '',
        ),
        {
          pending: 'Checking ...',
          success: 'Password successfully reset!',
          error: {
            render({ data }: any) {
              return data.data?.message || 'Something went wrong!';
            },
          },
        },
      )
      .then((res) => {
        if (res) {
          navigate('/login');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Basic>
      <div className="flex flex-col max-w-md mx-auto">
        <Title className="text-center mb-6">Reset Your Password</Title>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            checkPassword();
          }}
        >
          <div className="relative flex flex-col space-y-6 py- px-2 items-center">
            <Input
              name="newPassword"
              type="password"
              required
              placeholder="New Password"
              onChange={handleChange}
            />
            <Input
              name="repeatPassword"
              type="password"
              required
              placeholder="Repeat New Password"
              helperText={error.inputError ? 'Passwords do not match' : ''}
              onChange={handleChange}
              error={error.inputError}
            />
            <p className="max-w-sm mt-2 text-primary lg:absolute bottom-0 left-full md:w-full lg:w-8/12 xl:w-full">
              Your Password must have:
              <span className="block">- At least 8 characters</span>
              <span className="block">
                - A number, a capital letter and a lowercase letter
              </span>
              <span className="block">- A special character (!@#$%)</span>
            </p>
          </div>
          <div className="mt-4 flex justify-center">
            {error.isInValid && (
              <p className="text-red-600">
                Invalid Password. Please try again.
              </p>
            )}
          </div>
          <div className="mt-4 flex justify-center">
            <Button type="submit" variant="fullRounded">
              Reset Password
            </Button>
          </div>
        </form>
      </div>
    </Basic>
  );
};
