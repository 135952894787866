import { DashboardLayout as Layout } from 'layouts/Dashboard';
import * as S from './styles';
import React, { useEffect, useState } from 'react';
import { Button } from 'components/Button';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { DragIcon } from 'components/Icons/Drag';
import { PenIcon } from 'components/Icons/Pen';
import { Input } from 'components/Input';
import { useParams } from 'react-router-dom';
import { CoursesService, ModulesService } from '../../services';
import { toast } from 'react-toastify';
import { IModule } from 'types';
import Switch from 'components/Switch';
import { LinkButton } from 'components/LinkButton';
import { Modal } from 'components/Modal';

const DragHandle = SortableHandle(() => (
  <div className="text-secondary">
    <DragIcon />
  </div>
));

const SortableItem = SortableElement(
  ({ id, value, published, itemIndex, onNameChanged, onSwitch, onDelete }) => {
    const [modeEdit, setModeEdit] = useState<boolean>(false);
    const [currentValue, setCurrentValue] = useState(value);

    return (
      <S.ModuleRow>
        <DragHandle />
        <div className="flex flex-grow items-center">
          <PenIcon
            style={{ cursor: 'pointer' }}
            onClick={() => setModeEdit(!modeEdit)}
          />
          {modeEdit ? (
            <div className="mr-4 flex items-center px-4">
              <div className="mr-2">
                <Input
                  type="text"
                  value={currentValue}
                  onChange={(e) => {
                    setCurrentValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div>
                <Button
                  variant="secondary"
                  onClick={() => {
                    onNameChanged({
                      changedIndex: itemIndex,
                      newName: currentValue,
                    });
                    setModeEdit(false);
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          ) : (
            <div className="mr-4">
              <S.ModuleName className="ml-2 mr-4 text-secondary">
                {value}
              </S.ModuleName>
            </div>
          )}
        </div>
        <LinkButton to={`modules/${id}?module=${value}`} className="mr-6">
          Go to module
        </LinkButton>
        <button className="btn-red w-14 mr-2" onClick={() => onDelete(id)}>
          Delete
        </button>
        <Switch
          value={published}
          setValue={() => {
            onSwitch(id);
          }}
        />
        <p className="ml-4 text-primary w-20">
          {published ? 'Published' : 'Locked'}
        </p>
      </S.ModuleRow>
    );
  },
);

const SortableList = SortableContainer(
  ({
    items = [],
    onNameChanged,
    onSwitch,
    onDelete,
  }: {
    items: IModule[];
    onNameChanged: any;
    onSwitch: any;
    onDelete: any;
  }) => {
    return (
      <S.ModulesContainer>
        {items
          .sort((a, b) => {
            if (a.order && b.order) {
              return a.order - b.order;
            }
            return 0;
          })
          .map((value, index) => {
            return (
              <SortableItem
                key={index}
                id={value._id}
                published={value?.published}
                index={index}
                itemIndex={index}
                value={value?.name}
                onNameChanged={onNameChanged}
                onSwitch={onSwitch}
                onDelete={onDelete}
              />
            );
          })}
      </S.ModulesContainer>
    );
  },
);

export default function ModulesList() {
  const [modulesList, setModulesList] = useState<IModule[]>([]);
  const [createModuleMode, setCreateModuleMode] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [moduleToDelete, setModuleToDelete] = useState<string | null>('');
  const [newModule, setNewModule] = useState<IModule | undefined>();
  const [lastListHelper, setLastListHelper] = useState<IModule[]>();
  const { courseId } = useParams();

  const queryParams = new URLSearchParams(`${window.location.search}`);
  const course = queryParams.get('course');

  const handleGetModules = async () => {
    if (courseId) {
      const newModules = await CoursesService.getCourseModules(courseId);
      newModules?.data && setModulesList(newModules?.data);
    } else {
      toast.error('There is no Course ID on the URL');
    }
  };

  const handleCreateModule = async () => {
    if (newModule) {
      await ModulesService.createModule(newModule);
      setCreateModuleMode(false);
      setNewModule(undefined);
      handleGetModules();
    } else {
      toast.error('Unable to Create Module');
    }
  };

  useEffect(() => {
    handleGetModules();
  }, []);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (modulesList) {
      setLastListHelper(modulesList);
      const modulesListReodered = arrayMoveImmutable(
        modulesList,
        oldIndex,
        newIndex,
      ).map((item, index) => {
        ModulesService.editModule(item._id || '', {
          order: index + 1,
        }).catch((err) => {
          toast.error(`Error trying to reorder modules: ${err.data.message}`);
          modulesList[index].published = !modulesList[index]?.published;
          setModulesList([...modulesList]);
        });
        return { ...item, order: index + 1 };
      });
      toast.success(`Order updated`);
      setModulesList(modulesListReodered);
    }
  };

  const onNameChanged = ({ changedIndex, newName }) => {
    setModulesList(
      modulesList?.map((module, index) => {
        if (index === changedIndex) {
          module.name = newName;
          ModulesService.editModule(module._id || '', {
            name: newName,
          })
            .then((res) => toast.success(`Module updated`))
            .catch((err) => {
              toast.error(`Unable to change module: ${err.data.message}`);
              modulesList[index].published = !modulesList[index]?.published;
              setModulesList([...modulesList]);
            });
        }
        return module;
      }),
    );
  };

  const handleSwitch = (id) => {
    const index = modulesList?.findIndex((module) => module._id === id);
    modulesList[index].published = !modulesList[index]?.published;
    setModulesList([...modulesList]);
    ModulesService.editModule(id, {
      published: modulesList[index]?.published,
    })
      .then((res) => toast.success(`Module updated`))
      .catch((err) => {
        toast.error(`Unable to change module: ${err.data.message}`);
        modulesList[index].published = !modulesList[index]?.published;
        setModulesList([...modulesList]);
      });
  };

  const handleDelete = async (id: string) => {
    await toast
      .promise(ModulesService.deleteModule(id), {
        pending: 'Checking...',
        success: 'Success',
        error: {
          render: ({ data }: any) => {
            setModulesList(lastListHelper || modulesList);
            return data.error?.message || 'Error';
          },
        },
      })
      .then((response) => {
        const newModulesList = modulesList.filter(
          (module) => module._id !== id,
        );
        setModulesList(newModulesList);
      });
  };

  return (
    <Layout
      title={
        <>
          <div>{course}</div>
          <Button variant="green" className="hidden text-base">
            View as a student
          </Button>
        </>
      }
    >
      <div className="col-span-2">
        {modulesList && (
          <SortableList
            items={modulesList}
            onSortEnd={onSortEnd}
            onNameChanged={onNameChanged}
            onSwitch={handleSwitch}
            onDelete={(id) => {
              setModuleToDelete(id);
              setIsDeleteModalOpen(true);
            }}
            useDragHandle
          />
        )}
        {createModuleMode && courseId && (
          <S.Row className="mb-4">
            <Input
              type="text"
              onChange={(e) =>
                setNewModule({
                  name: e.currentTarget.value,
                  courseId: courseId,
                  order: (modulesList?.length || 0) + 1,
                })
              }
            />
            <div>
              <Button
                variant="secondary"
                className="ml-4"
                onClick={() => handleCreateModule()}
              >
                Save
              </Button>
            </div>
          </S.Row>
        )}
        <S.ModulesOptions>
          <Button variant="secondary" onClick={() => setCreateModuleMode(true)}>
            Create Module
          </Button>
        </S.ModulesOptions>
      </div>
      <Modal
        visible={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        style="bg-yellow text-white text-center"
        width="1/2"
      >
        <h1 className="text-xl">
          Are you sure you want to delete this Module?
        </h1>
        <div className="flex justify-around w-full mt-3">
          <Button
            variant="white"
            onClick={() => {
              setIsDeleteModalOpen(false);
              setModuleToDelete(null);
            }}
          >
            No, go back.
          </Button>
          <Button
            variant="red"
            onClick={() => {
              moduleToDelete && handleDelete(moduleToDelete);
              setIsDeleteModalOpen(false);
              setModuleToDelete(null);
            }}
          >
            Yes, delete.
          </Button>
        </div>
      </Modal>
    </Layout>
  );
}
