import React, { useEffect, useState } from 'react';

import { UserImage } from 'components/UserImage';
import { Button } from 'components/Button';
import { PenIcon } from 'components/Icons/Pen';
import { TextArea } from 'components/TextArea';
import { Editable } from 'components/Editable';
import { ChangePasswordModal } from 'components/ChangePassword';
import { DashboardLayout } from 'layouts/Dashboard';
import { userStateRecoil } from 'contexts/atoms';
import { useRecoilState } from 'recoil';
import { toast } from 'react-toastify';
import { UsersService } from 'services';
import { AccountTypeEnum, Role } from 'contexts/types';
import { Loader } from 'components/Loader';

export const AccountSettings = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSentMessage, setIsSentMessage] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [newPicture, setNewPicture] = useState('');
  const [open, setOpen] = React.useState(false);
  const [userState] = useRecoilState(userStateRecoil);
  const [user, setUser] = useState<any>();

  const getBase64 = (file: File) => {
    return new Promise((resolve) => {
      let baseURL: string | ArrayBuffer | null;
      // Make new FileReader
      const reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  // function to enter new profile picture into the form
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const picture = event?.target?.files && event?.target?.files[0];

    const img64 = (picture && ((await getBase64(picture)) as string)) || '';
    const index = img64.indexOf(',');
    const image = img64?.substring(index);
    if (img64) {
      toast
        .promise(UsersService.changeProfilePicture(userState.userId, image), {
          pending: 'Checking...',
          success: 'Profile changed successfully!',
          error: 'Something went wrong. Try again.',
        })
        .then((res) => {
          setNewPicture(res?.data.data.url);
        })
        .catch((err) => {
          toast.error(err.error);
        });
    }
  };

  const handleGetUserInfo = async () => {
    setIsLoading(true);
    const userInfo = await UsersService.getUser(userState.userId);
    setUser({
      role: userInfo?.data.roles[0],
      ...userInfo?.data,
    });
    if (userInfo?.data) {
      setIsLoading(false);
    }
  };

  const handleSupportEmail = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    const data = new FormData(event.target as HTMLFormElement);
    const formObject = Object.fromEntries(data.entries());
    toast
      .promise(
        UsersService.sendSupportEmail(
          userState.email,
          formObject.title as string,
          formObject.text as string,
        ),
        {
          pending: 'Sending...',
          success: 'Message sent successfully!',
          error: {
            render({ data }: any) {
              return data.data?.error || 'Something went wrong!';
            },
          },
        },
      )
      .then(() => {
        setIsSentMessage(true);
      });
  };

  const handleChangeEmail = async (email: string) => {
    if (email !== user.email) {
      toast
        .promise(UsersService.changeEmail(user?.id || '', newEmail), {
          pending: 'Checking...',
          success: 'Email changed successfully!',
          error: {
            render({ data }: any) {
              setNewEmail('');
              return data.data?.error || 'Something went wrong!';
            },
          },
        })
        .then((res) => {
          setUser((prevUser) => {
            return { ...prevUser, email: newEmail };
          });
        });
    } else {
      toast.error('You are already using this email');
    }
  };

  useEffect(() => {
    handleGetUserInfo();
  }, []);

  return (
    <DashboardLayout title="Account and Settings">
      {isLoading ? (
        <div className="col-span-3 h-full">
          <Loader />
        </div>
      ) : (
        <>
          <div className="col-span-3 lg:col-span-2">
            <div className="space-y-3">
              <div className="flex space-x-3">
                <h3 className="text-secondary">Name: </h3>
                <h3 className="text-primary">{`${user?.name} ${user?.surname}`}</h3>
              </div>
              <div className="flex space-x-3">
                <h3 className="text-secondary">Account Type: </h3>
                <h3 className="text-primary">
                  {Role[user?.role as keyof typeof AccountTypeEnum]}
                </h3>
              </div>
              <div className="flex space-x-3">
                <h3 className="text-secondary">School:</h3>
                <h3 className="text-primary">{user?.school?.name}</h3>
              </div>
              <div className="flex space-x-3">
                <h3 className="text-secondary">Email:</h3>
                <Editable
                  isEditing={isEditing}
                  onClose={() => setIsEditing(false)}
                  defaultValue={user?.email || ''}
                  value={newEmail ? newEmail : user?.email}
                  getValue={(value) => {
                    handleChangeEmail(value);
                  }}
                  setNewValue={setNewEmail}
                />
                <div
                  className={`flex text-secondary cursor-pointer ${
                    isEditing && 'hidden'
                  }`}
                  onClick={() => setIsEditing(true)}
                >
                  <PenIcon /> <span>Edit</span>
                </div>
              </div>
              <div
                className="flex text-secondary cursor-pointer w-max"
                onClick={(event_) => {
                  setOpen(true);
                }}
              >
                <PenIcon /> <span>Change Password</span>
              </div>
              <ChangePasswordModal
                visible={open}
                onClose={() => setOpen(false)}
              />
            </div>
          </div>
          <div className="col-span-3 lg:col-span-1 flex justify-center lg:justify-end order-first lg:order-none">
            <div className="flex flex-col items-center space-y-4">
              <UserImage
                size="lg"
                srcImg={newPicture || user?.profilePhotoUrl || ''}
              />
              <div className="relative">
                <Button variant="green">
                  <input
                    type="file"
                    className="absolute inset-0 opacity-0 w-full"
                    onChange={handleFileChange}
                  />
                  Change Profile Picture
                </Button>
              </div>
            </div>
          </div>
          <div className="col-span-3 mt-8 flex flex-col space-y-2">
            <h1 className="text-secondary text-xl">Contact Us</h1>
            {isSentMessage ? (
              <p className="text-primary">
                We received your messsage! <br /> You can expect a response in
                your email from support@xyloacademy.com within 48hrs.
              </p>
            ) : (
              <>
                <form onSubmit={handleSupportEmail} className="space-y-2">
                  <input
                    name="title"
                    type="text"
                    className="rounded-full w-52 px-3 border border-secondary focus:border-transparent focus:ring-2 focus:ring-secondary text-secondary placeholder-secondary focus:outline-none text-blue"
                    placeholder="Title"
                    required
                  />
                  <div className="lg:flex space-x-3">
                    <TextArea
                      name="text"
                      className="lg:w-3/5"
                      placeholder="Your Message"
                      required
                    />
                    <div className="relative w-full">
                      <div className="w-full">
                        <p className="text-primary mb-3">
                          You can ask for help with any technical issues here.
                          <br />
                          <br />
                          For course content related questions, please post to
                          the respective Q&A section within the modules.
                        </p>
                        <Button variant="secondary">Send</Button>
                      </div>
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
        </>
      )}
    </DashboardLayout>
  );
};
