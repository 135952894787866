import styled from 'styled-components';

export const Styles = styled.div`
  table {
    th,
    td {
      :first-child {
        min-width: 150px;
        color: #2b4e8c;
        padding: 12px;
      }
      div {
        &.sm-col {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 55px;
        }
      }
    }
  }
`;
