import React from 'react';

export const TextArea: React.FC<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>
> = (props): JSX.Element => {
  return (
    <div className={props.className}>
      <textarea
        {...props}
        className="border border-secondary resize-y rounded-xl px-3 py-3 focus:border-transparent focus:ring-2 focus:ring-secondary focus:outline-none text-secondary placeholder-secondary w-full h-full"
      />
    </div>
  );
};
