export enum AccountTypeEnum {
  XYLO_ADMIN = 'XYLO_ADMIN',
  SCHOOL_ADMIN = 'SCHOOL_ADMIN',
  STUDENT = 'STUDENT',
}

export enum Role {
  XYLO_ADMIN = 'XYLO Admin',
  SCHOOL_ADMIN = 'School Admin',
  STUDENT = 'Student',
}

export enum ContentTypeEnum {
  'TEXT/IMAGE' = 'Content',
  VIDEO = 'Video',
  PODCAST = 'Podcast',
  QUIZ = 'Quiz',
}

export enum ContentTypeReverse {
  Content = 'TEXT/IMAGE',
  Video = 'VIDEO',
  Podcast = 'PODCAST',
  Quiz = 'QUIZ',
}

export interface UserType {
  role: AccountTypeEnum | undefined;
  name: string;
  userId: string;
  surname: string;
  email: string;
  token: string;
  refreshToken: string;
  profilePhotoUrl?: string;
  school?: any;
  progress?: any;
}

export interface Question {
  id?: string;
  question: string;
  options: Array<string>;
  correctAnswer: string;
  explanation: string;
  timestamps: string;
  index?: number;
  _id: string;
}

export interface Url {
  urlMedia: string;
  urlScript: string;
}

export interface Content {
  id: string;
  _id: string;
  title: string;
  type: string;
  questions?: Question[];
  url?: Url;
  moduleId: string;
  order: number;
  timeLimit?: number;
  data?: string;
  text?: string;
  published?: boolean;
}

export interface UserAnswers {
  answer: string;
  questionId: string;
}

export interface ContentProgress {
  id: string;
  score: number;
  userAnswers: Array<UserAnswers>;
  attempts: number;
  seen: boolean;
  progressContent: number;
  userId: string;
  contentId: string;
  timestamps: number;
  nextQuestion: string;
  remainingTime: number;
}

// For recoil state
export interface Answer {
  questionId: string;
  answer: string;
}

// For recoil state
export interface AnswerByContent {
  contentId: string;
  answers: Answer[];
}