import React from 'react';

import * as S from './styles';

const textComponents = {
  title: (props: React.HTMLAttributes<HTMLHeadingElement>) => (
    <S.title {...props}>{props.children}</S.title>
  ),
  subtitle: (props: React.HTMLAttributes<HTMLHeadingElement>) => (
    <S.subtitle {...props}>{props.children}</S.subtitle>
  ),
  paragraph: (props: React.HTMLAttributes<HTMLParagraphElement>) => (
    <S.paragraph {...props}>{props.children}</S.paragraph>
  ),
};

interface TextProps
  extends React.ButtonHTMLAttributes<
    HTMLHeadingElement | HTMLParagraphElement
  > {
  variant: 'title' | 'subtitle' | 'paragraph';
  color?: string;
  children: React.ReactNode;
  resize?: string;
}

export const Text = ({ variant, color, ...props }: TextProps) => {
  const SpecificText = textComponents[variant];
  props.className += ` text-${color}`;
  return <SpecificText {...props}>{props.children}</SpecificText>;
};
