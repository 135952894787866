import { Menu } from 'components/Menu';
import React from 'react';
import * as S from '../Basic/styles';

interface IProps {
  children: React.ReactNode;
}

export const AddUsersLayout = ({ children }: IProps): JSX.Element => {
  return (
    <S.Container className="overflow-y-scroll no-scrollbar bg-pink-200">
      <div className="absolute right-8 top-8 z-10">
        <Menu
          list={[
            { text: 'Dashboard', link: '/' },
            { text: 'XYLO Home', link: 'https://xyloacademy.com/', externalPage: true },
            { text: 'Logout', link: '/logout' },
          ]}
        />
      </div>
      <div className="w-full h-full flex items-center">{children}</div>
    </S.Container>
  );
};
