import React from 'react';
import { XIcon } from '@heroicons/react/solid';

const MODAL_CONTAINER =
  'flex fixed bg-modal w-screen h-screen z-50 items-center justify-center left-0 top-0 right-0 bottom-0';

const INNER_CONTENT =
  'flex items-center justify-center bg-gray-900 bg-opacity-50 z-50 w-full h-full';

const MODAL_CONTENT =
  'flex flex-col h-auto p-6 bg-white border-sm relative rounded-3xl shadow-lg overflow-hidden';

const TITLE_CONTAINER = 'flex w-full items-center select-none justify-between';

const ICON =
  'flex w-10 h-10 items-center justify-center bg-transparent cursor-pointer rounded-full focus:outline-none hover:bg-gray-200';

interface ModalProperties {
  visible: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  width?: string;
  style?: string;
  hiddeCancelButton?: boolean;
}

export const Modal: React.FC<ModalProperties> = ({
  visible,
  onClose,
  title,
  children,
  width,
  style,
  hiddeCancelButton,
}: ModalProperties) => {
  if (!visible) return <></>;

  return (
    <div className={MODAL_CONTAINER}>
      <div className={INNER_CONTENT}>
        <div className={`${MODAL_CONTENT} w-${width} ${style}`}>
          {/* This element is just to maintain w-9/12 style when we apply purge on taliwndcss config */}
          <div className="hidden w-9/12" />
          <div className={`${TITLE_CONTAINER} ${title ? 'mb-4' : ''}`}>
            <div className="flex flex-row items-center w-auto">
              <h2 className="text-gray-500 text-left text-xl font-bold">
                {title}
              </h2>
            </div>
            <div className="flex flex-1" />
            {!hiddeCancelButton && (
              <button
                className={ICON}
                onClick={(event) => {
                  event.preventDefault();
                  onClose();
                }}
              >
                <XIcon className="h-5 w-5 text-gray-700" />
              </button>
            )}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
