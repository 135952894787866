export enum ContentType {
  CONTENT = 'TEXT/IMAGE',
  VIDEO = 'VIDEO',
  PODCAST = 'PODCAST',
  QUIZ = 'QUIZ',
}

export enum VideoTypesEnum {
  MP4 = 'video/mp4',
  MOV = 'video/quicktime',
  OGV = 'video/ogg',
}

export interface ItemType {
  id: string;
  title: string;
  type: string;
  order: number;
}

export interface SimpleFileUpload {
  presignedUrl: string;
  file: File | null;
  type: string;
}
