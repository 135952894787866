import React from 'react';
import { Button } from 'components/Button';

interface EditableProps {
  defaultValue: string;
  getValue: (value: string) => void;
  setNewValue: (value: string) => void;
  value: string;
  isEditing: boolean;
  onClose: () => void;
}

export const Editable = ({
  isEditing,
  defaultValue,
  getValue,
  setNewValue,
  value,
  onClose,
}: EditableProps) => {
  return (
    <>
      {isEditing ? (
        <>
          <div className="flex w-full">
            <input
              className="text-blue placeholder-blue rounded-xl px-1 focus:outline-none w-full lg:w-3/5"
              defaultValue={defaultValue}
              onChange={(e) => {
                setNewValue(e.target.value);
              }}
            />
            <div className={`${isEditing ? 'inline' : 'hidden'} ml-2`}>
              <Button
                variant="secondary"
                onClick={() => {
                  getValue(value);
                  onClose();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </>
      ) : (
        <span className="text-primary">{defaultValue}</span>
      )}
    </>
  );
};
