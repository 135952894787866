import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Router from './routes';
import * as S from 'layouts/Basic/styles';
import logo from 'assets/images/svg/logo-2.svg';

export default function App() {
  return (
    <div className="App bg-pink-200">
      <S.Logo src={logo} className="z-50 transform scale-75 lg:scale-90" />
      <S.Curve className={`absolute fix top-0 left-0 w-full lg:w-11/12 z-40`} />
      <Router />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
