import { useEffect, useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import { Button } from 'components/Button';
import { IconButton } from 'components/IconButton';
import { DashboardLayout as Layout } from 'layouts/Dashboard';
import { PlusIcon } from '@heroicons/react/outline';
import { Text } from 'components/Text';
import { ListItem } from './components/ListItem';
import { toast } from 'react-toastify';
import { ModalAddContent } from './components/ModalAddContent';
import { useRecoilState } from 'recoil';
import { contentListState } from 'contexts/atoms';
import { Content } from 'contexts/types';
import { ContentService } from 'services';
import { useNavigate, useParams } from 'react-router-dom';
import { replaceItemAtIndex } from 'utils/utils';
import { Modal } from 'components/Modal';

const SortableItem = SortableElement(
  ({
    item,
    onEdit,
    onSwitch,
    onDelete,
  }: {
    item: Content;
    onEdit: (id: string) => void;
    onSwitch: (id: string) => void;
    onDelete: (id: string) => void;
  }) => (
    <>
      <ListItem
        id={item.id || ''}
        title={item.title}
        contentType={item.type}
        published={!!item.published}
        onEdit={onEdit}
        onSwitch={onSwitch}
        onDelete={onDelete}
      />
    </>
  ),
);

const SortableList = SortableContainer(
  ({
    items,
    onEdit,
    onSwitch,
    onDelete,
  }: {
    items: Content[];
    onEdit: (id: string) => void;
    onSwitch: (id: string) => void;
    onDelete: (id: string) => void;
  }) => {
    return (
      <ul>
        {items.map((value, index) => (
          <>
            <SortableItem
              key={`item-${index}`}
              index={index}
              item={value}
              onEdit={onEdit}
              onSwitch={onSwitch}
              onDelete={onDelete}
            />
          </>
        ))}
      </ul>
    );
  },
);

export const ModuleContent = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [contentToDelete, setContentToDelete] = useState<string | null>('');
  const [contentList, setContentList] = useRecoilState(contentListState);
  const [lastListHelper, setLastListHelper] = useState<Content[]>();
  const [editId, setEditId] = useState('');
  const { idModule } = useParams();
  const navigate = useNavigate();

  // Get params
  const queryParams = new URLSearchParams(`${window.location.search}`);
  const module = queryParams.get('module');
  const { courseId } = useParams();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setLastListHelper(contentList);

    const contentListReodered = arrayMoveImmutable(
      contentList,
      oldIndex,
      newIndex,
    ).map((item, index) => {
      return { ...item, order: index + 1 };
    });

    setContentList(contentListReodered);
  };

  const handleSave = async () => {
    // TODO: Save to database
    const itemsToUpdate = contentList.map((item, index) => {
      return {
        id: item.id,
        order: index + 1,
        published: !!item.published,
      };
    });

    await toast.promise(
      ContentService.updateOrderAndStatusContent(itemsToUpdate),
      {
        pending: 'Checking...',
        success: 'Success',
        error: {
          render: ({ data }: any) => {
            setContentList(lastListHelper || contentList);
            return data.error?.message || 'Error';
          },
        },
      },
    );
  };

  const handleSwitch = (id: string) => {
    const index = contentList.findIndex((item) => item.id === id);

    const contentToUpdate = {
      ...contentList[index],
      published: !contentList[index].published,
    };

    const newContentList = replaceItemAtIndex(
      contentList,
      index,
      contentToUpdate,
    );

    setContentList(newContentList);
  };

  const handleDelete = async (id: string) => {
    await toast
      .promise(ContentService.deleteContent(id), {
        pending: 'Checking...',
        success: 'Success',
        error: {
          render: ({ data }: any) => {
            setContentList(lastListHelper || contentList);
            return data.error?.message || 'Error';
          },
        },
      })
      .then((response) => {
        const newContentList = contentList.filter(
          (content) => content.id !== id,
        );
        setContentList(newContentList);
      });
  };

  const getContentsByModule = async () => {
    const contentList = await ContentService.getContentList(idModule || '');
    contentList?.data &&
      setContentList([
        ...contentList.data.contents.sort((a, b) => a.order - b.order),
      ]);
  };

  useEffect(() => {
    getContentsByModule();
  }, []);

  return (
    <Layout
      title={
        <>
          <Text variant="subtitle" color="primary">
            Module:
          </Text>
          <Text variant="title" color="orange">
            {module}
          </Text>
          <Button
            variant="green"
            onClick={() =>
              navigate(
                `/student/course-outline/${
                  courseId + '/' + idModule
                }?idSchool=0&module=${module}`,
              )
            }
          >
            View as Student
          </Button>
        </>
      }
    >
      <div className="col-span-3 z-50">
        <button
          className="flex items-center space-x-3 text-primary mb-3"
          onClick={() => setModalVisible(true)}
        >
          <IconButton
            bg="primary"
            icon={<PlusIcon className="w-8 h-8 text-white" />}
          />
          <Text variant="subtitle" color="primary">
            Add content
          </Text>
        </button>

        <div className="ml-14">
          <SortableList
            items={contentList}
            onSortEnd={onSortEnd}
            onEdit={(id) => {
              setModalVisible(true);
              setEditId(id);
            }}
            onSwitch={(id) => handleSwitch(id)}
            onDelete={(id) => {
              setContentToDelete(id);
              setIsDeleteModalOpen(true);
            }}
            shouldCancelStart={(e: any) => {
              if (['svg', 'path'].includes(e.target.tagName.toLowerCase())) {
                return false;
              } else
                return (
                  e.target.tagName.toLowerCase() === 'button' ||
                  e.target.className?.includes('react-switch-handle') ||
                  e.target.className?.includes('react-switch-bg')
                );
            }}
          />
        </div>
      </div>
      <div className="flex justify-end col-span-8">
        <Button variant="green" onClick={handleSave}>
          Save Changes
        </Button>
        <ModalAddContent
          editId={editId}
          visible={modalVisible}
          onClose={() => {
            setModalVisible(false);
            setEditId('');
          }}
        />
      </div>
      <Modal
        visible={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        style="bg-yellow text-white text-center"
        width="1/2"
      >
        <h1 className="text-xl">
          Are you sure you want to delete this content?
        </h1>
        <div className="flex justify-around w-full mt-3">
          <Button
            variant="white"
            onClick={() => {
              setIsDeleteModalOpen(false);
              setContentToDelete(null);
            }}
          >
            No, go back.
          </Button>
          <Button
            variant="red"
            onClick={() => {
              contentToDelete && handleDelete(contentToDelete);
              setIsDeleteModalOpen(false);
              setContentToDelete(null);
            }}
          >
            Yes, delete.
          </Button>
        </div>
      </Modal>
    </Layout>
  );
};
