import React, { useState } from 'react';

import { Input } from 'components/Input';
import { Title } from 'components/Title/styles';
import { Button } from 'components/Button';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Basic from 'layouts/Basic';
import { AuthService } from 'services';

export const ForgotPassword = (): JSX.Element => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const sendEmail = () => {
    toast
      .promise(AuthService.forgotPassword(email), {
        pending: 'Checking...',
        success: 'Reset link sent to your email.',
        error: {
          render({ data }: any) {
            return data.data?.error || 'Something went wrong!';
          },
        },
      })
      .then((res) => {
        if (res) {
          navigate('/login');
        }
      });
  };
  return (
    <Basic>
      <div>
        <div className="flex flex-col max-w-md mx-auto">
          <Title className="mb-1">Forgot Your Password?</Title>
          <p className="relative inset-x-0 top-0 text-primary left-50">
            Enter your email address and we will send you a link to reset it!
          </p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              sendEmail();
            }}
          >
            <div className="flex mt-8 px-2 justify-center">
              <Input
                name="email"
                type="email"
                required
                placeholder="Email"
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
            </div>
            <div className="mt-8 flex justify-center">
              <Button type="submit" variant="fullRounded">
                Reset Password
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Basic>
  );
};
