import ReactWaves from '@dschoon/react-waves';
import { useEffect, useState } from 'react';
import { PlayIcon, PauseIcon } from '@heroicons/react/outline';
import { IconButton } from 'components/IconButton';
import { Text } from 'components/Text';
import { formatTime } from 'utils/date-utils';
import { ProgressService } from 'services';
import { ContentProgress } from 'contexts/types';

interface AudioPlayerProps {
  url?: string;
  id: string;
  newPosition?: number;
  contentProgress?: ContentProgress;
  moduleId: string;
  onSetModuleProgress: (progress: number) => void;
}

export const AudioPlayer = ({
  url,
  id,
  newPosition,
  contentProgress,
  moduleId,
  onSetModuleProgress,
}: AudioPlayerProps) => {
  const [wavesurfer, setWavesurfer] = useState();
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [ready, setReady] = useState(false);
  const [progress, setProgress] = useState(contentProgress);

  const seekTo = (start) => {
    if (!!wavesurfer) (wavesurfer as any).seekTo(secondsToPosition(start));
  };

  const secondsToPosition = (sec) => {
    if (!!sec) {
      return sec > duration ? 1 : (1 / duration) * sec;
    }
    return 0;
  };

  const onPosChange = async (pos, wavesurfer) => {
    setCurrentTime(wavesurfer.getCurrentTime());
    setDuration(wavesurfer.getDuration());
    if (
      formatTime(wavesurfer.getDuration()) ==
      formatTime(wavesurfer.getCurrentTime())
    ) {
      setPlaying(false);
      const response = await ProgressService.updateContentProgress(
        contentProgress?.id || '',
        {
          seen: true,
          moduleId,
          timestamps: wavesurfer.getDuration(),
          progressContent: 100,
        },
      );
      response?.data && onSetModuleProgress(response?.data.moduleProgress);
    }
  };

  const saveProgressPodcast = async () => {
    let auxIsPlaying = false;
    let auxTimestamps = 0;
    let auxProgresss;
    setCurrentTime((prevState) => {
      auxTimestamps = prevState;
      return prevState;
    });
    setPlaying((prevState) => {
      auxIsPlaying = prevState;
      return prevState;
    });
    setProgress((prevState) => {
      auxProgresss = prevState;
      return prevState;
    });

    try {
      if (auxIsPlaying && !!auxProgresss) {
        await ProgressService.updateContentProgress(auxProgresss.id || '', {
          timestamps: auxTimestamps,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    contentProgress && duration && seekTo(contentProgress?.timestamps);
  }, [duration, contentProgress, ready]);

  useEffect(() => {
    const interval = setInterval(() => {
      saveProgressPodcast();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setProgress(contentProgress);
  }, [contentProgress]);

  return (
    <>
      <div className="relative w-full bg-primary rounded-full h-16">
        <div className="absolute -top-3 z-20">
          <IconButton
            border="border-2 border-primary"
            bg="white"
            icon={
              !playing ? (
                <PlayIcon className="w-8 h-8 text-orange" />
              ) : (
                <PauseIcon className="w-8 h-8 text-orange" />
              )
            }
            onClick={() => {
              setPlaying(!playing);
            }}
          />
        </div>

        <ReactWaves
          id={`audio-${id}`}
          audioFile={url}
          className="custom-waves absolute -top-6 left-6 z-10"
          options={{
            backend: 'MediaElement',
            barGap: 1,
            barWidth: 8,
            barHeight: 2,
            cursorWidth: 0,
            height: 100,
            hideScrollbar: true,
            progressColor: '#FF8C4D',
            responsive: true,
            waveColor: '#A7BDE6',
            normalize: true,
            autoCenter: true,
          }}
          volume={1}
          zoom={2}
          playing={playing}
          pos={newPosition}
          onReady={({ wavesurfer }) => {
            setWavesurfer(wavesurfer);
            setCurrentTime(wavesurfer.getCurrentTime());
            setDuration(wavesurfer.getDuration());
            setReady(true);
          }}
          onPosChange={onPosChange}
        />
        <div className="flex relative top-8 justify-between px-12">
          <Text variant="paragraph" color="white">
            {formatTime(currentTime)}
          </Text>
          <Text variant="paragraph" color="white">
            {formatTime(duration)}
          </Text>
        </div>
      </div>
    </>
  );
};
