import styled from 'styled-components';

export const SeparationLine = styled.div`
  height: 3px;
  border-width: 0;
`;

export const EditorContainer = styled.div`
  margin-bottom: 20px !important;
`;
