import { useEffect, useState } from 'react';
import { Button } from 'components/Button';
import { formatToHHMMSS } from 'utils/date-utils';
import { buildFileKey, getEnumValues } from 'utils/utils';
import { VideoTypesEnum } from '../types';
import { TimestampedQuestionList } from '../components/TimestampedQuestionList';
import * as S from './styles';
import { ContentService } from 'services';
import { contentListState } from 'contexts/atoms';
import { useRecoilState } from 'recoil';
import { Question } from 'contexts/types';

interface VideoFormProps {
  contentId?: string;
  onChangeUploadMedia: (presignedUrl: string, file: File) => void;
  onChangeUploadScript: (presignedUrl: string, file: File) => void;
  onSetQuestions: (questions: Question[]) => void;
}

export const VideoForm = ({
  contentId,
  onChangeUploadMedia,
  onChangeUploadScript,
  onSetQuestions,
}: VideoFormProps) => {
  const [contentList] = useRecoilState(contentListState);
  const [infoVideo, setInfoVideo] = useState('');
  const [infoCaptions, setInfoCaptions] = useState('');
  const currentContent: any =
    contentId && contentList.filter((item) => item.id === contentId)[0];

  const acceptedVideoTypes: string[] = getEnumValues(VideoTypesEnum);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const file = event?.target?.files?.[0];

    if (file) {
      if (file.type && checkVideoType(file.type, acceptedVideoTypes)) {
        const fileKey = buildFileKey('videos', file.name, 'mp4');
        const response = await ContentService.getPresignedUrl(
          fileKey,
          'video/mp4;audio/mpeg',
        );
        const reader = new FileReader();
        reader.onload = function (e) {
          // create a blob of buffer
          const blob = new Blob([e.target?.result as ArrayBuffer], {
              type: file.type,
            }),
            url = (URL || webkitURL).createObjectURL(blob), // create o-URL of blob
            video = document.createElement('video'); // create video element
          video.preload = 'metadata';
          video.addEventListener('loadedmetadata', function () {
            // when enough data loads
            const duration = formatToHHMMSS(video?.duration);
            setInfoVideo(
              `${file.name} uploaded. Total Video Length: ${duration}`,
            );
            (URL || webkitURL).revokeObjectURL(url); // clean up
          });
          video.src = url; // start video load
        };
        reader.readAsArrayBuffer(file);

        // To upload content mp4 file to s3
        onChangeUploadMedia(response?.data, file);
      } else {
        const fileKey = buildFileKey('videos', file.name, 'srt');
        const response = await ContentService.getPresignedUrl(
          fileKey,
          file.type || 'text/plain',
        );
        setInfoCaptions(`${file.name} uploaded.`);
        onChangeUploadScript(response?.data, file);
      }
    }
  };

  useEffect(() => {
    if (currentContent) {
      setInfoVideo(
        `${currentContent.url?.urlMedia.substring(
          currentContent.url?.urlMedia.indexOf('_') + 1,
        )}`,
      );
      setInfoCaptions(
        `${currentContent.url?.urlScript.substring(
          currentContent.url?.urlScript.indexOf('_') + 1,
        )}`,
      );
    }
  }, []);

  return (
    <>
      <div className="flex items-center space-x-2 grid grid-cols-12">
        <span className="text-right col-span-3">Upload Content File:</span>
        <div className="col-span-9 space-x-2 text-green">
          <Button variant="outlined">
            <input
              type="file"
              name="video"
              accept=".mp4, .mov, .ogv"
              className="absolute inset-0 opacity-0 w-full"
              onChange={handleFileChange}
            />
            Browse
          </Button>
          <span>{infoVideo}</span>
        </div>
      </div>
      <div className="flex items-center space-x-2 grid grid-cols-12">
        <span className="text-right col-span-3">Upload Captions (.srt):</span>
        <div className="col-span-9 space-x-2 text-green">
          <Button variant="outlined">
            <input
              type="file"
              name="captions"
              accept=".srt"
              className="absolute inset-0 opacity-0 w-full"
              onChange={handleFileChange}
            />
            Browse
          </Button>
          <span>{infoCaptions}</span>
        </div>
      </div>
      <S.SeparationLine className="bg-primary" />
      <TimestampedQuestionList
        onSetQuestions={onSetQuestions}
        contentId={contentId}
      />
    </>
  );
};

// HELPERS
const checkVideoType = (type: string, videoTypes: string[]) => {
  return videoTypes.some((element) => element.includes(type));
};
