import { useState } from 'react';
import {
  MenuIcon,
  PlayIcon,
  PencilIcon,
  SpeakerphoneIcon,
} from '@heroicons/react/solid';
import { IconButton } from 'components/IconButton';
import { Text } from 'components/Text';
import { Content } from 'contexts/types';
import './styles.css';

const icons = {
  podcast: () => <SpeakerphoneIcon className="h-5 w-5" />,
  video: () => <PlayIcon className="h-5 w-5" />,
  quiz: () => <PencilIcon className="h-5 w-5" />,
  'text/image': () => <MenuIcon className="h-5 w-5" />,
};

interface SidebarProps {
  isHover?: boolean;
  onSelect?: (id: string) => void;
  moduleContent: Content[];
  contentsCompleted: string[];
}

const STYLE_CONTAINER =
  'group min-w-sidebar hover:w-96 h-screen z-30 absolute pt-44 px-6 pb-4 flex flex-col sidebar-gradient overflow-y-auto no-scrollbar transition-all duration-75 ease-out';

export const Sidebar = ({
  isHover,
  onSelect,
  moduleContent,
  contentsCompleted,
}: SidebarProps) => {
  const [contentSelected, setContentSelected] = useState(
    moduleContent?.[0]?.title,
  );

  const sectionContainer = document.getElementById('sectionContainer');

  return (
    <div className={STYLE_CONTAINER}>
      <div className="max-h-head flex items-center space-x-3 text-primary my-3">
        <IconButton
          bg="primary"
          icon={<MenuIcon className="min-w-icon w-8 h-8 text-white" />}
        />
        <Text
          variant="subtitle"
          color="primary"
          className="hidden group-hover:block transition-all"
        >
          {contentSelected}
        </Text>
      </div>
      <div className="with-before-element relative">
        {moduleContent.map((content, index) => {
          const isCompleted = contentsCompleted.includes(content.id);
          const SpecificIcon =
            icons[`${content.type.toLowerCase() as keyof typeof icons}`];
          return (
            <a
              key={index}
              href={`#${content.id}`}
              className="inline-block flex space-x-2 items-center first:mt-0 mt-2 text-primary hover:text-primary-dark"
              onClick={() => {
                sectionContainer?.classList.remove('mt-40');
                setContentSelected(content.title);
                setTimeout(() => {
                  sectionContainer?.classList.add('mt-40');
                }, 3);
              }}
            >
              <div
                className={`min-w-icon w-8 h-8 ml-2 rounded-full ${
                  isCompleted
                    ? 'bg-primary text-white'
                    : 'bg-white text-primary'
                } flex justify-center items-center relative`}
              >
                <SpecificIcon />
              </div>
              <div
                className={`hidden group-hover:block font-normal hover:font-bold`}
              >
                <Text variant="paragraph">{content.title}</Text>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};
