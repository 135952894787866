import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ModulesOutline as Layout } from 'layouts/ModulesOutline';
import { Button } from 'components/Button';
import CircularProgressBar from 'components/CircularProgressBar';

import * as S from './styles';
import { CourseProgress, IModule } from 'types';
import { CoursesService, ProgressService } from 'services';
import { userStateRecoil } from 'contexts/atoms';
import { useRecoilState } from 'recoil';
import { AccountTypeEnum } from 'contexts/types';

const colors = [
  'bg-green',
  'bg-primary',
  'bg-blue-100',
  'bg-yellow-400',
  'bg-orange',
  'bg-secondary',
];

export default function CourseOutline() {
  const [modulesList, setModulesList] = useState<IModule[]>([]);
  const [currentSelectedModule, setCurrentSelectedModule] = useState<{
    id: string;
    order: number;
  }>();
  const [progress, setProgress] = useState<CourseProgress>();
  const [userState] = useRecoilState(userStateRecoil);
  const license = JSON.parse(window.localStorage.getItem('license') || '');

  const [availableModules, setAvailableModules] = useState(
    license?.licenseCode?.split(''),
  );

  const navigate = useNavigate();
  const { courseId } = useParams();
  const queryParams = new URLSearchParams(`${window.location.search}`);
  const idSchool = queryParams.get('idSchool');

  const getCurrentModule = (id: string) => {
    const module = modulesList.filter((module) => module._id === id)[0];
    const moduleProgress = progress?.modulesProgresses?.filter(
      (progress) => progress.moduleId == id,
    )[0];

    return { module, moduleProgress };
  };

  const handleGetModules = async () => {
    if (courseId) {
      const newModules = await CoursesService.getCourseModules(courseId);
      newModules?.data && setModulesList(newModules?.data);
      if (userState.role == 'STUDENT') {
        const courseProgress = await ProgressService.getCourseProgress(
          courseId,
          userState.userId,
        );
        setProgress(courseProgress?.data);
      }
    } else {
      toast.error('There is no Course ID on the URL');
    }

    if (license.isLicenseExpired) {
      toast.warning(
        `Your license has expired. You can't access to the course content.`,
      );
    }
  };

  useEffect(() => {
    handleGetModules();
    setAvailableModules((prev) => {
      return license?.licenseCode?.split('');
    });
  }, []);

  return (
    <Layout>
      <div className="relative flex justify-center lg:mr-32 w-max md:w-auto">
        <div className="relative">
          <S.CourseContainer className="md:col-start-3md:col-span-5 lg:mx-auto transform scale-75 md:scale-100">
            {modulesList.map((module, index) => {
              const val =
                (360 * ((module.order as number) - 1 || 0)) /
                modulesList.length;

              return (
                <S.ModuleCircle
                  id={module._id}
                  onClick={() =>
                    setCurrentSelectedModule({
                      id: module._id as string,
                      order: module.order as number,
                    })
                  }
                  key={index}
                  style={{
                    transform:
                      module.order === 1
                        ? ' translateY(-275px)'
                        : `rotate(${val}deg) translateY(-275px) rotate(${
                            360 - val
                          }deg)`,
                  }}
                  className={`${module.order} ${colors[index]} ${
                    !availableModules?.includes(`${module.order}`) &&
                    availableModules[0] !== '0' &&
                    'filter grayscale opacity-75'
                  }`}
                >
                  <span>
                    {module.order === 1
                      ? ``
                      : `Module ${Number(module.order) - 1}`}
                  </span>
                  <h2>{module.name}</h2>
                </S.ModuleCircle>
              );
            })}
          </S.CourseContainer>
        </div>
        <div className="absolute top-0 -right-32  md:right-0">
          <S.StatusContainer>
            {currentSelectedModule && (
              <div
                className={`flex items-center ${
                  !getCurrentModule(currentSelectedModule.id).module
                    .published && 'opacity-50'
                }`}
              >
                {userState.role !== AccountTypeEnum.SCHOOL_ADMIN && (
                  <CircularProgressBar
                    progress={
                      getCurrentModule(currentSelectedModule.id).moduleProgress
                        ?.progress || 0
                    }
                    showProgress={false}
                  />
                )}
                <div className="flex flex-col text-left text-orange ml-4 w-28">
                  <h1 className="text-orange text-xl">
                    {getCurrentModule(currentSelectedModule.id).module.name}
                  </h1>
                  {userState.role !== AccountTypeEnum.SCHOOL_ADMIN && (
                    <div className="flex items-end text-primary mb-4">
                      <p>Module Progress: </p>
                      <span className="ml-2.5 text-2xl">{`${
                        Math.round(
                          getCurrentModule(currentSelectedModule.id)
                            .moduleProgress?.progress || 0,
                        ) || 0
                      }%`}</span>
                    </div>
                  )}
                  <Button
                    variant="fullRounded"
                    disabled={
                      !getCurrentModule(currentSelectedModule.id).module
                        .published ||
                      license.isLicenseExpired ||
                      (!availableModules?.includes(
                        `${
                          getCurrentModule(currentSelectedModule.id).module
                            .order
                        }`,
                      ) &&
                        availableModules[0] !== '0')
                    }
                    onClick={() =>
                      navigate(
                        `/student/course-outline/${
                          courseId +
                          '/' +
                          getCurrentModule(currentSelectedModule.id).module._id
                        }?idSchool=${idSchool}&module=${
                          getCurrentModule(currentSelectedModule.id).module.name
                        }`,
                      )
                    }
                  >
                    {getCurrentModule(currentSelectedModule.id).module
                      .published && !license.isLicenseExpired
                      ? 'Resume'
                      : 'Blocked'}
                  </Button>
                </div>
              </div>
            )}
          </S.StatusContainer>
        </div>
      </div>
    </Layout>
  );
}
