import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { userStateRecoil, authStateRecoil } from '../../contexts/atoms';
import { useNavigate } from 'react-router';

export const Logout = () => {
  const navigate = useNavigate();

  const [userState, setUserState] = useRecoilState(userStateRecoil);
  const [authState, setAuthState] = useRecoilState(authStateRecoil);

  useEffect(() => {
    setUserState({
      role: undefined,
      userId: '',
      name: '',
      surname: '',
      email: '',
      token: '',
      refreshToken: '',
    });
    setAuthState(false);
    navigate('/login');
  }, []);

  window.localStorage.removeItem('loggedUser');
  window.localStorage.removeItem('SESSION_TOKEN');
  window.localStorage.removeItem('REFRESH_TOKEN');

  return null;
};
