import React from 'react';

export const useCountDown = (
  minutes: number,
): [number, number, (isActive: boolean, minutes?: number) => void] => {
  const [count, setCount] = React.useState(minutes * 60);
  const [isActive, setIsActive] = React.useState(false);

  const tick = () => {
    setCount(count - 1);
  };

  const setActiveCounter = (isActive: boolean, minutes?: number) => {
    setIsActive(isActive);
    minutes && setCount(minutes * 60);
  };

  React.useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (isActive) {
      interval = setInterval(tick, 1000);
    } else {
      if (interval) clearInterval(interval);
    }

    return () => clearInterval(interval as NodeJS.Timeout);
  }, [count, isActive]);
  const [mins, seconds] = getReturnValues(count);
  return [mins, seconds, setActiveCounter];
};

const getReturnValues = (countDown): [number, number] => {
  // calculate time left
  const minutes = Math.floor(countDown / 60);
  const seconds = countDown - minutes * 60;

  return [minutes, seconds];
};
