import styled from 'styled-components';

interface Size {
  resize?: string;
}

export const title = styled.h1<Size>`
  font-size: ${(props) => props.resize || '42px'};
  line-height: 1;
`;

export const subtitle = styled.h2<Size>`
  font-size: ${(props) => props.resize || '24px'};
`;

export const paragraph = styled.p<Size>`
  font-size: ${(props) => props.resize || '18px'};
`;
