import { CheckCircleIcon } from '@heroicons/react/outline';
import { Text } from 'components/Text';

interface EndMessageProps {
  score: string;
}

export const EndMessage = ({ score }: EndMessageProps) => {
  return (
    <div className="px-4 py-2 space-y-2 absolute inset-0 bg-pink-200 flexitems-centerjustify-center">
      <div className="relative flex justify-center items-center w-full h-full">
        <div>
          <CheckCircleIcon className="h-28 w-28" />
        </div>
        <div className="text-primary">
          <Text variant="paragraph">You finished this video!</Text>
          <Text variant="paragraph">Good job!</Text>
        </div>
        <div className="absolute bottom-12 text-primary">
          <Text variant="paragraph">{`${
            score && 'Your Question Score:' + score
          }`}</Text>
        </div>
      </div>
    </div>
  );
};
