import React, { useState } from 'react';
import ReactSwitch from 'react-switch';

interface IProps {
  value: boolean;
  setValue: any;
}

export default function Switch({ value = false, setValue }: IProps) {
  return (
    <ReactSwitch
      onChange={() => setValue(!value)}
      checked={value}
      checkedIcon={false}
      uncheckedIcon={false}
      width={75}
      onColor="#63ACFB"
      offColor="#cdd5e1"
      offHandleColor="#2B4E8C"
      onHandleColor="#2B4E8C"
    />
  );
}
