import React from 'react';

export const InputContent = ({
  resize,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement> & { resize?: string }) => {
  return (
    <input
      {...props}
      className={`border bg-transparent border-primary outline-none focus:ring-primary focus:border-primary-dark rounded-full w-${
        resize || 'full'
      } h-7 text-green`}
    />
  );
};
