import React, { useEffect } from 'react';

import { LinkButton } from 'components/LinkButton';
import { formatDate } from 'utils/date-utils';
import { License } from 'types';
import { getTotalUsersBySchool } from 'services/UsersService';

interface ListItemProps {
  name: string;
  totalEnrolled: number;
  id: string;
  license: License;
  courses: string[];
}

export const ListItem = ({
  name,
  totalEnrolled,
  id,
  courses,
  license,
}: ListItemProps) => {
  const { expirationDate, code } = license;
  const [totalStudents, setTotalStudents] = React.useState(0);

  useEffect(() => {
    const getData = async () => {
      const response = await getTotalUsersBySchool(id);
      setTotalStudents(response?.data);
    };
    getData();
  }, []);

  //TODO - update the following line if schools has more than one course
  const course = courses[0];
  return (
    <li className="grid grid-flow-col auto-cols-auto flex justify-between items-center bg-primary rounded-full px-6 py-4 gap-2 w-min md:w-auto">
      <div className="text-secondary text-xl w-36 xl:w-72">{name}</div>
      <div className="text-white w-24">{totalStudents} enrolled</div>
      <div>
        <LinkButton
          to={`schools/${id}/courses/${course}/users?schoolName=${name}`}
        >
          View Users
        </LinkButton>
      </div>
      <div>
        <LinkButton to={`schools/${id}/forums`}>View Forums</LinkButton>
      </div>
      <div className="text-white">
        {`License ${code} Until ${formatDate(new Date(expirationDate))}`}
      </div>
      <div>
        <LinkButton to={`schools/${id}/license`}>Extend</LinkButton>
      </div>
    </li>
  );
};
