import { Modal } from 'components/Modal';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { Content } from 'contexts/types';

interface ModalPodcastProps {
  content: Content;
  visible: boolean;
  text: string;
  onClose: () => void;
}

export const ModalPodcast = ({
  visible,
  onClose,
  content,
  text,
}: ModalPodcastProps) => {
  return (
    <Modal
      visible={visible}
      onClose={onClose}
      hiddeCancelButton
      width="9/12"
      style="border-4 border-primary bg-pink-100 h-2/3"
    >
      <div className="absolute left-0 top-0 w-full h-24 bg-secondary rounded-b-3xl p-4">
        <Text variant="subtitle" color="white">
          {content.title}
        </Text>
        <Text variant="paragraph" color="orange">
          Podcast
        </Text>
        <div className="w-full flex justify-center h-20 items-end">
          <div className="rounded-b-2xl w-36 bg-blue-100 text-lg text-center text-secondary h-9 pt-1 px-3">
            Transcript
          </div>
        </div>
      </div>
      <div className="relative top-24 h-3/5 mt-10 px-2 pb-8 overflow-scroll no-scrollbar">
        {text.split('\n').map((item, key) => (
          <span key={key}>
            {item}
            <br />
          </span>
        ))}
      </div>
      <div className="absolute bottom-2 right-2">
        <Button variant="primary" onClick={() => onClose()}>
          Close Transcript
        </Button>
      </div>
    </Modal>
  );
};
