/* eslint-disable @typescript-eslint/no-explicit-any */
import { authAxiosCall } from '../axiosCall';
import { AxiosResponse } from 'axios';
import { License } from '../../types';

const BASE_PATH = 'licenses';

export const editLicense = async (
  _id: string,
  license: License,
): Promise<AxiosResponse<any> | undefined> => {
  const params = JSON.stringify(license);
  return authAxiosCall(`${BASE_PATH}/${_id}`, {
    method: 'PATCH',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    data: params,
  });
};
