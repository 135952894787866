import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import * as S from './styles';
import { MenuIcon } from 'components/Icons/Menu';

interface MenuProps {
  list: Array<{ text: string; link: string; externalPage?: boolean }>;
}

export const Menu = ({ list }: MenuProps): JSX.Element => {
  const [open, setOpen] = useState(false);

  const node = useRef<HTMLUListElement>(null);

  const handleClick = (event) => {
    if (node.current?.contains(event.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);
  return (
    <div className="relative w-content inline-block z-70">
      <button
        onClick={() => setOpen((prevState) => !prevState)}
        className="text-primary-dark lg:text-primary"
      >
        <MenuIcon />
      </button>
      {open && (
        <S.ListContainer
          className="absolute right-0 px-2 pb-2 text-white text-right w-56 bg-primary list-none"
          ref={node}
        >
          {list?.map((element, index) => (
            <S.ListItem key={index}>
              {element.externalPage ? (
                <a href={element.link} rel="noreferrer" target="_blank">
                  {element.text}
                </a>
              ) : (
                <Link to={`${element.link}`}>{element.text}</Link>
              )}
            </S.ListItem>
          ))}
        </S.ListContainer>
      )}
    </div>
  );
};
