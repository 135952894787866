import styled from 'styled-components';

export const Row = styled.div`
  height: fit-content;
  background-color: #A7BDE6;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  max-width: 700px;
  width: fit-content;
`;

export const RowTitle = styled.h2`
  color: #2B4E8C;
`;

export const RowDateCotnainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowDateLabel = styled.span`
  color: white;
`;

export const RowDate = styled.span`
  color: white;
`;
