import styled from 'styled-components';

export const StatusContainer = styled.div`
  display: flex;
`;

export const ModuleCircle = styled.div`
  span {
    text-decoration: underline;
  }
  h2 {
    font-size: 24px;
  }
  height: 200px;
  width: 200px;
  @media (max-height: 800px) {
    height: 170px;
    width: 170px;
  }
  border-radius: 50%;
  color: white;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  position: absolute;
  text-align: center;
  display: flex;
  top: 175px;
  left: 175px;
  @media (max-height: 800px) {
    top: 140px;
    left: 140px;
  }
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: inherit;
  transition: 0.3s;
  border: white 4px solid;
  &:hover {
    height: 220px;
    width: 220px;
  }

  /* 
  Add STYLE attribute to component to make dynamic this part
  &.circle2 {
    transform: rotate(60deg) translateY(-275px) rotate(300deg);
  }

  &.circle3 {
    transform: rotate(120deg) translateY(-275px) rotate(240deg);
  }

  &.circle4 {
    transform: rotate(180deg) translateY(-275px) rotate(180deg); // no rotation, just move to the left
  }

  &.circle5 {
    transform: rotate(240deg) translateY(-275px) rotate(120deg);
  }

  &.circle6 {
    transform: rotate(300deg) translateY(-275px) rotate(60deg);
  }

  &.circle1 {
    transform: translateY(-275px); // no rotation, just move to the right
  } */

  @media (max-height: 800px) {
    &:hover {
      height: 190px;
      width: 190px;
    }

    /* 
    Add STYLE attribute to component to make dynamic this part
    &.circle2 {
      transform: rotate(60deg) translateY(-225px) rotate(300deg);
    }

    &.circle3 {
      transform: rotate(120deg) translateY(-225px) rotate(240deg);
    }

    &.circle4 {
      transform: rotate(180deg) translateY(-225px) rotate(180deg); // no rotation, just move to the left
    }

    &.circle5 {
      transform: rotate(240deg) translateY(-225px) rotate(120deg);
    }

    &.circle5 {
      transform: rotate(300deg) translateY(-225px) rotate(60deg);
    }

    &.circle1 {
      transform: translateY(-225px); // no rotation, just move to the right
    } */
  }
`;

export const CourseContainer = styled.div`
  position: relative;
  height: 550px;
  width: 550px;
  @media (max-height: 800px) {
    height: 450px;
    width: 450px;
  }
  border: 4px dashed white;
  border-radius: 50%;
`;

export const CircleOutline = styled.div`
  position: absolute;
  border-radius: 50%;
  height: 550px;
  width: 550px;
  @media (max-height: 800px) {
    height: 450px;
    width: 450px;
  }
  top: 50%;
  left: 50%;
  border: 4px dashed white;
  transform: translate(-50%, -50%);
`;
