import styled from 'styled-components';

export const FileInput = styled.div`
  border-radius: 1rem;
  background-color: white;
  padding: 0.5rem 2.5rem;
  cursor: pointer;
`;

export const Text = styled.span`
  opacity: 0.5;
`;
