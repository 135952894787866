import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface ICircularProgressBar {
  showProgress?: boolean;
  progress: number;
  size?: 'sm' | 'lg';
}

enum Size {
  sm = 'h-12 w-12',
  lg = 'h-20 w-20',
}

export default function CircularProgressBar({
  showProgress = true,
  progress = 0,
  size = 'lg',
}: ICircularProgressBar) {
  return (
    <div className={`${Size[size]}`}>
      <CircularProgressbar
        value={progress}
        maxValue={100}
        strokeWidth={15}
        text={showProgress ? `${progress}%` : ''}
        styles={buildStyles({
          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: 'butt',

          // How long animation takes to go from one percentage to another, in seconds
          pathTransitionDuration: 0.5,

          // Can specify path transition in more detail, or remove it entirely
          // pathTransition: 'none',

          // Colors
          pathColor: `#FF8C4D`,
          textColor: '#ffffff',
          trailColor: '#ffffff',
          backgroundColor: '#3e98c7',
        })}
      />
    </div>
  );
}
