import { useEffect, useState } from 'react';

import { Modal } from 'components/Modal';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { Content, ContentProgress } from 'contexts/types';

import { ContentService, ProgressService } from 'services';
import { useCountDown } from 'hooks/useCountDown';
import { shuffleArray } from 'utils/utils';
import { userAnswersState, userStateRecoil } from 'contexts/atoms';
import { useRecoilState } from 'recoil';

interface ModalQuizProps {
  content: Content;
  contentProgress?: ContentProgress;
  visible: boolean;
  onClose: () => void;
  onUpdateProgress: (
    contentProgress: ContentProgress,
    newModuleProgress?: number,
  ) => void;
}

const QuestionInfo = ({ answer, explanation }) => {
  return (
    <>
      <Text variant="paragraph" color="primary">
        The correct answer is:
      </Text>
      <Text variant="paragraph" color="primary" resize="18px">
        {answer}
      </Text>
      <Text variant="paragraph" color="primary">
        {explanation}
      </Text>
    </>
  );
};

export const ModalQuiz = ({
  visible,
  onClose,
  content,
  contentProgress,
  onUpdateProgress,
}: ModalQuizProps) => {
  const [questions, setQuestions] = useState<Array<any>>([]);
  const [currentOptions, setCurrentOptions] = useState<Array<string>>([]);
  const [step, setStep] = useState(0);
  const [score, setScore] = useState(0);
  const [userState] = useRecoilState(userStateRecoil);
  const [answersState, setAnswersState] = useRecoilState(userAnswersState);

  const [checked, setChecked] = useState('');
  const [userAnswers, setUserAnswers] = useState<
    {
      questionId: string;
      answer: string;
    }[]
  >([]);

  const [isReview, setIsReview] = useState(
    contentProgress?.seen || userState.role !== 'STUDENT',
  );
  const [validation, setValidation] = useState<
    Array<{
      correctAnswer: string;
      explanation: string;
      isCorrect: boolean;
      questionId: string;
    }>
  >([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  /* This is for remaining time */

  const timeLimit = contentProgress?.remainingTime || content.timeLimit;
  const [minutes, seconds, setActiveConter] = useCountDown(timeLimit || 0);
  const isZeroTime = minutes + seconds !== 0;
  const [time, setTime] = useState(0);

  /**
   * Active when Input radio is enabled
   */
  const handleAnswer = async (userAnswer: string) => {
    try {
      /* Save progress of user on DB */
      const isSeen = step == questions.length;
      if (userState.role !== 'XYLO_ADMIN') {
        setLoading(true);
        await ProgressService.updateContentProgress(contentProgress?.id || '', {
          userAnswers: [
            ...userAnswers,
            {
              questionId: `${questions[step - 1]._id}`,
              answer: userAnswer,
            },
          ],
          nextQuestion: questions[step]?._id || undefined,
          remainingTime: minutes + seconds / 60,
        });
        contentProgress &&
          onUpdateProgress({
            ...contentProgress,
            nextQuestion: questions[step]?._id || undefined,
          });

        setLoading(false);

        /* update recoil state for answers  */
        const index = answersState.findIndex(
          (item) => item.contentId == content.id,
        );

        const newAnswersState = [...answersState];
        newAnswersState[index] = {
          ...newAnswersState[index],
          answers: [
            ...newAnswersState[index].answers,
            {
              questionId: `${questions[step - 1]._id}`,
              answer: userAnswer,
            },
          ],
        };
        setAnswersState(newAnswersState);
        /* end of update */
      }

      const newUserAnswers = [
        ...userAnswers,
        { questionId: `${questions[step - 1]._id}`, answer: userAnswer },
      ];

      setUserAnswers(newUserAnswers);
      if (isSeen) {
        setActiveConter(false);
      }
      return newUserAnswers;
    } catch (error) {
      console.error(error);
      setLoading(false);
      setChecked('');
      setError('Failed validation. Select an option again.');
      return null;
    }
  };

  /* If Quiz is already completed validate user's answers */
  const validateAnswers = async (answers?: typeof userAnswers) => {
    const auxValidation: Array<{
      correctAnswer: string;
      explanation: string;
      isCorrect: boolean;
      questionId: string;
    }> = [];
    setLoading(true);
    const newAnswers = answers;
    if (
      answers &&
      content.questions &&
      answers.length < content.questions.length
    ) {
      const questionNotAnswered = content.questions.filter(
        (q) => !answers.map((el) => el.questionId).includes(q._id || ''),
      );
      let myI = 0;
      for (let i = answers.length; i < content.questions.length; i++) {
        newAnswers?.push({
          questionId: questionNotAnswered[myI]._id as string,
          answer: '',
        });
        myI++;
      }
    }

    let newScore = 0;
    for (const userAnswer of newAnswers || userAnswers) {
      try {
        const response = await ContentService.validateAnswersContent(
          content.id,
          {
            answer: userAnswer.answer,
            questionId: userAnswer.questionId,
          },
        );
        if (response && response.data) {
          auxValidation.push({
            ...response.data,
            questionId: userAnswer.questionId,
          });
        }
        if (!isReview) {
          newScore = response?.data.isCorrect ? newScore + 1 : newScore;
        }
      } catch (error) {
        setLoading(false);
        setError('Failed validation. Select an option again.');
        return;
      }
    }

    setValidation(auxValidation);

    if (!isReview) {
      const response = await ProgressService.updateContentProgress(
        contentProgress?.id || '',
        {
          seen: true,
          attempts: 1,
          score: newScore,
          moduleId: content.moduleId,
          progressContent: 100,
        },
      );
      setScore(newScore);
      contentProgress &&
        onUpdateProgress(
          {
            ...contentProgress,
            score: newScore,
            userAnswers: newAnswers || userAnswers,
            seen: true,
          },
          response?.data.moduleProgress,
        );
    }
    setLoading(false);
  };

  const saveTimerProgress = async () => {
    let auxTimestamps = 0;
    setTime((prev) => {
      auxTimestamps = prev;
      return prev;
    });
    try {
      await ProgressService.updateContentProgress(contentProgress?.id || '', {
        remainingTime: auxTimestamps,
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (contentProgress?.userAnswers.length == 0 || answersState.length == 0) {
      setQuestions(shuffleArray(content?.questions || []));
    }
    setQuestions(content?.questions || []);
    const newUserAnswers = contentProgress?.userAnswers;
    newUserAnswers && setUserAnswers(newUserAnswers);

    if (userState.role !== 'STUDENT') {
      setIsReview(true);
      setStep(1);
    }

    if (contentProgress?.seen) {
      validateAnswers(newUserAnswers);
      setStep(1);
      setIsReview(true);
      setScore(contentProgress?.score);
    }

    if (
      contentProgress &&
      !!contentProgress?.nextQuestion &&
      !contentProgress?.seen
    ) {
      const qAnswerIds = contentProgress?.userAnswers.map(
        (el) => el.questionId,
      );
      let questions = content?.questions || [];
      let lastIndex;

      const questionAnswered: any[] = [];
      questions = questions.filter((item) => {
        qAnswerIds.includes(item._id) && questionAnswered.push(item);
        return !qAnswerIds.includes(item._id);
      });
      questionAnswered.forEach((el) => questions.unshift(el));

      const lastQuestion = questions.filter((item, index) => {
        if (item._id == contentProgress.nextQuestion) {
          lastIndex = index;
          return item;
        }
      });

      const element = questions.splice(lastIndex, 1)[0];
      questions.splice(questionAnswered.length, 0, element);

      setQuestions(questions);
      setStep(questionAnswered.length + 1);
      setActiveConter(true, contentProgress?.remainingTime);
    }

    const interval = setInterval(() => {
      !isReview &&
        userState.role == 'STUDENT' &&
        step !== content?.questions?.length &&
        step !== questions.length + 1 &&
        saveTimerProgress();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!isZeroTime) {
      setStep(questions.length + 1);
      setActiveConter(false);
      validateAnswers(userAnswers);
    }
  }, [isZeroTime]);

  useEffect(() => {
    if (questions.length > 0 && step <= questions.length) {
      setCurrentOptions(shuffleArray(questions[step - 1].options || []));
    }

    if (step > 0 && step <= questions.length && !isReview) {
      const qAnswerIds = contentProgress?.userAnswers.map(
        (el) => el.questionId,
      );
      if (qAnswerIds?.includes(questions?.[step - 1]._id)) {
        setStep(qAnswerIds.length + 1);
      }
    }

    if (step == questions.length + 1) {
      userState.role == 'STUDENT' && validateAnswers(userAnswers);
    }
  }, [step]);

  useEffect(() => {
    setTime(minutes + seconds / 60);
  }, [seconds]);

  const checkInput = (option) => {
    return (
      option === checked ||
      (!loading &&
        isReview &&
        option ==
          userAnswers.filter(
            (el) => el.questionId == questions?.[step - 1]._id,
          )[0]?.answer) ||
      (!loading &&
        isReview &&
        option ==
          validation?.filter(
            (el) => el.questionId == questions[step - 1]._id,
          )[0]?.correctAnswer) ||
      (!loading &&
        isReview &&
        userState.role !== 'STUDENT' &&
        option == content?.questions?.[step - 1].correctAnswer)
    );
  };

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      hiddeCancelButton
      width="9/12"
      style="border-4 border-primary bg-pink-100 h-2/3"
    >
      <div className="absolute left-0 top-0 w-full h-24 bg-primary rounded-b-3xl p-4">
        <div className="flex justify-between">
          <Text variant="subtitle" color="secondary">
            Quiz: {content.title}
          </Text>
          <Text variant="paragraph" color="white">
            {!isReview &&
              (step == 0
                ? `Time Limit: ${content.timeLimit || 0} minutes`
                : step == questions.length + 1
                ? ''
                : `Remaining Time: ${minutes} minutes ${Math.trunc(
                    seconds,
                  )} seconds`)}
          </Text>
          <Button
            variant="green"
            onClick={() => {
              contentProgress &&
                !isReview &&
                onUpdateProgress({
                  ...contentProgress,
                  userAnswers,
                  remainingTime: time,
                  //nextQuestion: questions[step]._id,
                });
              onClose();
            }}
          >
            Close
          </Button>
        </div>
        <Text variant="paragraph" color="white">
          {step == 0
            ? `Questions: ${content.questions?.length}`
            : step <= questions.length
            ? `Question ${step}/${content.questions?.length}`
            : 'Completed'}
        </Text>
        {step == 0 && (
          <div className="w-full flex justify-center h-20">
            <button
              className="rounded-2xl w-36 bg-green hover:bg-green-dark text-lg text-center text-white h-9 pt-1 px-3"
              onClick={() => {
                setStep(1);
                setActiveConter(true);
              }}
            >
              {userAnswers.length > 0 ? 'Continue Quiz' : 'Begin Quiz'}
            </button>
          </div>
        )}
      </div>
      {step > 0 && step <= questions.length && (
        <div className="relative flex top-24 h-4/5 px-6 pb-4 overflow-scroll text-secondary space-x-1">
          <div className="w-1/2">
            <Text variant="paragraph" color="secondary">
              {questions?.[step - 1].question}
            </Text>
            <div>
              {currentOptions.map((option: string, index) => {
                return (
                  <div key={index} className="flex space-x-1 pt-2">
                    <input
                      id={`option-${index}`}
                      type="radio"
                      required
                      disabled={isReview}
                      onChange={(event) => {
                        setError('');
                        setChecked(option);
                      }}
                      checked={checkInput(option)}
                      className={`form-radio h-5 w-5 mt-1 border-primary cursor-pointer focus:ring-transparent ${
                        !isReview && option === checked && 'text-primary'
                      } ${
                        (!loading &&
                          isReview &&
                          option ===
                            validation?.filter(
                              (el) => el.questionId == questions[step - 1]._id,
                            )[0]?.correctAnswer) ||
                        option ==
                          content?.questions?.filter(
                            (el) => el._id == questions[step - 1]._id,
                          )[0].correctAnswer
                          ? 'text-green'
                          : 'text-red-500'
                      }`}
                    />
                    <label htmlFor={`option-${index}`}>
                      <Text variant="paragraph" color="secondary">
                        {option}
                      </Text>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-1/2">
            {/* STUDENT ROLE SECTION  */}
            {userState.role == 'STUDENT' &&
              (loading
                ? isReview
                  ? 'Reviewing answers...'
                  : 'Saving answer...'
                : error && (
                    <Text variant="paragraph" color="red-500">
                      {error}
                    </Text>
                  ))}
            {isReview && !error && !loading && userState.role == 'STUDENT' ? (
              userAnswers.filter(
                (el) => el.questionId == questions[step - 1]._id,
              )[0]?.answer ==
              validation?.filter(
                (el) => el.questionId == questions[step - 1]._id,
              )[0]?.correctAnswer ? (
                <Text variant="paragraph" color="green">
                  Correct
                </Text>
              ) : (
                <>
                  {userAnswers?.filter(
                    (el) => el.questionId == questions[step - 1]._id,
                  )[0].answer === '' ? (
                    <Text variant="paragraph" color="orange">
                      Not answered
                    </Text>
                  ) : (
                    <Text variant="paragraph" color="red-500">
                      Incorrect
                    </Text>
                  )}
                  <Text variant="paragraph" color="primary">
                    The correct answer is:
                  </Text>
                  <Text variant="paragraph" color="primary" resize="18px">
                    {
                      validation?.filter(
                        (el) => el.questionId == questions[step - 1]._id,
                      )[0]?.correctAnswer
                    }
                  </Text>
                  <Text variant="paragraph" color="primary">
                    {
                      validation?.filter(
                        (el) => el.questionId == questions[step - 1]._id,
                      )[0]?.explanation
                    }
                  </Text>
                </>
              )
            ) : null}
            {/* END STUDENT SECTION */}

            {/* XYLO_ADMIN AND SCHOOL_ADMIN SECTION */}
            {isReview && !error && !loading && userState.role !== 'STUDENT' && (
              <QuestionInfo
                answer={content?.questions?.[step - 1].correctAnswer}
                explanation={content?.questions?.[step - 1].explanation}
              />
            )}
            {/* END XYLO_ADMIN AND SCHOOL_ADMIN SECTION */}
          </div>
        </div>
      )}

      {/* Section active only when userRole is STUDENT and is not in review mode */}
      {step == questions.length + 1 && (
        <div className="relative flex justify-center top-24 h-3/5 mt-10 px-2 pb-8 overflow-scroll no-scrollbar text-secondary">
          <div className="text-center space-y-4">
            <Text className="" variant="paragraph">
              You finished this quiz!
            </Text>
            <Text className="" variant="paragraph">
              Your score:
              <span className="text-2xl text-green">
                {' '}
                {loading ? 'Loading...' : `${score}/${questions.length}`}
              </span>
            </Text>
            <Button
              variant="green"
              disabled={loading}
              onClick={() => {
                setStep(1);
                setIsReview(true);
              }}
            >
              Review Your Answers
            </Button>
          </div>
        </div>
      )}
      {step > 0 && step <= questions.length && (
        <div className="absolute bottom-2 right-2">
          <Button
            variant="primary"
            type="submit"
            disabled={loading}
            onClick={async () => {
              //if user doesn't select an answer
              if (checked == '' && !isReview) {
                setError('Please select an answer');
                return;
              }
              let newAnswers;
              if (
                checked &&
                !error &&
                step > 0 &&
                content &&
                content.questions &&
                step <= content.questions.length &&
                !isReview
              ) {
                newAnswers = await handleAnswer(checked);
                if (!newAnswers) return;
              }

              if (step == questions.length && isReview) {
                error && setError('');
                onClose();
                setStep(1);
              }
              setStep((prev) => prev + 1);
              setChecked('');
            }}
          >
            {content && content.questions && content.questions?.length == step
              ? 'Finish Quiz'
              : 'Next Question'}
          </Button>
        </div>
      )}
    </Modal>
  );
};
