import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { LinkButton } from 'components/LinkButton';
import { ManageLayout } from 'layouts/Manage';
import { ModulesService, SchoolsService } from 'services';

export const Forums = (): JSX.Element => {
  const [dataSchool, setDataSchool] = useState<any>();
  const [modules, setModules] = useState<any>();
  const { idSchool } = useParams();

  useEffect(() => {
    getDataSchool();
  }, []);

  const getDataSchool = async () => {
    const school = await SchoolsService.getSchool(idSchool || '');
    setDataSchool(school?.data);
    const newModules = await ModulesService.getModulesByCourse(
      school?.data.courses[0].id || '',
    );
    setModules(newModules?.data);
  };

  return (
    <ManageLayout title="Q&A Forums" subtitle="">
      <div className="p-20">
        <div className="mb-8">
          <h1 className="text-primary text-3xl">{dataSchool?.name}</h1>
          <h2 className="text-secondary text-2xl">
            {dataSchool?.courses?.[0].name}
          </h2>
        </div>
        <div className="grid grid-cols-6 grid-flow-col grid-rows-6 gap-x-20 gap-y-4">
          {modules
            ?.sort((a, b) => a.order - b.order)
            .map((module, index) => {
              return (
                <div
                  key={index}
                  className="flex w-fullflex justify-between col-span-5 lg:col-span-3"
                >
                  <h2 className="text-secondary text-xl">{module.name}</h2>
                  <LinkButton
                    to={`/schools/${idSchool}/forum/${module.id}?school=${dataSchool.name}&course=${dataSchool.courses[0].name}&module=${module.name}`}
                  >
                    Go To Forum
                  </LinkButton>
                </div>
              );
            })}
        </div>
      </div>
    </ManageLayout>
  );
};
