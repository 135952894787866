import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';

export const getEnums = (SomeEnum: Record<string, unknown>) =>
  Object.keys(SomeEnum).filter((x) => !(parseInt(x) >= 0));

export const getEnumValues = (SomeEnum: Record<string, string>) =>
  Object.values(SomeEnum).filter((x) => !(parseInt(x) >= 0));

export const buildFileKey = (
  folder: string,
  name: string,
  mimeType: string,
) => {
  const fileName = `${uuid()}`;
  const keyFile = `XYLOAPI/${folder}/${fileName}_${
    name.split('.')[0]
  }.${mimeType}`;
  return keyFile;
};

export const uploadFile = async (
  presignedUrl: string,
  fileType: string,
  file: File,
) => {
  let s3Url = '';
  const contentFyle = await readFileAsync(file);
  const config = {
    headers: {
      'Content-Type': fileType,
    },
  };
  await axios.put(presignedUrl, contentFyle, config).then((res) => {
    if (res.status === 200) {
      s3Url = `${presignedUrl.split('?')[0]}`;
    }
  });

  return s3Url;
};

export const readFileAsync = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsBinaryString(file);
  });
};

export const replaceItemAtIndex = (arr, index, newValue) => {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
};

export const formatNumber = (number: number) =>
  Math.round((number + Number.EPSILON) * 100) / 100;

export const shuffleArray = (options: Array<any>) => {
  let currentIndex = options.length,
    randomIndex: number;
  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    // And swap it with the current element.
    [options[currentIndex], options[randomIndex]] = [
      options[randomIndex],
      options[currentIndex],
    ];
  }
  return options;
};

export const reviewLicenseExpiration = (license: any) => {
  const today = new Date();
  const licenseExpiration = new Date(license.expirationDate);

  if (licenseExpiration < today) {
    window.localStorage.setItem(
      'license',
      JSON.stringify({ isLicenseExpired: true, licenseCode: license.code }),
    );

    toast.warning(
      `Your license has expired. You can't access to the course content.`,
    );
  } else {
    window.localStorage.setItem(
      'license',
      JSON.stringify({ isLicenseExpired: false, licenseCode: license.code }),
    );
  }
};
