import React from 'react';

export default function useFullscreenStatus(elRef): [boolean, () => void] {
  const [isFullscreen, setIsFullscreen] = React.useState(
    document[getBrowserFullscreenElementProp()] != null,
  );

  const setFullscreen = () => {
    if (elRef.current == null) return;

    elRef.current
      .requestFullscreen()
      .then(() => {
        setIsFullscreen(document[getBrowserFullscreenElementProp()] != null);
      })
      .catch(() => {
        setIsFullscreen(false);
      });
  };

  React.useLayoutEffect(() => {
    if (elRef.current != null) {
      const refEl = elRef.current;
      refEl.onfullscreenchange = () =>
        setIsFullscreen(document[getBrowserFullscreenElementProp()] != null);

      return () => (refEl.onfullscreenchange = undefined);
    }
  });

  return [isFullscreen, setFullscreen];
}

function getBrowserFullscreenElementProp() {
  if (typeof document.fullscreenElement !== 'undefined') {
    return 'fullscreenElement';
  } else if (typeof (document as any).mozFullScreenElement !== 'undefined') {
    return 'mozFullScreenElement';
  } else if (typeof (document as any).msFullscreenElement !== 'undefined') {
    return 'msFullscreenElement';
  } else if (typeof (document as any).webkitFullscreenElement !== 'undefined') {
    return 'webkitFullscreenElement';
  } else {
    throw new Error('fullscreenElement is not supported by this browser');
  }
}
