import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  text: string;
  href: string;
}

export default function index({ text, href }: IProps) {
  return (
    <Link className="underline text-primary hover:text-primary-dark" to={href}>
      {text}
    </Link>
  );
}
