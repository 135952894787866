import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { UserCard } from 'components/UserCard';
import { LinkButton } from 'components/LinkButton';
import Switch from 'components/Switch';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { toast } from 'react-toastify';
import { CoursesService, UsersService } from '../../services';
import { DashboardLayout } from 'layouts/Dashboard';
import { userStateRecoil } from 'contexts/atoms';
import { useRecoilState } from 'recoil';
import { Role, UserType } from 'contexts/types';
import { Loader } from 'components/Loader';

export const DashboardRoot = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);

  const [courses, setCourses] = useState<
    { name: string; published: boolean; _id: string }[]
  >([]);
  const [creatingCourse, setCreatingCourse] = useState<boolean>(false);
  const [newCourse, setNewCourse] = useState<{ name: string }>();
  const [userState] = useRecoilState(userStateRecoil);
  const [user, setUser] = useState<UserType>();

  const handleCreateCourse = async () => {
    if (newCourse) {
      CoursesService.createCourse(newCourse)
        .then((res) => {
          toast.success('Course Created');
        })
        .catch((err) => {
          toast.error(
            err.data.error.includes('duplicate')
              ? 'Duplicate Course Name'
              : 'Unable to create course',
          );
        });

      handleGetCourses();
    } else {
      toast.error('Unable to create Course');
    }
    setCreatingCourse(false);
  };
  const handleGetCourses = async () => {
    setIsLoading(true);
    const newCourses = await CoursesService.getCourses();
    setCourses(newCourses?.data);
  };

  const handleGetUserInfo = async () => {
    const userInfo = await UsersService.getUser(userState.userId);
    setUser({
      role: userInfo?.data.roles[0],
      ...userInfo?.data,
    });
    if (userInfo?.data) {
      setIsLoading(false);
    }
  };

  const editCourse = async (id: string) => {
    const index = courses?.findIndex((course) => course._id === id);
    courses[index].published = !courses[index]?.published;
    setCourses([...courses]);
    CoursesService.editCourse(id, {
      published: courses[index]?.published,
    }).catch((err) => {
      toast.error(`Unable to change course: ${err.data.message}`);
      courses[index].published = !courses[index]?.published;
      setCourses([...courses]);
    });
  };

  useEffect(() => {
    handleGetCourses();
    handleGetUserInfo();
  }, []);

  return (
    <DashboardLayout title="Your Dashboard">
      {isLoading ? (
        <div className="w-full col-span-3 h-full">
          <Loader />
        </div>
      ) : (
        <>
          <div className="col-span-2">
            <div className="mb-12">
              <h2 className="text-primary text-3xl mb-4">Licensed Courses</h2>
              {courses?.map((course, index) => {
                return (
                  <S.Row className="mb-4" key={course.name + index}>
                    <div className="mr-2 d-flex">
                      <S.RowTitle className="text-3xl mb-2">
                        {course.name}
                      </S.RowTitle>
                      <div className="flex justify-between items-center">
                        <LinkButton
                          to={`/root/courses/${course._id}?course=${course.name}`}
                        >
                          Go To Course Outline
                        </LinkButton>
                        <div>
                          <S.Switchlabel className="block text-2x1 mr-2">
                            {course.published ? 'Published' : 'Locked'}
                          </S.Switchlabel>
                          <Switch
                            value={course.published}
                            setValue={() => editCourse(course._id)}
                          />
                        </div>
                      </div>
                    </div>
                  </S.Row>
                );
              })}

              {creatingCourse ? (
                <S.Row className="mb-4">
                  <Input
                    type="text"
                    className="mb-4"
                    onChange={(e) =>
                      setNewCourse({
                        name: e.currentTarget.value,
                      })
                    }
                  />
                  <div>
                    <Button
                      variant="secondary"
                      onClick={() => handleCreateCourse()}
                    >
                      Create Course
                    </Button>
                  </div>
                </S.Row>
              ) : (
                <Button
                  variant="green"
                  onClick={() => setCreatingCourse(true)}
                  className="mr-2"
                >
                  Create New Course
                </Button>
              )}
            </div>
            <h2 className="text-primary text-3xl mb-4">Enrolled Students</h2>
            <LinkButton to="/root/add-users" className="mr-2">
              Add Users
            </LinkButton>
            <LinkButton to="/root/view-manage" className="mr-2">
              View & Manage
            </LinkButton>
            <LinkButton to="/root/delete-users" className="mr-2">
              Delete User by Email
            </LinkButton>
          </div>

          <div className="col-span-1 flex justify-end">
            <UserCard
              role={Role[user?.role as keyof typeof Role]}
              name={`${user?.name} ${user?.surname}`}
              photoUrl={user?.profilePhotoUrl}
            />
          </div>
        </>
      )}
    </DashboardLayout>
  );
};
