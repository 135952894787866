export const hoursBetween = (start: Date, end: Date): number => {
  const diff = end.getTime() - start.getTime();
  return Math.floor(diff / (1000 * 60 * 60));
};

export const daysBetweenDates = (date: Date) => {
  const diff = new Date().getTime() - date.getTime();
  return Math.ceil(diff / (1000 * 3600 * 24));
};

export const monthsBetweenDates = (date: Date) => {
  const diff = new Date().getTime() - date.getTime();
  return Math.ceil(diff / (1000 * 3600 * 24 * 30));
};

export const yearsBetweenDates = (date: Date) => {
  const diff = new Date().getTime() - date.getTime();
  return Math.ceil(diff / (1000 * 3600 * 24 * 365));
};

export const formatDate = (date: Date) => {
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const formatToHHMMSS = (seconds: number) => {
  return new Date(seconds * 1000).toISOString().substr(11, 8);
};

export const convertStringTimetoSeconds = (time: string) => {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

const dateFormatter = new Intl.DateTimeFormat('en-GB', {
  timeZone: 'Etc/UTC',
  hour12: false,
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
});

export const formatTime = (seconds: number) => {
  const newDateStr = dateFormatter
    .format(new Date(seconds * 1000))
    .split(':')
    .map((item, index) => {
      if (index == 2) return item;
      if (index == 1) return `${seconds > 3600 ? item : +item}`;
      return +item > 0 && `${+item}`;
    })
    .filter((item) => !!item)
    .join(':');
  return newDateStr;
};
