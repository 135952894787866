import { useEffect, useState } from 'react';
import { Button } from 'components/Button';
import { formatToHHMMSS } from 'utils/date-utils';
import { ContentService } from 'services';
import { buildFileKey } from 'utils/utils';
import { contentListState } from 'contexts/atoms';
import { useRecoilState } from 'recoil';

interface PodcastFormProps {
  contentId?: string;
  onChangeUploadMedia: (presignedUrl: string, file: File) => void;
  onChangeUploadScript: (presignedUrl: string, file: File) => void;
}

export const PodcastForm = ({
  contentId,
  onChangeUploadMedia,
  onChangeUploadScript,
}: PodcastFormProps) => {
  const [contentList] = useRecoilState(contentListState);
  const [infoAudio, setInfoAudio] = useState('');
  const [infoScript, setInfoScript] = useState('');
  const currentContent =
    contentId && contentList.filter((item) => item.id === contentId)[0];

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const file = event?.target?.files?.[0];

    if (file) {
      if (file.type == 'audio/mpeg') {
        const fileKey = buildFileKey('podcasts', file.name, 'mp3');
        const response = await ContentService.getPresignedUrl(
          fileKey,
          'video/mp4;audio/mpeg',
        );

        // Just to read duration of file
        const reader = new FileReader();
        reader.onload = function (
          this: FileReader,
          event: ProgressEvent<FileReader>,
        ) {
          const audioContext = new window.AudioContext();
          // Asynchronously decode audio file data contained in an ArrayBuffer.
          audioContext.decodeAudioData(
            event?.target?.result as ArrayBuffer,
            function (buffer) {
              const duration = formatToHHMMSS(buffer.duration);
              setInfoAudio(`${file.name} uploaded. Total length: ${duration}`);
            },
          );
        };
        reader.readAsArrayBuffer(file);

        // To upload content mp3 file to s3
        onChangeUploadMedia(response?.data, file);
      } else {
        const fileKey = buildFileKey('podcasts', file.name, 'txt');
        const response = await ContentService.getPresignedUrl(
          fileKey,
          file.type || 'text/plain',
        );
        onChangeUploadScript(response?.data, file);
        setInfoScript(`${file.name} uploaded.`);
      }
    }
  };

  useEffect(() => {
    if (currentContent) {
      setInfoAudio(
        `${currentContent.url?.urlMedia.substring(
          currentContent.url?.urlMedia.indexOf('_') + 1,
        )}`,
      );
      setInfoScript(
        `${currentContent.url?.urlScript.substring(
          currentContent.url?.urlScript.indexOf('_') + 1,
        )}`,
      );
    }
  }, []);

  return (
    <>
      <div className="flex items-center space-x-2 grid grid-cols-12">
        <span className="text-right col-span-3">Upload Content File:</span>
        <div className="col-span-9 space-x-2 text-green">
          <Button variant="outlined">
            <input
              required
              type="file"
              name="audio"
              accept=".mp3"
              className="absolute inset-0 opacity-0 w-full"
              onChange={handleFileChange}
            />
            Browse
          </Button>
          <span>{infoAudio}</span>
        </div>
      </div>
      <div className="flex items-center space-x-2 grid grid-cols-12">
        <span className="text-right col-span-3">Upload Transcript:</span>
        <div className="col-span-9 space-x-2 text-green">
          <Button variant="outlined">
            <input
              required
              type="file"
              name="script"
              accept=".txt"
              className="absolute inset-0 opacity-0 w-full"
              onChange={handleFileChange}
            />
            Browse
          </Button>
          <span>{infoScript}</span>
        </div>
      </div>
    </>
  );
};
