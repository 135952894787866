import React from 'react';

import { Button } from 'components/Button';
import { UserImage } from 'components/UserImage';
import { userStateRecoil } from 'contexts/atoms';
import { useRecoilState } from 'recoil';

interface InputCommentProps {
  getValue: (value: string) => void;
}

export const InputComment = ({ getValue }: InputCommentProps): JSX.Element => {
  const [userState] = useRecoilState(userStateRecoil);
  return (
    <>
      <div className="absolute inset-y-0 flex items-center left-2">
        <UserImage size="sm" srcImg={userState.profilePhotoUrl || ''} />
      </div>
      <textarea
        id="comment-box"
        placeholder="Write a comment here"
        className="resize-y rounded-3xl pl-28 pr-16 py-3 border border-transparent focus:border-transparent focus:outline-none focus:ring focus:ring-yellow text-blue w-full h-full"
        onChange={(e) => getValue(e.target.value)}
        required
      />
      <div className="absolute bottom-3 right-3">
        <Button variant="secondary">Post</Button>
      </div>
    </>
  );
};
