import React, { useEffect, useRef, useState } from 'react';

import { userStateRecoil } from 'contexts/atoms';
import { useRecoilState } from 'recoil';
import { ThumbUpIcon, ReplyIcon } from '@heroicons/react/solid';
import { ThumbUpIcon as ThumbUpOutline } from '@heroicons/react/outline';

import { UserImage } from 'components/UserImage';
import {
  daysBetweenDates,
  hoursBetween,
  monthsBetweenDates,
  yearsBetweenDates,
} from 'utils/date-utils';
import { AccountTypeEnum } from 'contexts/types';

export interface IComment {
  id: string;
  user: any;
  content: string;
  createdAt: string;
  likesCount: number;
  liked: boolean;
  parentId: null | string;
}

interface CommentProps {
  id: string;
  user: IComment['user'];
  date: Date;
  text: string;
  likes: number;
  liked: boolean;
  replies?: IComment[];
  selectToDelete: (idComment: string) => void;
  selectToReply: (idRootComment: string | null, user: string | null) => void;
  handleLike: (idComment: string) => void;
  onDelete(): void;
}

export const Comment = ({
  id,
  user,
  date,
  text,
  likes = 0,
  liked,
  replies,
  selectToDelete,
  selectToReply,
  handleLike,
  onDelete,
}: CommentProps): JSX.Element => {
  const [userState] = useRecoilState(userStateRecoil);
  const [like, setLike] = useState(liked);
  const [selected, setSelected] = useState(false);
  const differenceDates =
    hoursBetween(date, new Date()) < 24
      ? `${hoursBetween(date, new Date())} hours`
      : daysBetweenDates(date) < 30
      ? `${daysBetweenDates(date)} days`
      : monthsBetweenDates(date) < 12
      ? `${monthsBetweenDates(date)} months`
      : `${yearsBetweenDates(date)} years`;

  const focusTextBox = () => {
    const textBox = document.getElementById(
      `comment-box`,
    ) as HTMLTextAreaElement;
    textBox?.focus();
  };

  const node = useRef<HTMLDivElement>(null);

  const handleClick = (event) => {
    if (
      node.current?.contains(event.target) &&
      event.target.localName == 'button'
    ) {
      // inside click
      return;
    }
    if (!node.current?.contains(event.target)) {
      // outside click
      setSelected(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <>
      <div
        id={id}
        ref={node}
        className={`flex rounded-4xl bg-white space-x-3 w-full pl-3 py-1 pr-6 border  ${
          selected && 'border-yellow'
        }`}
      >
        <div>
          <UserImage size="xs" srcImg={user.profilePhotoUrl} />
        </div>
        <div className="flex flex-col w-full">
          <h5 className="text-orange">
            {user.name} - {differenceDates} ago
          </h5>
          <p className="text-sm text-blue">{text}</p>
          <div className="text-sm flex justify-between">
            <div className="flex text-blue-light space-x-2">
              <span>{likes} Likes!</span>
              <button
                className="flex transform hover:scale-110"
                onClick={() => {
                  !like && handleLike(id);
                  setLike(!like);
                }}
              >
                {like ? (
                  <ThumbUpIcon className="h-5 w-5" />
                ) : (
                  <ThumbUpOutline className="h-5 w-5" />
                )}
                Like
              </button>
              <button
                className="flex transform hover:scale-110"
                onClick={() => {
                  focusTextBox();
                  selectToReply(id, user.name);
                  setSelected(true);
                }}
              >
                <ReplyIcon className="h-5 w-5" />
                Reply
              </button>
            </div>
            {(userState.role == AccountTypeEnum.XYLO_ADMIN ||
              userState.userId == user.id) && (
              <button
                className="bg-transparent focus:outline-none underline text-red-600"
                onClick={() => {
                  selectToDelete(id);
                  onDelete();
                }}
              >
                Delete Comment
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="pl-24 mt-2">
        {replies?.map((reply, index) => (
          <Comment
            id={`${reply.id}`}
            key={index}
            user={reply.user}
            text={reply.content}
            date={new Date(reply.createdAt)}
            likes={reply.likesCount}
            liked={reply.liked}
            handleLike={() => handleLike(reply.id)}
            selectToDelete={() => {
              selectToDelete(reply.id);
            }}
            selectToReply={(idComment, user) =>
              selectToReply(id, reply.user.name)
            }
            onDelete={onDelete}
          />
        ))}
      </div>
    </>
  );
};
