import React, { useState } from 'react';

import { EyeIcon, EyeOffIcon } from 'components/Icons';

interface IInput extends React.AllHTMLAttributes<HTMLInputElement> {
  type: string;
  error?: boolean;
  helperText?: string;
  readOnly?: boolean;
}

export const Input = ({
  type,
  error,
  helperText,
  readOnly,
  ...props
}: IInput) => {
  const [newType, setnewType] = useState(type);

  const TEXT_HELPER = `absolute -bottom-5 left-0 text-xs mt-1 ml-3 ${
    error ? 'text-red-500' : 'text-gray-500'
  } text-left self-start`;

  const INPUT = `w-full text-center px-4 py-2 rounded-full border focus:border-gray-300 focus:outline-none focus:ring-2 px-8 ${
    error
      ? 'border-red-500 text-red-600 placeholder-red-600 focus:ring-red-200'
      : readOnly
      ? 'text-gray-500 border-gray-300 focus:ring-gray-200'
      : 'border-primary focus:ring-primary text-secondary placeholder-secondary'
  }`;
  props.className = `${INPUT} ${props.className}`;
  return (
    <div className="relative inline-block max-w-sm w-full text-gray-500">
      <input type={newType} readOnly={readOnly} {...props} />
      {type == 'password' && (
        <span
          className="absolute right-1.5 top-2.5 rounded-md px-1 py-px"
          onClick={() => {
            setnewType((prev) => (prev == 'password' ? 'text' : 'password'));
          }}
        >
          {newType === 'password' ? <EyeOffIcon /> : <EyeIcon />}
        </span>
      )}
      {helperText && <p className={TEXT_HELPER}>{helperText}</p>}
    </div>
  );
};
