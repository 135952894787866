import { Menu } from 'components/Menu';
import React from 'react';
import * as S from './styles';
import { userStateRecoil } from 'contexts/atoms';
import { useRecoilState } from 'recoil';

interface IProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
}

export const DashboardLayout = ({ children, title }: IProps): JSX.Element => {
  const [userState] = useRecoilState(userStateRecoil);

  const partialUrl = userState.role?.replace('_', '-').toLocaleLowerCase();

  return (
    <S.Container className="overflow-y-scroll no-scrollbar bg-pink-200">
      <S.Header className="z-40">
        <S.HeaderTop>
          <div className="absolute right-8 top-8 z-10">
            <Menu
              list={[
                { text: 'Dashboard', link: `/${partialUrl}/dashboard` },
                {
                  text: 'XYLO Home',
                  link: 'https://xyloacademy.com/',
                  externalPage: true,
                },
                { text: 'Logout', link: '/logout' },
              ]}
            />
          </div>
        </S.HeaderTop>
        <S.HeaderBottom>
          <div className="flex justify-end">
            <h2 className="text-secondary text-3xl text-right">{title}</h2>
          </div>
        </S.HeaderBottom>
      </S.Header>
      <S.Content className="flex-grow z-40">
        <div className="grid grid-cols-3 xl:gap-4 h-full">{children}</div>
      </S.Content>
    </S.Container>
  );
};
