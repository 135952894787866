import { useEffect, useState } from 'react';
import { Content, ContentProgress } from 'contexts/types';
import { AudioPlayer } from './Player';
import { ModalPodcast } from './ModalPodcast';
import { ProgressService } from 'services';
import { userStateRecoil } from 'contexts/atoms';
import { useRecoilState } from 'recoil';

interface PodcastProps {
  content: Content;
  userId: string;
  onSetModuleProgress: (progress: number) => void;
  onSetContentCompleted: (contentId: string) => void;
}

export const Podcast = ({
  content,
  userId,
  onSetModuleProgress,
  onSetContentCompleted,
}: PodcastProps) => {
  const [text, setText] = useState('');
  const [contentProgress, setContentProgress] = useState<ContentProgress>();
  const [modalVisible, setModalVisible] = useState(false);
  const [userState] = useRecoilState(userStateRecoil);

  const fetchFile = () => {
    fetch(content?.url?.urlScript || '')
      .then((r) => r.text())
      .then((t) => {
        setText(t);
      });
  };

  const getProgressByContent = async () => {
    if (userState.role == 'STUDENT') {
      const response = await ProgressService.getProgressByContent(
        content.id,
        userId,
        content?.moduleId,
      );
      return response?.data;
    }
  };

  useEffect(() => {
    fetchFile();
    getProgressByContent().then((data) => {
      if (data?.seen) {
        onSetContentCompleted(data?.contentId);
      }
      setContentProgress(data);
    });
  }, []);

  return (
    <div className="h-full  justify-center items-center mx-auto w-10/12">
      <br />
      <div id="audio-modal-container">
        <>
          <div
            className={`${
              modalVisible &&
              'absolute top-1/6 w-full flex justify-center left-0 w-7/12 z-60 '
            }`}
          >
            <div
              className={`${modalVisible && 'relative w-7/12 top-16 ml-16'}`}
            >
              <AudioPlayer
                id={content.id}
                url={content.url?.urlMedia}
                contentProgress={contentProgress}
                moduleId={content.moduleId}
                onSetModuleProgress={(progress) => {
                  onSetModuleProgress(progress);
                  onSetContentCompleted(content.id);
                }}
              />
            </div>
          </div>
          <button
            className="rounded-b-2xl bg-blue-100 text-white underline ml-8 h-8 px-3 opacity-90 hover:opacity-100"
            onClick={() => setModalVisible(true)}
          >
            See Transcript
          </button>
        </>

        <ModalPodcast
          visible={modalVisible}
          content={content}
          text={text}
          onClose={() => {
            setModalVisible(false);
          }}
        />
      </div>
    </div>
  );
};
