import styled from 'styled-components';

export const ModulesContainer = styled.div`
  padding-bottom: 40px;
`;

export const ModuleRow = styled.div`
  margin: 5px 0;
  min-width: 500px;
  display: flex;
  align-items: center;
`;

export const ModuleName = styled.span`
  font-weight: 500;
  min-width: 240px;
`;

export const ModulesOptions = styled.div`
  flex-direction: column;
  width: 180px;
`;

export const Row = styled.div`
  height: fit-content;
  width: fit-content;
  background-color: rgb(26, 57, 113);
  padding: 20px 30px;
  display: flex;
  align-items: center;
  border-radius: 15px;
`;
