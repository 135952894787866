import styled from 'styled-components';

export const Row = styled.div`
  height: fit-content;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  max-width: 500px;
`;

export const RowTitle = styled.h2`
  color: #2B4E8C;
`;

export const RowDateCotnainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowDateLabel = styled.span`
  color: #FF8C4D;
`;

export const RowDate = styled.span`
  color: white;
`;
