import { useEffect, useState } from 'react';
import { Comment, IComment } from '../../components/Comment';
import { InputComment } from './components/InputComment';
import { Modal } from 'components/Modal';
import { Button } from 'components/Button';
import { userStateRecoil } from 'contexts/atoms';
import { useRecoilState } from 'recoil';
import { ForumLayout } from 'layouts/Forum';
import { AccountTypeEnum } from 'contexts/types';
import { useParams } from 'react-router-dom';
import { CommentsService } from 'services';
import { toast } from 'react-toastify';

export const CommentsSection = (): JSX.Element => {
  const [comments, setComments] = useState<IComment[]>([]);
  const [inputComment, setInputComment] = useState<string>('');
  const [commentToDelete, setCommentToDelete] = useState<string | null>(null);
  const [commentToReply, setCommentToReply] = useState<string | null>(null);
  const [userToReply, setUserToReply] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [triggerScroll, setTriggerScroll] = useState(false);
  const [userState] = useRecoilState(userStateRecoil);

  const { idSchool, idModule } = useParams();
  const queryParams = new URLSearchParams(`${window.location.search}`);

  const school = queryParams.get('school');
  const course = queryParams.get('course');
  const module = queryParams.get('module');

  const rootComments = comments.filter((comment) => comment.parentId === null);

  const childComments = comments.filter((comment) => comment.parentId !== null);

  const getReplies = (commentId: string) => {
    return childComments.filter((comment) => comment.parentId === commentId);
  };

  const addComment = async (content: string, parentId: string | null) => {
    toast
      .promise(
        CommentsService.createComment({
          content: content,
          liked: false,
          parentId: parentId,
          user: userState.userId,
          schoolId: idSchool,
          moduleId: idModule,
        }),
        {
          pending: 'Checking...',
          success: 'Comment created successfully!',
          error: {
            render({ data }: any) {
              return data.data?.error || 'Something went wrong!';
            },
          },
        },
      )
      .then((res) => {
        res?.data.data && setComments([...comments, res?.data.data]);
        setInputComment('');
      })
      .catch((err) => {
        toast.error(err.error);
      });
  };

  const deleteCommentConfirm = (commentId) => {
    const index = comments.findIndex((comment) => comment.id === commentId);

    toast
      .promise(CommentsService.deleteComment(commentId), {
        pending: 'Checking...',
        success: 'Comment deleted successfully!',
        error: {
          render({ data }: any) {
            return data.data?.error || 'Something went wrong!';
          },
        },
      })
      .then((res) => {
        comments.splice(index, 1);
        setComments([...comments]);
      })
      .catch((err) => {
        toast.error(err.error);
      });
  };

  const handleLikeComment = (commentId) => {
    CommentsService.likeComment(commentId)
      .then((res) => {
        const index = comments.findIndex((comment) => comment.id === commentId);
        comments[index].likesCount = comments[index].likesCount + 1;
        setComments([...comments]);
      })
      .catch((err) => {
        toast.error(err.data.message);
      });
  };

  const getCommentsByModule = async () => {
    const newComments = await CommentsService.getCommentsBySchoolAndModule(
      idSchool || '',
      idModule || '',
    );

    setComments(newComments?.data);
  };

  useEffect(() => {
    getCommentsByModule();
  }, []);

  useEffect(() => {
    const newComment = comments[comments.length - 1];

    newComment && scrollToElement(newComment.id);
  }, [triggerScroll]);

  const scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <ForumLayout title="Q&A Forums">
      <div className="w-full pb-4">
        <div className="mb-8">
          <h2 className="text-secondary text-titleMd">{school} - Forums</h2>
          <h3 className="text-secondary text-small">
            {course} - {module}
          </h3>
        </div>
        <div
          id="comments"
          className="mt-3 flex flex-grow flex-col overflow-y-scroll no-scrollbar"
        >
          {rootComments.map((comment, index) => (
            <Comment
              id={`${comment.id}`}
              key={index}
              user={comment.user}
              text={comment.content}
              date={new Date(comment.createdAt)}
              replies={getReplies(comment.id)}
              likes={comment.likesCount}
              liked={comment.liked}
              selectToReply={(idRootComment, toReply) => {
                setCommentToReply(idRootComment);
                setUserToReply(toReply);
              }}
              selectToDelete={(idComment) => {
                setCommentToDelete(idComment);
              }}
              handleLike={(idComment) => {
                handleLikeComment(idComment);
              }}
              onDelete={() => setIsModalOpen(true)}
            />
          ))}
        </div>
        {userToReply && (
          <span className="text-base text-primary">
            Replying to {userToReply}
          </span>
        )}
        <form
          className="relative mt-3 h-28"
          onSubmit={(e) => {
            e.preventDefault();
            addComment(inputComment, commentToReply);
            setCommentToReply(null);
            setUserToReply(null);
            e.currentTarget.reset();
            setTriggerScroll(!triggerScroll);
          }}
        >
          <InputComment getValue={(value) => setInputComment(value)} />
        </form>

        <Modal
          visible={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          style="bg-yellow text-white text-center"
          width="1/2"
        >
          <h1 className="text-xl">
            Are you sure you want to delete this comment?
          </h1>
          {userState.role !== AccountTypeEnum.STUDENT && (
            <span className="text-md">
              Make sure it violates our community guidelines.
            </span>
          )}
          <div className="flex justify-around w-full mt-3">
            <Button
              variant="white"
              onClick={() => {
                setIsModalOpen(false);
                setCommentToDelete(null);
              }}
            >
              No, go back.
            </Button>
            <Button
              variant="red"
              onClick={() => {
                deleteCommentConfirm(commentToDelete);
                setIsModalOpen(false);
                setCommentToDelete(null);
              }}
            >
              Yes, delete.
            </Button>
          </div>
        </Modal>
      </div>
    </ForumLayout>
  );
};
