import React, { useEffect, useState } from 'react';
import { InputContent } from './InputContent';
import { Text } from 'components/Text';
import { Modal } from 'components/Modal';
import { Dropdown } from './DropDown';
import { Button } from 'components/Button';
import { Content, Question } from 'contexts/types';
import { contentListState } from 'contexts/atoms';
import { useRecoilState } from 'recoil';

interface QuizFormProps {
  contentId?: string;
  onSetTimeLimit: (value: number) => void;
  onSetQuestions: (questions: Question[]) => void;
}

const defaultQuestion = {
  _id: '',
  question: '',
  options: [],
  correctAnswer: '',
  explanation: '',
  timestamps: '',
};

export const QuizForm = ({
  onSetTimeLimit,
  onSetQuestions,
  contentId,
}: QuizFormProps) => {
  const [isAdd, setIsAdd] = useState(false);
  const [contentList] = useRecoilState(contentListState);
  const [choices, setChoices] = useState(['', '']);
  const [newQuestion, setNewQuestion] = useState<Question>(defaultQuestion);
  const [questionToEdit, setQuestionToEdit] = useState<number>();
  const [currentContent, setCurrentContent] = useState<Content>();
  const [time, setTime] = useState<number>();
  const [questionsState, setQuestionsState] = useState<Array<Question>>([]);

  const onSaveQuestion = () => {
    if (questionToEdit !== undefined && questionsState) {
      const newQuestions = [...questionsState];
      newQuestions[questionToEdit] = newQuestion;

      onSetQuestions(newQuestions);
      setQuestionsState(newQuestions);
      setQuestionToEdit(undefined);
    } else {
      onSetQuestions([...questionsState, newQuestion]);
      questionsState && setQuestionsState([...questionsState, newQuestion]);
    }
    setIsAdd(false);
  };

  const handleChangeTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTime(+e.target.value);
    onSetTimeLimit(+e.target.value);
  };

  const onChangeQuestion = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewQuestion((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onDeleteOption = (index: number) => {
    const auxChoices = [...choices];
    auxChoices.splice(index, 1);
    setChoices(auxChoices);

    setNewQuestion((prevState) => {
      return {
        ...prevState,
        options: auxChoices,
      };
    });
  };

  useEffect(() => {
    if (contentId) {
      const content = contentList.filter((item) => item.id === contentId)[0];
      setCurrentContent(content);
      content.questions && setQuestionsState(content.questions);
    }
  }, []);

  const onDeleteQuestion = (index: number) => {
    const auxQuestions = [...questionsState];
    auxQuestions.splice(index, 1);
    setQuestionsState(auxQuestions);
    onSetQuestions(auxQuestions);
  };

  return (
    <>
      <div className="flex items-center space-x-2 grid grid-cols-12">
        <span className="text-right col-span-3">Time Limit in Minutes:</span>
        <div className="w-36">
          <InputContent
            type="number"
            onChange={handleChangeTime}
            defaultValue={time || currentContent?.timeLimit}
          />
        </div>
      </div>
      <div className="h-4/6 overflow-auto">
        <Text variant="subtitle" resize="20px" color="secondary">
          Add Questions
        </Text>
        {questionsState?.map((element, index) => (
          <div key={index} className="flex space-x-1 mt-2 justify-between">
            <Text variant="paragraph" resize="16px" color="secondaryF">
              {index + 1}. {element.question}
            </Text>
            <div className="flex space-x-1">
              <button
                className="btn-green w-36"
                style={{ maxHeight: '24px' }}
                onClick={() => {
                  setQuestionToEdit(index);
                  setNewQuestion(element);
                  setChoices(element.options);
                  setIsAdd(true);
                }}
              >
                Edit Question
              </button>
              <button
                className="btn-red w-24 h-6"
                onClick={() => {
                  onDeleteQuestion(index);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
        <button
          onClick={() => {
            setIsAdd(true);
            setNewQuestion(defaultQuestion);
            setChoices(['', '']);
          }}
          className="underline text-green hover:text-green-dark mt-2"
        >
          Add Question
        </button>
        <Modal
          visible={isAdd}
          onClose={() => setIsAdd(false)}
          hiddeCancelButton
          width="9/12"
          style="border-4 border-primary p-2"
        >
          <div className="flex items-center space-x-2 grid grid-cols-12 space-y-1">
            <span className="text-right col-span-2">Question:</span>
            <div className="col-span-9">
              <InputContent
                type="text"
                name="question"
                onChange={onChangeQuestion}
                defaultValue={
                  (questionToEdit != undefined &&
                    questionsState?.[questionToEdit].question) ||
                  ''
                }
              />
            </div>
            {choices.map((choice, index) => (
              <>
                {index == 0 ? (
                  <span className="text-right col-span-2">Choices:</span>
                ) : (
                  <span className="col-span-2" />
                )}
                <div className="flex col-span-9">
                  <InputContent
                    type="text"
                    defaultValue={choice}
                    onChange={(event) =>
                      setNewQuestion((prevState) => {
                        const newOptions = [...prevState.options];
                        newOptions[index] = event.target.value;
                        return {
                          ...prevState,
                          options: [...newOptions],
                        };
                      })
                    }
                  />
                  {index > 1 && (
                    <button
                      onClick={() => onDeleteOption(index)}
                      className="underline text-red-500 hover:text-red-800 col-span-9 text-left  ml-2"
                    >
                      X
                    </button>
                  )}
                </div>
              </>
            ))}
            <span className="col-span-2" />
            <button
              onClick={() => setChoices((prev) => [...prev, ''])}
              className="underline text-green hover:text-green-dark col-span-9 text-left w-36"
            >
              Add Choice
            </button>
            <span className="text-right col-span-2">Correct Choice:</span>
            <div className="col-span-9">
              <Dropdown
                options={newQuestion.options}
                value={
                  newQuestion.correctAnswer ||
                  (questionToEdit != undefined &&
                    questionsState?.[questionToEdit].correctAnswer) ||
                  ''
                }
                onChange={(value) =>
                  setNewQuestion((prevState) => {
                    return { ...prevState, correctAnswer: value };
                  })
                }
              />
            </div>

            <span className="text-right col-span-2">Explanation:</span>
            <div className="col-span-9">
              <InputContent
                type="text"
                name="explanation"
                defaultValue={
                  (questionToEdit != undefined &&
                    questionsState?.[questionToEdit].explanation) ||
                  ''
                }
                onChange={onChangeQuestion}
              />
            </div>
          </div>
          <div className="mt-4 flex space-x-2 justify-end">
            <Button
              type="submit"
              variant="white"
              onClick={() => {
                setNewQuestion(defaultQuestion);
                setChoices(['', '']);
                setQuestionToEdit(undefined);
                setIsAdd(false);
              }}
            >
              Cancel
            </Button>
            <Button type="submit" variant="primary" onClick={onSaveQuestion}>
              Save
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
};
