import styled from 'styled-components';
import backgroundImg from '../../assets/images/svg/newBackground2.svg';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
`;

export const Logo = styled.img`
  position: absolute;
  top: -40px;
  left: -40px;
  height: 200px;
`;

export const Content = styled.div`
  width: 100%;
  padding: 0 5%;
`;

export const Header = styled.div`
  height: 300px;
  width: 100%;
  //background-image: url(${backgroundImg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const HeaderTop = styled.div`
  height: 150px;
  padding: 0 5%;
  width: 100%;
  position: relative;
`;

export const HeaderBottom = styled.div`
  height: 150px;
  padding: 0 5%;
  width: 100%;
`;
