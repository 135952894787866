import React, { useState } from 'react';
import { Button } from 'components/Button';
import FileUpload from 'components/FileUpload';

import { Title } from 'components/Title/styles';
import { AddUsersLayout } from 'layouts/AddUsers';
import { toast } from 'react-toastify';
import { UsersService } from '../../services';

export default function AddUsers() {
  const [file, setFile] = useState<File>();

  const handleUpload = () => {
    if (file) {
      toast
        .promise(UsersService.addUsersCSV(file), {
          pending: 'Checking...',
          success: 'Users added successfully!',
          error: {
            render({ data }: any) {
              return data.data?.error || 'Something went wrong!';
            },
          },
        })
        .catch((err) => {
          toast.error(err.error);
        });
    }
  };

  return (
    <AddUsersLayout>
      <div className="flex flex-col justify-center items-center max-w-full mx-auto">
        <Title className="mb-4 text-center text-secondary">Add Users</Title>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleUpload();
          }}
        >
          <div>
            <p className="text-primary mb-8 text-center">
              Upload the user information in a .csv format
              <br />
              {`Remember, the columns should be: School, User Type (XYLO_ADMIN, SCHOOL_ADMIN, STUDENT), Email, Name, Surname.`}
            </p>
          </div>
          <div className="flex items-center w-full justify-center">
            <FileUpload
              onChange={(file) => {
                setFile(file);
              }}
            />
          </div>
          <div className="mt-8 flex justify-center">
            <Button type="submit" variant="fullRounded">
              Continue
            </Button>
          </div>
        </form>
      </div>
    </AddUsersLayout>
  );
}
