import React, { FC } from 'react';

interface CheckBoxProperties
  extends React.InputHTMLAttributes<HTMLInputElement> {
  color: string;
}

export const CheckBox: FC<CheckBoxProperties> = ({
  color,
  ...rest
}: CheckBoxProperties): JSX.Element => {
  const CHECKBOX = `h-5 w-5  cursor-pointer rounded border-none focus:ring-transparent checked:border-transparent ${
    rest.disabled ? 'text-gray-3 text-opacity-50' : `text-${color}`
  }`;

  return (
    <input type="checkbox" className={CHECKBOX} aria-hidden="true" {...rest} />
  );
};
