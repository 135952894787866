import styled from 'styled-components';
import backgroundImg from '../../assets/images/svg/newBackground2.svg';

export const Logo = styled.img`
  position: absolute;
  top: -50px;
  left: -50px;
  height: 200px;
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 30vh 10% 20px 10%;
`;

export const Curve = styled.div`
  background-image: url(${backgroundImg});
  @media (max-width: 1300px) {
    background-size: cover;
    height: 200px;
    width: 100%;
    background-position: -40px;
  }

  background-size: contain;
  background-repeat: no-repeat;
  height: 190px;
`;
