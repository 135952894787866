/* eslint-disable @typescript-eslint/ban-types */
import { CheckBox } from 'components/CheckBox';

import { Row as IRow } from 'react-table';

export const Row = <T extends object>(row: IRow<T>): JSX.Element => {
  return (
    <tr {...row.getRowProps()} className="bg-primary text-white w-full mb-4">
      {row.cells.map((cell) => {
        const { key, ...getCellProps } = cell.getCellProps();
        let cellValue;
        if (Number(`${cell.value}`.split('-')[0]) >= 0 || cell.value) {
          cellValue = `${cell.value}`.split('-')[0];
          if ((cell.column as any).type == 'QUIZ') {
            cellValue = `${
              (Number(`${cell.value}`.split('-')[0]) * 100) /
              (cell.column as any).totalQuestions
            }`;
            if (`${cell.value}`.split('-')[1] === 'false') cellValue = 'NF';
          }
          if ((cell.column as any).type == 'VIDEO') {
            cellValue = 'NF';
            if (`${cell.value}`.split('-')[1] == 'true')
              cellValue = (
                <CheckBox id="payment" type="checkbox" color="green" checked />
              );
          }
        } else {
          cellValue = 'NF';
        }
        return (
          <td
            key={key}
            {...getCellProps}
            className={`border-none first:rounded-l-3xl last:rounded-r-3xl ${
              cell.column.parent?.columns?.[1]?.isVisible &&
              (cell.column.parent?.Header === cell.column.Header
                ? 'bg-primary-dark'
                : cell.column.parent && 'bg-primary-dark opacity-70')
            }`}
          >
            {cellValue == 'NaN' ? 'NF' : cellValue}
          </td>
        );
      })}
    </tr>
  );
};
