import styled from 'styled-components';

export const ListContainer = styled.ul`
  width: fit-content;
  min-width: 170px;
  border-radius: 8px;
  padding: 10px 20px;
`;

export const ListItem = styled.li`
  padding: 7px 0;
  width: 100%;
`;
