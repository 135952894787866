import { useRecoilState } from 'recoil';
import { authStateRecoil } from '../contexts/atoms';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from 'react-router-dom';
import { Login } from 'containers/Login';
import { DashboardRoot } from 'containers/DashboardRoot';
import { ForgotPassword } from 'containers/ForgotPassword';
import { ResetPassword } from 'containers/ResetPassword';
import { AuthCode } from 'containers/Login/AuthCode';
import { AccountSettings } from 'containers/AccountSettings';
import { Logout } from 'containers/Logout';
import { Forums } from 'containers/Forums';
import { SchoolsList } from 'containers/SchoolsList';
import { CommentsSection } from 'containers/Comments';
import ModulesList from 'containers/ModulesList';
import { DashboardAdmin } from 'containers/DashboardAdmin';
import { DashboardStudent } from 'containers/DashboardStudent';
import AddUsers from 'containers/AddUsers';
import AuthRedirect from 'routes/AuthRedirect';
import CourseOutline from 'containers/CourseOutline';
import { ViewUsersAdmin } from 'containers/ViewUsersAdmin';
import { DetailedScores } from 'containers/DetailedScores';
import { EnrolledStudents } from 'containers/EnrolledStudents';
import { AdminDetailedScores } from 'containers/AdminDetailedScores';
import { ExtendLicense } from 'containers/ExtendLicense';
import { ModuleContent } from 'containers/ModuleContent';
import { StudentModuleContent } from 'containers/StudentModuleContent';
import DeleteUsers from 'containers/DeleteUsers';

function RequireAuth({ children }: { children: JSX.Element }) {
  const [authState] = useRecoilState(authStateRecoil);
  const location = useLocation();

  if (!authState) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AuthRedirect />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/login" element={<Login />} />
        <Route path="/code" element={<AuthCode />} />
        <Route
          path="/xylo-admin/dashboard"
          element={
            <RequireAuth>
              <DashboardRoot />
            </RequireAuth>
          }
        />
        <Route
          path="/root/add-users"
          element={
            <>
              <RequireAuth>
                <AddUsers />
              </RequireAuth>
            </>
          }
        />
        <Route
          path="/root/delete-users"
          element={
            <>
              <RequireAuth>
                <DeleteUsers />
              </RequireAuth>
            </>
          }
        />
        <Route
          path="/school-admin/dashboard"
          element={
            <>
              <RequireAuth>
                <DashboardAdmin />
              </RequireAuth>
            </>
          }
        />
        <Route
          path="/school-admin/course-outline/:courseId"
          element={
            <RequireAuth>
              <CourseOutline />
            </RequireAuth>
          }
        />
        <Route
          path="/admin/view-students/schools/:idSchool/courses/:idCourse"
          element={<EnrolledStudents />}
        />
        <Route
          path="/admin/view-students/schools/:idSchool/courses/:idCourse/detailed-scores"
          element={<AdminDetailedScores />}
        />
        <Route
          path="/school-admin/school/:idSchool/forums"
          element={
            <RequireAuth>
              <Forums />
            </RequireAuth>
          }
        />
        <Route
          path="/student/dashboard"
          element={
            <>
              <RequireAuth>
                <DashboardStudent />
              </RequireAuth>
            </>
          }
        />
        <Route
          path="/root/view-manage/schools/:idSchool/forums"
          element={
            <RequireAuth>
              <Forums />
            </RequireAuth>
          }
        />
        <Route
          path="/root/courses/:courseId"
          element={
            <>
              <RequireAuth>
                <ModulesList />
              </RequireAuth>
            </>
          }
        />
        <Route
          path="/root/courses/:courseId/modules/:idModule"
          element={
            <>
              <RequireAuth>
                <ModuleContent />
              </RequireAuth>
            </>
          }
        />
        <Route
          path="/root/view-manage"
          element={
            <>
              <RequireAuth>
                <SchoolsList />
              </RequireAuth>
            </>
          }
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/settings"
          element={
            <RequireAuth>
              <AccountSettings />
            </RequireAuth>
          }
        />
        <Route
          path="/student/school/:idSchool/forums"
          element={
            <RequireAuth>
              <Forums />
            </RequireAuth>
          }
        />
        <Route
          path="/student/course-outline/:courseId"
          element={
            <RequireAuth>
              <CourseOutline />
            </RequireAuth>
          }
        />
        <Route
          path="/student/course-outline/:courseId/:idModule"
          element={
            <RequireAuth>
              <StudentModuleContent />
            </RequireAuth>
          }
        />
        <Route
          path="/schools/:idSchool/forum/:idModule" // schools/1/forum/DisruptiveInnovation-ArtificialIntelligence/1
          element={
            <RequireAuth>
              <CommentsSection />
            </RequireAuth>
          }
        />
        <Route
          path="/root/view-manage/schools/:idSchool/courses/:idCourse/users"
          element={
            <RequireAuth>
              <ViewUsersAdmin />
            </RequireAuth>
          }
        />
        <Route
          path="/root/view-manage/schools/:idSchool/courses/:idCourse/users/detailed-scores"
          element={
            <RequireAuth>
              <DetailedScores />
            </RequireAuth>
          }
        />
        <Route
          path="/root/view-manage/schools/:idSchool/license"
          element={
            <>
              <RequireAuth>
                <ExtendLicense />
              </RequireAuth>
            </>
          }
        />
        <Route path="*" element={<AuthRedirect />} />
      </Routes>
    </BrowserRouter>
  );
}
