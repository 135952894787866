import { Menu } from 'components/Menu';
import React from 'react';
import * as S from '../Dashboard/styles';
import { userStateRecoil } from 'contexts/atoms';
import { useRecoilState } from 'recoil';

interface IProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
}

export const ForumLayout = ({ children, title }: IProps): JSX.Element => {
  const [userState, setUserState] = useRecoilState(userStateRecoil);

  return (
    <S.Container className="overflow-y-scroll no-scrollbar bg-pink-200">
      <S.Header>
        <S.HeaderTop>
          <div className="absolute right-8 top-8 z-40">
            <Menu
              list={[
                { text: 'Dashboard', link: `/${userState.role}/dashboard` },
                { text: 'XYLO Home', link: 'https://xyloacademy.com/', externalPage: true },
                { text: 'Logout', link: '/logout' },
              ]}
            />
          </div>
        </S.HeaderTop>
        <S.HeaderBottom>
          <div className="flex justify-end">
            <h2 className="text-secondary text-3xl">{title}</h2>
          </div>
        </S.HeaderBottom>
      </S.Header>
      <S.Content>
        <div>{children}</div>
      </S.Content>
    </S.Container>
  );
};
