import { Styles } from 'containers/DetailedScores/components/Table/styles';
import { Row } from '../Row';

interface TableProps {
  columns: Array<Record<string, any>>;
  data: Array<Record<string, any>>;
}

export const Table = ({ columns, data }: TableProps): JSX.Element => {
  return (
    <Styles>
      <table className="w-full border-spacing border-separate border-none">
        <thead className="w-full sticky top-0 bg-pink-200 z-10">
          <tr className="w-full mb-4 bgyellow-dark ">
            {columns.map((column, index) => {
              return (
                <th
                  key={index}
                  className={`border-none ${
                    index === 2 ? 'text-blue-200' : 'text-secondary'
                  } ${
                    index >= 2 ? 'border-b border-white  text-center' : ''
                  } px-4 py-2`}
                >
                  {index < 2
                    ? null
                    : index == 2
                    ? 'Module Progress'
                    : index - 2}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="w-full">
          {data.map((item, index) => {
            return <Row columns={columns} key={index} item={item} />;
          })}
        </tbody>
      </table>
    </Styles>
  );
};
