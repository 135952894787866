import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { useEffect, useState } from 'react';
import { Dropdown } from './DropDown';
import { InputContent } from './InputContent';
import { PodcastForm } from './PodcastForm';
import { VideoForm } from './VideoForm';
import { TextImageForm } from './TextImageForm';
import { Text } from 'components/Text';
import { QuizForm } from './QuizForm';
import {
  Content,
  ContentTypeEnum,
  ContentTypeReverse,
  Question,
} from 'contexts/types';
import { SimpleFileUpload } from '../types';
import { contentListState } from 'contexts/atoms';
import { useRecoilState } from 'recoil';
import { getEnumValues, replaceItemAtIndex } from 'utils/utils';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ContentService } from 'services';
import axios, { AxiosResponse } from 'axios';

interface ModalProps {
  editId?: string;
  visible: boolean;
  onClose: () => void;
}

interface Upload {
  media: { presignedUrl: string; file: File | null };
  script: { presignedUrl: string; file: File | null };
}

const defaultUpload = {
  media: { presignedUrl: '', file: null },
  script: { presignedUrl: '', file: null },
};

export const ModalAddContent = ({ editId, visible, onClose }: ModalProps) => {
  const [contentType, setContentType] = useState<string>();
  const [contentList, setContentList] = useRecoilState(contentListState);
  const [contentToEdit, setContentToEdit] = useState<Content>();
  const [name, setName] = useState('');
  const [contentData, setContentData] = useState('');
  const [uploadInfo, setUploadInfo] = useState<Upload>(defaultUpload);
  const [contentFiles, setContentFiles] = useState<
    Record<string, SimpleFileUpload>
  >({});
  const [questions, setQuestions] = useState<Array<Question>>([]);
  const [timeLimit, setTimeLimit] = useState<number>();
  const { idModule } = useParams();

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const reset = () => {
    setName('');
    setContentToEdit(undefined);
    setContentType(undefined);
    setUploadInfo(defaultUpload);
    setContentData('');
    setContentFiles({});
    setQuestions([]);
    onClose();
  };

  const saveContent = async () => {
    const newContent = {
      id: '',
      title: name,
      type: contentType || '',
      moduleId: idModule || '',
      order: contentList.length + 1,
      questions,
      timeLimit,
      data: contentData || undefined,
    };

    if (editId) {
      const index = contentList.findIndex((item) => item.id === editId);
      let contentToUpdate;
      if ((contentType || contentToEdit?.type) !== ContentTypeReverse.Content) {
        await uploadFilesToS3();

        const urlContent =
          !!uploadInfo?.media?.presignedUrl ||
          !!uploadInfo?.script?.presignedUrl
            ? {
                urlMedia:
                  uploadInfo?.media?.presignedUrl.split('?')[0] ||
                  contentList[index].url?.urlMedia ||
                  '',
                urlScript:
                  uploadInfo?.script?.presignedUrl.split('?')[0] ||
                  contentList[index].url?.urlScript ||
                  '',
              }
            : undefined;

        contentToUpdate = {
          ...contentList[index],
          order: contentList[index].order,
          title: name,
          type: contentType || contentList[index].type,
          timeLimit,
          questions: questions,
          url: urlContent || contentList[index].url,
          data: contentData || undefined,
        };
      } else {
        const contentWithS3Urls = await uploadContentFilesToS3(contentFiles);
        contentToUpdate = {
          ...contentList[index],
          order: contentList[index].order,
          title: name,
          type: contentType || contentList[index].type,
          timeLimit,
          questions: questions.length > 0 ? questions : undefined,
          data: contentWithS3Urls || undefined,
        };
      }

      const newContentList = replaceItemAtIndex(
        contentList,
        index,
        contentToUpdate,
      );

      const auxQuestions =
        contentToUpdate?.questions?.map((q) => {
          const { _id, ...rest } = q;
          return {
            ...rest,
          };
        }) || undefined;
      const auxContent = { ...contentToUpdate, questions: auxQuestions };

      const { id, ...rest } = auxContent;
      await toast.promise(ContentService.updateContent(id, rest), {
        pending: 'Checking...',
        success: 'Success',
        error: {
          render: ({ data }: any) => {
            return data?.error?.message || 'Something went wrong.';
          },
        },
      });

      setContentList(newContentList);
    } else {
      //Upload files
      let contentToDB;
      if ((contentType || contentToEdit?.type) === ContentTypeReverse.Content) {
        const contentWithS3Urls = await uploadContentFilesToS3(contentFiles);
        const { id, ...rest } = newContent;
        contentToDB = { ...rest, data: contentWithS3Urls };
      } else {
        await uploadFilesToS3();
        const { id, ...rest } = newContent;
        const contentUrl = {
          urlMedia: uploadInfo?.media?.presignedUrl.split('?')[0] || '',
          urlScript: uploadInfo?.script?.presignedUrl.split('?')[0] || '',
        };
        contentToDB = {
          ...rest,
          url: contentUrl,
        };
      }
      contentToDB.published = false;
      const auxQuestions = contentToDB.questions.map((q) => {
        const { _id, ...rest } = q;
        return {
          ...rest,
        };
      });
      const auxContent = { ...contentToDB, questions: auxQuestions };
      await toast
        .promise(ContentService.createContent(auxContent), {
          pending: 'Updating...',
          success: 'Success',
          error: {
            render: ({ data }: any) => {
              const mesgTitle = !contentToDB.title
                ? 'Name should not be empty'
                : '';
              const mesgType = !contentToDB.type
                ? 'Content Type should not be empty'
                : '';
              return (
                mesgTitle ||
                mesgType ||
                data?.data?.message[0] ||
                'Something went wrong. Try again.'
              );
            },
          },
        })
        .then((response) => {
          setContentList([
            ...contentList,
            { ...contentToDB, id: response?.data?.id },
          ]);
        });
    }

    reset();
  };

  const uploadContentFilesToS3 = async (
    files: Record<string, SimpleFileUpload>,
  ) => {
    if (contentType == ContentTypeReverse.Content) {
      const axiosPromises: Promise<AxiosResponse<any, any>>[] = [];
      let contentWithS3Urls: string = contentData;
      const filesToUpload: SimpleFileUpload[] = [];

      //checks the files actually saved and replaces blob url with s3 url
      Object.entries(files).forEach((file) => {
        if (contentWithS3Urls.includes(file[0])) {
          const fileUrl = file[1].presignedUrl?.split('?')[0];
          contentWithS3Urls = contentWithS3Urls.replace(file[0], fileUrl);
          filesToUpload.push({ ...file[1] });
        }
      });
      setContentData(contentWithS3Urls);

      filesToUpload.forEach((file: SimpleFileUpload) => {
        !!file.presignedUrl &&
          !!file.file &&
          axiosPromises.push(
            axios.put(file.presignedUrl || '', file.file, {
              headers: {
                'Content-Type': file.file.type,
              },
            }),
          );
      });
      if (filesToUpload.length > 0) {
        await toast
          .promise(Promise.all(axiosPromises), {
            pending: 'Uploading...',
            success: 'Files uploaded successfully!',
            error: 'Something went wrong. Try again.',
          })
          .then((res) => {
            res.map((everyResponse) => {
              if (everyResponse.status !== 200) return;
            });
          });
      }
      return contentWithS3Urls;
    }
  };

  const uploadFilesToS3 = async () => {
    if (
      (contentType == ContentTypeReverse.Podcast ||
        contentType == ContentTypeReverse.Video) &&
      (uploadInfo?.media.presignedUrl || uploadInfo?.script.presignedUrl)
    ) {
      const axiosPromises: Promise<AxiosResponse<any, any>>[] = [];

      uploadInfo?.media?.presignedUrl &&
        axiosPromises.push(
          axios.put(
            uploadInfo?.media?.presignedUrl || '',
            uploadInfo?.media?.file,
            {
              headers: {
                'Content-Type': 'video/mp4;audio/mpeg',
              },
            },
          ),
        );

      uploadInfo?.script?.presignedUrl &&
        axiosPromises.push(
          axios.put(
            uploadInfo?.script?.presignedUrl || '',
            uploadInfo?.script?.file,
            {
              headers: {
                'Content-Type': 'text/plain',
              },
            },
          ),
        );

      await toast
        .promise(Promise.all(axiosPromises), {
          pending: 'Uploading...',
          success: 'Files uploaded successfully!',
          error: 'Something went wrong. Try again.',
        })
        .then((res) => {
          res.map((everyResponse) => {
            if (everyResponse.status !== 200) return;
          });
        });
    }
  };

  const onCancel = () => {
    reset();
  };

  useEffect(() => {
    if (editId) {
      const content = contentList.find((item) => item.id === editId);
      if (content) {
        setContentToEdit(content);
        setName(content.title);
        setTimeLimit(content.timeLimit);
        setContentType(content.type);
      }
    }
  }, [editId]);

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      hiddeCancelButton
      width="10/12"
      style="border-4 border-primary bg-pink-100 min-h-modal max-h-modal"
    >
      <div>
        <Text variant="subtitle" color="primary">
          Add Content
        </Text>
      </div>
      <div
        className="flex flex-col justifyend text-secondary space-y-2 h-5/6 overflow-auto"
        style={{ minHeight: 400 }}
      >
        <div className="flex items-center space-x-2 grid grid-cols-12">
          <span className="text-right col-span-3">Name:</span>
          <div className="col-span-9">
            <InputContent
              type="text"
              onChange={handleChangeName}
              defaultValue={name}
            />
          </div>
        </div>
        <div className="flex items-center space-x-2 grid grid-cols-12">
          <span className="text-right col-span-3">Content Type:</span>
          <Dropdown
            disabled={!!editId}
            options={getEnumValues(ContentTypeEnum)}
            onChange={(value) => setContentType(ContentTypeReverse[value])}
            value={
              ContentTypeEnum[
                (contentType || contentToEdit?.type) as ContentTypeEnum
              ]
            }
          />
        </div>
        {(contentType || contentToEdit?.type) === ContentTypeReverse.Quiz && (
          <QuizForm
            contentId={editId}
            onSetTimeLimit={(timeLimit) => setTimeLimit(timeLimit)}
            onSetQuestions={(questions) => {
              setQuestions([...questions]);
            }}
          />
        )}
        {(contentType || contentToEdit?.type) ===
          ContentTypeReverse.Podcast && (
          <PodcastForm
            contentId={editId}
            onChangeUploadMedia={(presignedUrl, file) => {
              setUploadInfo((prev) => {
                return { ...prev, media: { presignedUrl, file } };
              });
            }}
            onChangeUploadScript={(presignedUrl, file) => {
              setUploadInfo((prev) => {
                return { ...prev, script: { presignedUrl, file } };
              });
            }}
          />
        )}
        {(contentType || contentToEdit?.type) === ContentTypeReverse.Video && (
          <VideoForm
            contentId={editId}
            onChangeUploadMedia={(presignedUrl, file) => {
              setUploadInfo((prev) => {
                return { ...prev, media: { presignedUrl, file } };
              });
            }}
            onChangeUploadScript={(presignedUrl, file) => {
              setUploadInfo((prev) => {
                return { ...prev, script: { presignedUrl, file } };
              });
            }}
            onSetQuestions={(questions) => {
              setQuestions([...questions]);
            }}
          />
        )}
        {(contentType || contentToEdit?.type) ===
          ContentTypeReverse.Content && (
          <TextImageForm
            contentId={editId}
            onChangeContent={setContentData}
            onChangeUploadMedia={(newFile) =>
              setContentFiles((prev) => {
                return { ...prev, ...newFile };
              })
            }
          />
        )}
      </div>

      <div className="mt-4 flex space-x-2 absolute bottom-2 right-2">
        <Button type="submit" variant="white" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" variant="primary" onClick={saveContent}>
          Save
        </Button>
      </div>
    </Modal>
  );
};
