import { Menu } from 'components/Menu';
import { Content, ContentTypeEnum, ContentTypeReverse } from 'contexts/types';
import { ContentContainer } from './components/Content';
import { Podcast } from './components/Podcast';
import { Quiz } from './components/Quiz';
import { Sidebar } from './components/Sidebar';
import { Video } from './components/Video';
import { Text } from 'components/Text';
import { useState, useEffect } from 'react';
import { LinkButton } from 'components/LinkButton';
import CircularProgressBar from 'components/CircularProgressBar';
import { ContentService, ProgressService } from 'services';
import { useParams, useNavigate } from 'react-router-dom';
import { userStateRecoil } from 'contexts/atoms';
import { useRecoilState } from 'recoil';

export const StudentModuleContent = () => {
  const [contentList, setContentList] = useState<Array<Content>>([]);
  const [userState] = useRecoilState(userStateRecoil);
  const [progress, setProgress] = useState(0);
  const [contentsCompleted, setContentsCompleted] = useState<Array<string>>([]);
  const license = JSON.parse(window.localStorage.getItem('license') || '{}');

  // Get params
  const queryParams = new URLSearchParams(`${window.location.search}`);
  const module = queryParams.get('module');
  const idSchool = queryParams.get('idSchool');
  const { courseId, idModule } = useParams();
  const navigate = useNavigate();

  const getContentsByModule = async () => {
    const contentList = await ContentService.getContentList(idModule || '');
    if (contentList?.data.isModulePublished !== true) {
      navigate(-1);
    }
    contentList?.data && setContentList(contentList.data.contents);
  };

  const getProgress = async () => {
    if (userState.role !== 'XYLO_ADMIN') {
      const courseProgress = await ProgressService.getCourseProgress(
        courseId || '',
        userState.userId,
      );
      const response = courseProgress?.data;

      const moduleProgress = response?.modulesProgresses?.filter(
        (ele) => ele.moduleId == idModule,
      )[0];
      setProgress(Number(moduleProgress?.progress.toFixed(2) || 0));
    }
  };

  useEffect(() => {
    if (license.isLicenseExpired) {
      navigate(-1);
    }
    !license.isLicenseExpired && getContentsByModule();
    userState.role == 'STUDENT' && getProgress();
  }, []);

  return (
    <>
      <div className="absolute right-8 top-8 z-40 flex space-x-10">
        <div className="-top-20 right-10 place-content-end">
          <div className="flex space-x-3">
            <div className="text-right">
              <Text variant="paragraph" color="primary" resize="20px">
                Module
              </Text>
              <Text variant="title" color="orange">
                {module}
              </Text>
              {userState.role == 'STUDENT' && (
                <Text variant="paragraph" color="primary">
                  Your progress {Math.round(progress)} %
                </Text>
              )}
            </div>
            {userState.role == 'STUDENT' && (
              <CircularProgressBar progress={progress} showProgress={false} />
            )}
          </div>
          <div className="flex justify-end">
            {userState.role !== 'XYLO_ADMIN' && (
              <LinkButton to={`/student/school/${idSchool}/forums`}>
                Go to Q&A Forum
              </LinkButton>
            )}
          </div>
        </div>
        <Menu
          list={[
            { text: 'Dashboard', link: '/' },
            {
              text: 'XYLO Home',
              link: 'https://xyloacademy.com/',
              externalPage: true,
            },
            { text: 'Logout', link: '/logout' },
          ]}
        />
      </div>

      <Sidebar
        moduleContent={contentList}
        contentsCompleted={contentsCompleted}
      />
      <div
        id="sectionContainer"
        className="pl-48 mt-40 pr-10 pb-52 lg:pr-48 overflow-y-scroll flexflex-col no-scrollbar h-full 3/4 space-y-3"
      >
        {contentList.map((content, index) => {
          return (
            <section
              key={content.id}
              id={content.id}
              className="mb-2 block last:pb-4"
            >
              <div className="h-full flex-col justify-center items-center text-secondary">
                <div className="relative flex flex-col justify-center pt-8">
                  <div className="relative ml-20 w-10/12">
                    <Text
                      variant="subtitle"
                      color={`${index == 0 ? 'primary' : 'secondary'}`}
                      className={`${index == 0 && 'text-center'}`}
                    >
                      {content.title}
                    </Text>
                  </div>
                </div>
                {content.type === ContentTypeReverse.Content && (
                  <ContentContainer content={content} />
                )}
                {content.type === ContentTypeReverse.Podcast && (
                  <Podcast
                    content={content}
                    userId={userState.userId}
                    onSetModuleProgress={(value) =>
                      setProgress((prev) => value || prev)
                    }
                    onSetContentCompleted={(id) =>
                      setContentsCompleted((prev) => [...prev, id])
                    }
                  />
                )}
                {content.type === ContentTypeReverse.Video && (
                  <Video
                    content={content}
                    userId={userState.userId}
                    onSetModuleProgress={(value) =>
                      setProgress((prev) => value || prev)
                    }
                    onSetContentCompleted={(id) =>
                      setContentsCompleted((prev) => [...prev, id])
                    }
                  />
                )}
                {content.type === ContentTypeReverse.Quiz && (
                  <Quiz
                    content={content}
                    userId={userState.userId}
                    onSetModuleProgress={(value) =>
                      setProgress((prev) => value || prev)
                    }
                    onSetContentCompleted={(id) =>
                      setContentsCompleted((prev) => [...prev, id])
                    }
                  />
                )}
              </div>
            </section>
          );
        })}
      </div>
    </>
  );
};
