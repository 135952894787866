import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import * as S from '../Basic/styles';
import { Menu } from 'components/Menu';

interface IProps {
  title?: string | React.ReactNode;
  subtitle?: string;
  children: React.ReactNode;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const ManageLayout = ({
  children,
  title = 'Enrolled Schools',
  subtitle = 'View and Manage',
}: IProps): JSX.Element => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  const { idSchool } = useParams();
  const schoolName = [
    {
      idSchool: '1',
      name: 'Independent Students',
      usersEnrroled: 10,
      course: 'Disruptive innovation 1',
      license: 'License 1 Until 12/31/2022',
    },
  ].find((school) => school.idSchool === idSchool)?.name;
  const isUsersView = window.location.pathname.includes('/users');

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <S.Container className="bg-pink-200">
      <div className="absolute right-8 top-8 z-40">
        <Menu
          list={[
            { text: 'Dashboard', link: '/' },
            {
              text: 'XYLO Home',
              link: 'https://xyloacademy.com/',
              externalPage: true,
            },
            { text: 'Logout', link: '/logout' },
          ]}
        />
      </div>
      <div className="w-full h-full px-10 pt-28 pb-2">
        <div className="flex flex-col items-end pr-32">
          <h2 className="text-secondary text-3xl">{title}</h2>
          <h3 className="text-primary text-2xl">
            {isUsersView ? schoolName : subtitle}
          </h3>
        </div>
        <div
          className={`${
            windowDimensions.height < 700
              ? 'mt-5vh'
              : windowDimensions.height < 801
              ? 'mt-vh10'
              : windowDimensions.height < 1300
              ? 'mt-vh15'
              : windowDimensions.height > 1300
              ? 'mt-vh20'
              : 'mt-28'
          }`}
        >
          {children}
        </div>
      </div>
    </S.Container>
  );
};
