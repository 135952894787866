import { authAxiosCall } from '../axiosCall';
import { AxiosResponse } from 'axios';

const BASE_PATH = 'users';

export const getUser = async (
  userId: string,
): Promise<AxiosResponse<any> | undefined> => {
  return authAxiosCall(`${BASE_PATH}/${userId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const addUsersCSV = async (
  file: File,
): Promise<AxiosResponse<any> | undefined> => {
  const data = new FormData();
  data.append('file', file);
  return authAxiosCall(`${BASE_PATH}/createWithCSV`, {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
};

export const changePassword = async (
  idUser: string,
  currentPassword: string,
  newPassword: string,
): Promise<AxiosResponse<any> | undefined> => {
  const params = JSON.stringify({ idUser, currentPassword, newPassword });
  return authAxiosCall(`${BASE_PATH}/password/change`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
  });
};

export const changeProfilePicture = async (
  idUser: string,
  file: string,
): Promise<AxiosResponse<any> | undefined> => {
  const params = JSON.stringify({ idUser, file });
  return authAxiosCall(`${BASE_PATH}/update/profilePicture`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
  });
};

export const sendSupportEmail = async (
  email: string,
  title: string,
  text: string,
): Promise<AxiosResponse<any> | undefined> => {
  const data = JSON.stringify({ email, title, text });
  return authAxiosCall(`support`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
};

export const changeEmail = async (
  idUser: string,
  newEmail: string,
): Promise<AxiosResponse<any> | undefined> => {
  const params = JSON.stringify({ idUser, email: newEmail });
  return authAxiosCall(`${BASE_PATH}/email/change`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
  });
};

export const getModulesProgressByUser = async (
  schoolId: string,
  courseId: string,
): Promise<AxiosResponse<any> | undefined> => {
  return authAxiosCall(
    `${BASE_PATH}/progresses/school/${schoolId}/course/${courseId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export const getModulesProgressByUserDetailed = async (
  schoolId: string,
  courseId: string,
): Promise<AxiosResponse<any> | undefined> => {
  return authAxiosCall(
    `${BASE_PATH}/progresses/school/${schoolId}/course/${courseId}/detail`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export const getTotalUsersBySchool = async (
  schoolId: string,
): Promise<AxiosResponse<any> | undefined> => {
  return authAxiosCall(`${BASE_PATH}/school/${schoolId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const deleteUserByEmail = async (
  email: string,
): Promise<AxiosResponse<any> | undefined> => {
  return authAxiosCall(`${BASE_PATH}/deleteByEmail/${email}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
