import { SortableHandle } from 'react-sortable-hoc';
import { MenuIcon } from '@heroicons/react/outline';

import { Text } from 'components/Text';
import { ContentTypeEnum } from 'contexts/types';
import Switch from 'components/Switch';

interface ListItemProps {
  title: string;
  contentType: string;
  id: string;
  published: boolean;
  onEdit: (id: string) => void;
  onSwitch: (id: string) => void;
  onDelete: (id: string) => void;
}
const DragHandle = SortableHandle(() => (
  <MenuIcon className="text-primary h-6 w-6 mr-2" />
));

export const ListItem = ({
  title,
  contentType,
  id,
  published,
  onEdit,
  onSwitch,
  onDelete,
}: ListItemProps) => {
  return (
    <div className="grid grid-cols-9 mb-2 items-center gap-x-1">
      <div className="col-span-5 xl:col-span-4 flex cursor-pointer">
        <DragHandle />
        <Text variant="paragraph" color="primary">
          {title}
        </Text>
      </div>
      <div className="col-span-4 xl:col-span-3 flex justifybetween space-x-2">
        <div className="flex w-48 justify-between">
          <Text className="w-16" variant="paragraph" color="secondary">
            {ContentTypeEnum[contentType]}
          </Text>
          <button className="btn-green w-12" onClick={() => onEdit(id)}>
            Edit
          </button>
          <button className="btn-red w-14" onClick={() => onDelete(id)}>
            Delete
          </button>
        </div>
        <div className="flex">
          <Switch
            value={published}
            setValue={() => {
              onSwitch(id);
            }}
          />
          <p className="ml-4 text-primary w20">
            {published ? 'Published' : 'Locked'}
          </p>
        </div>
      </div>
    </div>
  );
};
