import { unAuthAxiosCall } from '../axiosCall';

const BASE_PATH = 'auth';

export const login = async (email: string, password: string) => {
  const data = JSON.stringify({ email, password });
  return unAuthAxiosCall(`${BASE_PATH}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
};

export const loginWithCode = async (email: string, authCode: string) => {
  const data = JSON.stringify({ email, authCode });
  return unAuthAxiosCall(`${BASE_PATH}/login/code`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
};

export const forgotPassword = async (email: string) => {
  const data = JSON.stringify({ email });
  return unAuthAxiosCall(`${BASE_PATH}/forgotPassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
};

export const resetPassword = async (
  email: string,
  newPassword: string,
  token: string,
) => {
  const data = JSON.stringify({ email, newPassword, token });
  return unAuthAxiosCall(`${BASE_PATH}/resetPassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
};
