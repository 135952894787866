import { authAxiosCall } from '../axiosCall';
import { AxiosResponse } from 'axios';
import { CreateComment } from './types';

const BASE_PATH = 'comments';

export const createComment = async (
  createComment: CreateComment,
): Promise<AxiosResponse<any> | undefined> => {
  const params = JSON.stringify({
    comment: createComment.content,
    ...createComment,
  });
  return authAxiosCall(`${BASE_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
  });
};

export const getCommentsBySchoolAndModule = async (
  schoolId: string,
  moduleId: string,
): Promise<AxiosResponse<any> | undefined> => {
  return authAxiosCall(`${BASE_PATH}/modules/${schoolId}/${moduleId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const likeComment = async (
  idComment: string,
): Promise<AxiosResponse<any> | undefined> => {
  return authAxiosCall(`${BASE_PATH}/${idComment}/likes`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const deleteComment = async (
  idComment: string,
): Promise<AxiosResponse<any> | undefined> => {
  return authAxiosCall(`${BASE_PATH}/${idComment}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
