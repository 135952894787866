import axios, { AxiosRequestConfig } from 'axios';

const API_URL = process.env.REACT_APP_API_URL?.toString() || '';

const axiosInstance = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  validateStatus: (status) => status < 400,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem('SESSION_TOKEN');
    if (token && config.headers) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (err) => {
    const originalConfig = err.config;
    if (
      originalConfig.url !== `${API_URL}'auth/login'` &&
      originalConfig.url !== `${API_URL}'auth/code'` &&
      err.response
    ) {
      if (err.response.status === 404) {
        // go to /logout
        if (err.response.data.message.includes('Refresh'))
          window.location.href = '/logout';

        return Promise.reject(err.response);
      }
      if (err.response.status === 400) {
        if (err.response.data.message.includes('Refresh'))
          window.location.href = '/logout';
        return Promise.reject(err.response);
      }
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await axiosInstance.post(`${API_URL}auth/accessToken`, {
            refreshToken: window.localStorage.getItem('REFRESH_TOKEN'),
          });
          const loggedUser = rs.data;

          window.localStorage.setItem('SESSION_TOKEN', loggedUser.token);
          window.localStorage.setItem('REFRESH_TOKEN', loggedUser.refreshToken);
          axiosInstance.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${loggedUser.token}`;
          return axiosInstance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  },
);

const axiosCall = async (url: string, requestOptions: AxiosRequestConfig) => {
  try {
    const response = await axiosInstance({
      method: requestOptions.method,
      url: `${API_URL}${url}`,
      data: requestOptions.data,
      headers: requestOptions.headers,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const unAuthAxiosCall = (
  url: string,
  requestOptions: AxiosRequestConfig,
) => axiosCall(url, requestOptions);

export const authAxiosCall = async (
  url: string,
  requestOptions: AxiosRequestConfig,
) => {
  const tokenData = localStorage.getItem('SESSION_TOKEN');
  if (tokenData) {
    return axiosCall(url, {
      ...requestOptions,
      headers: {
        ...requestOptions.headers,
        Authorization: `Bearer ${tokenData}`,
      },
    });
  }
};
