import React from 'react';

import { UserImage } from 'components/UserImage';
import Link from 'components/Link/index';

interface IUserCard {
  name?: string;
  role?: string;
  school?: string;
  photoUrl?: string;
}

export const UserCard = ({
  name,
  school,
  role,
  photoUrl,
}: IUserCard): JSX.Element => {
  return (
    <div
      style={{ height: 'fit-content' }}
      className="rounded-3xl flex flex-col items-center p-6 w-56 bg-blue-100"
    >
      <UserImage size="md" srcImg={photoUrl || ''} />
      <div className="my-3 text-white text-center">
        <h1 className="text-2xl">{name}</h1>
        {school && <h2 className="text-md">{school}</h2>}
        <h2 className="text-md">{role}</h2>
      </div>
      <div className="mt-4">
        <Link text="Account settings" href="/settings" />
      </div>
    </div>
  );
};
