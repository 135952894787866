import { authAxiosCall } from '../axiosCall';
import { AxiosResponse } from 'axios';

const BASE_PATH = 'progresses';

export const getProgressByContent = async (
  contentId: string,
  userId: string,
  moduleId: string,
): Promise<AxiosResponse<any> | undefined> => {
  return authAxiosCall(
    `${BASE_PATH}/contents/${contentId}/param?userId=${userId}&moduleId=${moduleId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        userId,
      },
    },
  );
};

export const getProgress = async (
  id: string,
): Promise<AxiosResponse<any> | undefined> => {
  return authAxiosCall(`${BASE_PATH}/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getCourseProgress = async (
  courseId: string,
  userId: string,
): Promise<AxiosResponse<any> | undefined> => {
  return authAxiosCall(
    `${BASE_PATH}/course/${courseId}/param?userId=${userId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export const createOrUpdateContentProgress = async (
  newProgress,
): Promise<AxiosResponse<any> | undefined> => {
  const params = JSON.stringify(newProgress);
  return authAxiosCall(`${BASE_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
  });
};

export const updateContentProgress = async (
  id: string,
  progress,
): Promise<AxiosResponse<any> | undefined> => {
  const params = JSON.stringify(progress);
  return authAxiosCall(`${BASE_PATH}/contents/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
  });
};
