import { useState, useRef } from 'react';
import { buildFileKey } from 'utils/utils';
import { ContentService } from 'services';
import { contentListState } from 'contexts/atoms';
import { useRecoilState } from 'recoil';
import { SimpleFileUpload } from '../types';
import { Editor } from '@tinymce/tinymce-react';
import { EditorContainer } from './styles';

interface IProps {
  contentId?: string;
  onChangeUploadMedia: (fileData: Record<string, SimpleFileUpload>) => void;
  onChangeContent: (content: string) => void;
}

export const TextImageForm = ({
  contentId,
  onChangeUploadMedia,
  onChangeContent,
}: IProps) => {
  const [contentList] = useRecoilState(contentListState);
  const currentContent =
    contentId && contentList.filter((item) => item.id === contentId)[0];
  const [editorContent, setEditorContent] = useState(
    !!currentContent ? currentContent?.data : '',
  );
  const EDITOR_API_KEY = process.env.REACT_APP_TINY_API_KEY?.toString() || '';

  const editorRef: any = useRef(null);

  const imageHandler = async (blobInfo, success, failure) => {
    const filename = await blobInfo.filename();
    const file: File = await blobInfo.blob();
    const mimeType: string = filename.split('.')[1];
    const fileKey = buildFileKey('images', filename, mimeType);
    try {
      const presignedUrl = await ContentService.getPresignedUrl(
        fileKey,
        file.type,
      );
      const presigned: string = presignedUrl?.data;
      const fileUpload: SimpleFileUpload = {
        presignedUrl: presigned,
        file,
        type: file.type,
      };

      const localFileUrl = URL.createObjectURL(file);
      const newFileData = {
        [localFileUrl]: { ...fileUpload },
      };
      const isFileInstance = file instanceof File;

      //prevents adding extra files to the parent's file record
      if (!isFileInstance && localFileUrl) {
        onChangeUploadMedia({ ...newFileData });
      }

      success(localFileUrl);
    } catch (e) {
      failure('There was a error to upload the image. Please try again.');
    }
  };

  const onChangeEditorContent = () => {
    if (editorRef.current) {
      onChangeContent(editorRef.current.getContent());
    }
  };

  return (
    <EditorContainer>
      <Editor
        onInit={(evt, editor) => (editorRef.current = editor)}
        apiKey={EDITOR_API_KEY}
        initialValue={editorContent}
        init={{
          height: 500,
          menubar: true,
          indentation: '10pt',
          block_unsupported_drop: false,
          automatic_uploads: true,
          file_picker_types: 'file image media',
          images_upload_handler: imageHandler,
          plugins: [
            'advlist autolink lists link image paste charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media paste code help wordcount table',
          ],
          toolbar:
            'undo redo | formatselect | outdent indent |' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist |' +
            'removeformat | help' +
            'link | image | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
        onChange={onChangeEditorContent}
      />
    </EditorContainer>
  );
};
