import React, { ReactNode } from 'react';

import * as S from './styles';

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | 'primary'
    | 'secondary'
    | 'white'
    | 'red'
    | 'green'
    | 'fullRounded'
    | 'outlined';
  children: ReactNode;
}

export const Button = ({
  variant,
  children,
  ...rest
}: IButton): JSX.Element => {
  const variantStyles = {
    primary:
      'bg-primary hover:bg-primary-dark rounded-md px-8 py-0.5 text-white',
    secondary: 'bg-secondary text-white rounded-md px-4 py-0.5',
    white: 'rounded-md px-4 py-0.5 bg-pink-100 hover:bg-gray-200 text-primary',
    red: 'rounded-md px-4 py-0.5 bg-red-600 hover:bg-red-800 text-white',
    green: 'rounded-md px-4 py-0.5 bg-green hover:bg-green-dark text-white',
    fullRounded:
      'bg-secondary hover:bg-secondary-dark rounded-full h-11 px-8 py-2 text-white disabled:opacity-50',
    outlined:
      'hover:border-primary-dark hover:text-primary-dark rounded-full px-8 text-primary border-2 border-primary w-min',
  };

  rest.className = `text-base relative filter disabled:grayscale disabled:opacity-50 ${
    variantStyles[variant as keyof typeof variantStyles]
  } ${rest?.className}`;
  return <S.Button {...rest}>{children}</S.Button>;
};
