import React, { useState, useEffect } from 'react';
import { Column } from 'react-table';

import { Table } from './components/Table';

import { getModulesProgressByUserDetailed } from 'services/UsersService';
import { useParams } from 'react-router-dom';
import { ManageLayout } from 'layouts/Manage';
import { getCourse } from 'services/CoursesService';

export interface IModule {
  progress: number | null;
  contentsProgresses: Array<Record<string, any>>;
}

export interface RowData {
  name: string;
  surname: string;
  email: string;
  courseProgress: number | null;
  courseScore: number | null;
  modulesProgresses: Array<IModule>;
}

export const DetailedScores = (): JSX.Element => {
  const [progresses, setProgresses] = useState([] as any);
  const { idSchool, idCourse } = useParams();

  const [modulesWithContent, setModulesWithContent] = useState([] as any);
  const [course, setCourse] = useState({ name: '' });

  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);
  const schoolName = params.get('schoolName');

  const getData = async () => {
    if (idSchool && idCourse) {
      const response = await getModulesProgressByUserDetailed(
        idSchool,
        idCourse,
      );
      setProgresses(response?.data?.modulesProgressesByUser);
      setModulesWithContent(response?.data?.contentsByModule);
      const course = await getCourse(idCourse);
      setCourse(course?.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const firstColumns: ReadonlyArray<Column<RowData>> = [
    {
      Header: 'Name',
      accessor: (originalRow) => {
        return originalRow.name + ' ' + originalRow.surname;
      }, // accessor is the "key" in the data
    },
    {
      Header: 'Course Score',
      accessor: (originalRow) => {
        return originalRow.courseScore
          ? Math.round(originalRow.courseScore)
          : undefined;
      },
    },
  ];

  const columns = React.useMemo(() => {
    if (!!modulesWithContent.length) {
      const newColumns: ReadonlyArray<Column<RowData>> = modulesWithContent.map(
        (moduleP, indexModuleP) => {
          const columnsAux = [
            {
              Header: `Module ${indexModuleP + 1}`,
              accessor: (originalRow) => {
                return Math.round(
                  originalRow?.modulesProgresses.filter(
                    (el) => el.moduleId == moduleP._id,
                  )[0]?.score,
                );
              },
            },
          ].concat(
            modulesWithContent[indexModuleP]?.contentsData.map(
              (contentP, i) => {
                return {
                  Header: `${contentP.title}`,
                  id: `Module${indexModuleP + 1}${contentP._id}`,
                  type: contentP.type,
                  totalQuestions: contentP.totalQuestions,
                  accessor: (originalRow) => {
                    // checks if exist progress values for every moduleProgress and contentProgress
                    // if not return undefined
                    const aux = originalRow?.modulesProgresses
                      .filter((el) => el.moduleId == moduleP._id)[0]
                      ?.contentProgresses.filter(
                        (el) => el.contentId == contentP._id,
                      )[0];

                    const isValid =
                      originalRow?.modulesProgresses.filter(
                        (el) => el.moduleId == moduleP._id,
                      )[0] && aux;

                    return isValid
                      ? Math.round(aux?.score) + `-${aux?.seen}`
                      : undefined;
                  },
                };
              },
            ),
          );
          return {
            Header: `Module ${indexModuleP + 1}`,
            columns: columnsAux,
          };
        },
      );
      if (newColumns && progresses.length) {
        return [...firstColumns, ...newColumns];
      }
    }
  }, [modulesWithContent]);

  return (
    <ManageLayout
      title={
        <div>
          <h1>Enrolled Schools</h1>
          <p className="text-primary text-2xl">{schoolName}</p>
        </div>
      }
    >
      <div>
        <div className="flex justify-between mt-6 items-center">
          <div>
            <h1 className="text-primary text-3xl">{course.name}</h1>
            <h2 className="text-secondary text-2xl">Detailed Scores</h2>
          </div>
        </div>
        <div className="overflow-auto no-scrollbar h-vh45">
          {!!columns && <Table columns={columns} data={progresses} />}
        </div>
      </div>
    </ManageLayout>
  );
};
