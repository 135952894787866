import { authAxiosCall } from '../axiosCall';
import { AxiosResponse } from 'axios';
import { IModule } from '../../types';

const BASE_PATH = 'modules';

export const getModules = async (): Promise<AxiosResponse<any> | undefined> => {
  return authAxiosCall(`${BASE_PATH}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getModulesByCourse = async (
  courseId: string,
): Promise<AxiosResponse<any> | undefined> => {
  return authAxiosCall(`${BASE_PATH}/courses/${courseId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getModule = async (
  moduleId: string,
): Promise<AxiosResponse<any> | undefined> => {
  return authAxiosCall(`${BASE_PATH}/${moduleId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const createModule = async (
  newModule: IModule,
): Promise<AxiosResponse<any> | undefined> => {
  const params = JSON.stringify(newModule);
  return authAxiosCall(`${BASE_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
  });
};

export const editModule = async (
  _id: string,
  module: IModule,
): Promise<AxiosResponse<any> | undefined> => {
  const params = JSON.stringify(module);
  return authAxiosCall(`${BASE_PATH}/${_id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
  });
};

export const deleteModule = async (
  _id: string,
): Promise<AxiosResponse<any> | undefined> => {
  return authAxiosCall(`${BASE_PATH}/${_id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
