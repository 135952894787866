import React, { useState } from 'react';
import { Title } from 'components/Title/styles';
import { Button } from 'components/Button';
import Link from 'components/Link';
import { useRecoilState } from 'recoil';
import { authStateRecoil, userStateRecoil } from '../../contexts/atoms';
import { InputCode } from 'components/Input/InputCode';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import Basic from 'layouts/Basic';
import { AccountTypeEnum } from 'contexts/types';
import { AuthService } from 'services';

export const AuthCode = (): JSX.Element => {
  const navigate = useNavigate();
  const [userState] = useRecoilState(userStateRecoil);
  const [authState, setAuthState] = useRecoilState(authStateRecoil);

  const [code, setCode] = useState<string>('');

  const handleCodeLogin = () => {
    toast
      .promise(AuthService.loginWithCode(userState.email, code), {
        pending: 'Checking access code...',
        success: 'Access granted.',
        error: 'Wrong code.',
      })
      .then((res) => {
        if (res) {
          const data = res.data;
          setAuthState(true);
          const loggedUser = {
            ...userState,
            token: data.token,
            refreshToken: data.refreshToken,
          };
          window.localStorage.setItem('loggedUser', JSON.stringify(loggedUser));
          window.localStorage.setItem('SESSION_TOKEN', loggedUser.token);
          window.localStorage.setItem('REFRESH_TOKEN', loggedUser.refreshToken);

          userState.role == AccountTypeEnum.STUDENT
            ? navigate('/student/dashboard')
            : navigate('/login');
        }
      });
  };

  return (
    <Basic>
      <div>
        <div className="flex flex-col max-w-lg mx-auto">
          <Title className="mb-8 text-center">Email Authentication</Title>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleCodeLogin();
            }}
          >
            <div className="flex flex-col space-y-3 py- px-2 items-center">
              <InputCode getCode={(x) => setCode(x)} />
            </div>
            <div className="mt-8 flex justify-center">
              <Button type="submit" variant="fullRounded">
                Log In
              </Button>
            </div>
          </form>
          <div className="flex flex-col items-center mt-8">
            <Link href="/forgot-password" text="Forgot password?" />
          </div>
        </div>
        <div className="flex justify-center absolute inset-x-0 bottom-10">
          <p className="relative inset-x-0 top-4 text-primary left-50">
            For more information on how to register your school or invidually,
            email&nbsp;
            <Link href="#" text="info@xyloacademy.com" />
          </p>
        </div>
      </div>
    </Basic>
  );
};
