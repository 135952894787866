import React, { useState, useEffect } from 'react';
import { Text } from 'components/Text';
import { TimestampedQuestionForm } from '../components/TimestampedQuestionForm';
import { InputContent } from './InputContent';
import { useRecoilState } from 'recoil';
import { contentListState } from 'contexts/atoms';
import TimeField from 'react-simple-timefield';

import { Question } from 'contexts/types';

interface IProps {
  contentId?: string;
  onSetQuestions: (questions: Question[]) => void;
}

const defaultQuestion = {
  _id: '',
  question: '',
  options: [],
  correctAnswer: '',
  explanation: '',
  timestamps: '00:00:00',
};

export const TimestampedQuestionList = ({
  contentId,
  onSetQuestions,
}: IProps) => {
  const [isAdd, setIsAdd] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState<number>();
  const [questionsState, setQuestionsState] = useState<Array<Question>>([]);
  const [contentList] = useRecoilState(contentListState);
  const [newQuestion, setNewQuestion] = useState<Question>(defaultQuestion);
  const [currentOptions, setCurrentOptions] = useState(['', '']);

  useEffect(() => {
    if (contentId) {
      const content = contentList.filter((item) => item.id === contentId)[0];
      content?.questions && setQuestionsState(content?.questions);
    }
  }, [contentId]);

  const onSaveQuestion = (question: Question) => {
    if (questionToEdit !== undefined && questionsState) {
      const newQuestions = [...questionsState];
      newQuestions[questionToEdit] = question;

      onSetQuestions(newQuestions);
      setQuestionsState(newQuestions);
      setQuestionToEdit(undefined);
    } else {
      onSetQuestions([...questionsState, question]);
      setQuestionsState([...questionsState, question]);
    }
    setIsAdd(false);
  };

  const onDeleteQuestion = (index: number) => {
    const auxQuestions = [...questionsState];
    auxQuestions.splice(index, 1);
    setQuestionsState(auxQuestions);
    onSetQuestions(auxQuestions);
  };

  return (
    <>
      <div>
        <Text variant="subtitle" resize="20px" color="secondary">
          Add Timestamped Questions:
        </Text>
        {questionsState.map((question, index) => (
          <div key={index} className="flex space-x-1 mt-2 justify-between">
            <div className="flex space-x-1">
              <Text variant="paragraph" resize="16px" color="secondaryF">
                {index + 1}- Timestamp:
              </Text>
              <TimeField
                value={question.timestamps}
                input={
                  <InputContent
                    type="text"
                    resize="36"
                    name="timestamps"
                    className="form-control"
                    disabled
                  />
                }
                showSeconds
              />
              <Text variant="paragraph" resize="16px" color="secondaryF">
                {question.question}
              </Text>
            </div>
            <div className="space-x-1">
              <button
                className="btn-green w-36"
                onClick={() => {
                  setQuestionToEdit(index);
                  setNewQuestion(question);
                  setCurrentOptions(question.options || []);
                  setIsAdd(true);
                }}
              >
                Edit Question
              </button>
              <button
                className="btn-red w-24"
                onClick={() => {
                  onDeleteQuestion(index);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
        <button
          onClick={() => {
            setNewQuestion(defaultQuestion);
            setCurrentOptions(['', '']);
            setIsAdd(true);
          }}
          className="underline text-green hover:text-green-dark mt-2"
        >
          Add Question
        </button>
        <TimestampedQuestionForm
          question={newQuestion}
          visible={isAdd}
          setChoices={(options) => {
            setCurrentOptions(options);
          }}
          choices={currentOptions}
          onClose={() => {
            setIsAdd(false);
            setQuestionToEdit(undefined);
          }}
          onSave={onSaveQuestion}
        />
      </div>
    </>
  );
};
