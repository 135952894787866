import React from 'react';

import avatar from '../../assets/images/png/avatar.png';

interface UserImageProps {
  srcImg: string;
  size: 'xs' | 'sm' | 'md' | 'lg';
}

export const UserImage = ({ srcImg, size }: UserImageProps): JSX.Element => {
  const sizeImage = {
    xs: 'h-12 w-12 border-4',
    sm: 'h-24 w-24 border-4',
    md: 'h-28 w-28 border-8',
    lg: 'h-36 w-36 border-8',
  };

  return (
    <div
      className={`rounded-full ${sizeImage[size]} flex items-center justify-center border-white overflow-hidden`}
    >
      <img className="w-full h-full" src={srcImg || avatar} />
    </div>
  );
};
